






import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-logo';

@Component({
  name: baseClassName,
})
export default class AuthForm extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => true }) private casual!: boolean;
  private baseClassName = baseClassName;

  private get className() {
    return (this.incomingClasses || []).reduce(
      (acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }
}

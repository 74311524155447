import { Vue } from 'vue-property-decorator';
import { DirectiveOptions, VNode, VNodeData } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

interface DirectiveInstance {
    el: HTMLElement;
    node: VNode;
}

let directiveInstance: DirectiveInstance | undefined;

export const directive: DirectiveOptions = {
    inserted: (el: HTMLElement, bindings: DirectiveBinding, newNode: VNode, oldVNode: VNode) => {
        directiveInstance = createInstance(el, newNode);
    },
};

function createInstance(el: HTMLElement, node: VNode) {
    removeElement();
    return {el, node};
}


function removeElement() {
    if (directiveInstance) {
        const vNode = directiveInstance.node.context as Vue;
        if (vNode) {
            vNode.$emit('cancel');
        }
    }
}

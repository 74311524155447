








































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

const baseClassName = 'elib-input-assumption-select';

@Component({
  name: baseClassName,
})
export default class InputAssumptionsSelect extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string;
  @Prop({ default: () => '' }) private value!: string;
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => [] }) private assamptions!: any;
  @Prop({ default: () => false }) private required!: boolean;
  @Prop({ default: () => false }) private horizontal!: boolean;
  @Prop({ default: () => false }) private readonly!: boolean;
  @Prop({ default: () => true }) private clearble!: boolean;
  @Prop({ default: () => false }) private fullWidth?: boolean;
  private query = '';
  private currentValue = '';
  private baseClassName = baseClassName;
  private isFocused = false;
  private selectedItem = null;

  private get isValid() {
    return (this.required && this.value !== '') || !this.required;
  }

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get visibleAssumptions() {
    return this.assamptions;
  }

  private onSelect(item: any) {
    this.selectedItem = item;
    this.isFocused = false;
    this.currentValue = item.value || item.title || item.fullName;
    this.query = item.value || item.title || item.fullName;
    this.$emit('select', item);
  }

  private onInput($event: any) {
    this.query = $event.target?.value;
    this.selectedItem = null;
    this.$emit('input', $event.target.value);
  }

  private mounted() {
    this.onComponentEnter();
  }

  private created() {
    this.currentValue = this.value;
    this.query = this.value;
  }

  private beforeDestroy() {
    this.onComponentLeave();
  }

  private onComponentEnter() {
    window.addEventListener('click', this.onClickOutside);
  }

  private onComponentLeave() {
    window.removeEventListener('click', this.onClickOutside);
  }

  @Watch('value', { immediate: true, deep: true })
  private onPropValueChange(val: string, oldVal: string) {
    this.currentValue = this.value;
    if (val !== oldVal) {
      this.query = val;
    }
  }

  private onClickOutside(event: Event) {
    const element = event.target as HTMLElement;
    this.isFocused = (this.$refs.mainContainer as HTMLElement).contains(element) ||
      element === this.$refs.mainContainer;
    if (this.selectedItem === null && this.value.length !== 0 && this.isFocused) {
      this.$emit('input', this.value);
    }
    if (this.selectedItem === null) {
      this.query = this.currentValue.trim();
      this.$emit('input', '');
    }
  }
}

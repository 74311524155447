import { Module } from 'vuex';
import {
  ExternalSystem,
  getEmptyExternalSystem,
  ITSystem,
} from '@/models/external-systems.model';
import {
  fetchExternalSystems,
  fetchExternalSystemById,
  deleteExternalSystem,
  postNewExternalSystem,
  updateExistingExternalSystem,
} from '@/controllers/external-systems.controllers';
import { QueryParams } from '@/commons/axios.config';
import types from '../types';

type StringFilter = string | undefined;

interface ModuleState {
  externalSystemList: ExternalSystem[];
  filterString: StringFilter;
  activeExternalSystem: ExternalSystem | undefined;
}

export const module: Module<ModuleState, any> = {
  namespaced: true,
  state: {
    externalSystemList: [],
    filterString: undefined,
    activeExternalSystem: undefined,
  },
  mutations: {
    [types.SET_SYSTEMS_LIST](state: ModuleState, payload: ExternalSystem[]) {
      state.externalSystemList = payload;
    },
    [types.ADD_SYSTEMS_TO_LIST](state: ModuleState, payload: ExternalSystem[]) {
      const data = [...state.externalSystemList, ...payload];
      const ids = new Set(data.map((sys) => sys.id));
      state.externalSystemList = [...ids].map((id) => data.find((sys) => sys.id === id) as ExternalSystem);
    },
    setActiveExternalSystem(state: ModuleState, payload: ExternalSystem) {
      state.activeExternalSystem = payload;
    },
    setActiveITSystem(state: ModuleState, payload: ITSystem) {
      payload.innerCode = payload.outerCode = (payload as ITSystem).code;
      state.activeExternalSystem = {...state.activeExternalSystem, ...payload};
    },
    filterString(state: ModuleState, stringFilter: StringFilter) {
      state.filterString = stringFilter;
    },
    [types.SET_EMPTY_EXTERNAL_SYSTEM](state: ModuleState) {
      state.activeExternalSystem = getEmptyExternalSystem();
    },
    updateExternalSystemsList(state: ModuleState, payload: ExternalSystem) {
      const index = state.externalSystemList.findIndex(
        (v) => v.id === payload.id,
      );
      if (index > -1) {
        state.externalSystemList[index] = payload;
      } else {
        state.externalSystemList.push(payload);
      }
    },
    updateActiveExternalSystemProperty(state: ModuleState, payload: { prop: string; value: any }) {
      if (state.activeExternalSystem) {
        (state.activeExternalSystem as { [key: string]: any })[payload.prop] = payload.value;
        const index = state.externalSystemList.
          findIndex((role) => role.id === (state.activeExternalSystem as ExternalSystem).id);
        state.externalSystemList[index] = state.activeExternalSystem;
        state.externalSystemList = [...state.externalSystemList];
        state.activeExternalSystem = {...state.activeExternalSystem};
      }
    },
    mergeExternalSystemProperties(state: ModuleState, payload: {[key: string]: any}) {
      if (state.activeExternalSystem) {
        state.activeExternalSystem = {...state.activeExternalSystem, ...payload};
        const index = state.externalSystemList.
          findIndex((role) => role.id === (state.activeExternalSystem as ExternalSystem).id);
        state.externalSystemList[index] = state.activeExternalSystem;
        state.externalSystemList = [...state.externalSystemList];
        state.activeExternalSystem = {...state.activeExternalSystem};
      }
    },
    removeExternalSystem(state: ModuleState, payload: ExternalSystem) {
      state.externalSystemList = state.externalSystemList.filter(
        (v) => v.id !== payload.id,
      );
    },
  },
  getters: {
    actualExternalSystems({ externalSystemList, filterString }) {
      return getFilteredSystems(externalSystemList, filterString);
    },
  },
  actions: {
    async getExternalSystems({commit, rootState, state}, params?: QueryParams) {
      const query = {...{offset: state.externalSystemList.length, query: state.filterString}, ...params};
      const systems = await fetchExternalSystems(rootState.token || '', query as QueryParams) || [];
      commit(types.ADD_SYSTEMS_TO_LIST, systems);
      return systems;
    },
    async setFilterString({ commit, state, dispatch }, stringFilter: string) {
      const params: QueryParams = {
        limit: 20,
        offset: state.externalSystemList.length - 1,
        query: stringFilter,
      };
      dispatch('getExternalSystems', params);
      commit('filterString', stringFilter);
    },
    async getActiveExternalSystemById({ commit, rootState }, payload: number) {
      const system = await fetchExternalSystemById(
        rootState.token || '',
        payload,
      );
      if (system) {
        commit('setActiveExternalSystem', system);
      }
    },
    async updateExternalSystem({ commit, rootState }, payload: ExternalSystem) {
      const system = await updateExistingExternalSystem(
        rootState.token,
        payload,
      );
      if (system) {
        commit('updateExternalSystemsList', system);
        return true;
      }
      return false;
    },
    async createNewExternalSystem({ commit, rootState }, payload: ExternalSystem) {
      const system = await postNewExternalSystem(rootState.token, payload);
      if (system) {
        commit('updateExternalSystemsList', system);
        return true;
      }
      return false;
    },
    async removeExternalSystem({ commit, rootState }, payload: ExternalSystem) {
      await deleteExternalSystem(rootState.token, payload);
      commit('removeExternalSystem', payload);
    },
  },
};

function getFilteredSystems(externalSystemList: ExternalSystem[], filter: StringFilter) {
  return externalSystemList.filter((v) => {
    const nameSatisfy = filter ? RegExp(filter, 'gi').test(v.name) : true;
    const codeSatisfy = filter ? RegExp(filter, 'gi').test(v.outerCode) : true;
    const descriptionSatisfy = filter
      ? RegExp(filter, 'gi').test(v.description)
      : true;
    return nameSatisfy || codeSatisfy || descriptionSatisfy;
  });
}

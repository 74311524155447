








































import { Vue, Component, Prop, Model } from 'vue-property-decorator';
import { getDateRepresentation } from '@/commons/filters.main';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { TreeViewData } from '@/models/view.model';
import { Document } from '@/models/user-documents.model';

const baseClassName = 'elib-input-autocomplete-files';

@Component({
  name: baseClassName,
  computed: {

    ...mapGetters('catalogues', ['cataloguesTreeViewNodeById']),
  },
})
export default class InputAutocompleteFiles extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private placeholder!: string;
  @Model('change', { type: String, default: () => '' }) private value!: string;
  @Prop({ default: () => [] }) private suggestions!: Array<{ id: string | number, value: any }>;
  @Prop({ default: () => true }) private clearble!: boolean;
  private isFocused = false;
  private baseClassName = baseClassName;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private onValueSelect(ev: Event, suggestion: any) {
    this.isFocused = false;
    this.$emit('select', suggestion);
    ev.stopPropagation();
  }

  private onClickOutised(event: Event) {
    const element = event.target as HTMLElement;
    this.isFocused = (this.$refs.section as HTMLElement).contains(element) || element === this.$refs.section;
  }

  private getLinkedDocumentDateNumber(document: Document) {
    return `№ ${document.documentNumber} от ${getDateRepresentation(document.created)}`;
  }

  private getDocumentsTypePath(document: Document) {
    const node = (this as any).cataloguesTreeViewNodeById((document as any).documentTypeId) as TreeViewData;
    if (!node) {
      return;
    }
    return node.path;
  }
  private mounted() {
    this.onComponentEnter();
  }

  private beforeDestroy() {
    this.onComponentLeave();
  }

  private onComponentEnter() {
    window.addEventListener('click', this.onClickOutised);
  }

  private onComponentLeave() {
    window.removeEventListener('click', this.onClickOutised);
  }
}

import Vue from 'vue';
import Vuex, { Plugin, Store } from 'vuex';
import { module as session } from '@/store/modules/session.module';
import { module as users } from '@/store/modules/users.module';
import { module as roles } from '@/store/modules/roles.module';
import { module as systems } from '@/store/modules/external-systems.module';
import { module as attributes } from '@/store/modules/document-attributes.module';
import { module as catalogues } from '@/store/modules/catalogues.module';
import { module as documents } from '@/store/modules/user-documents.module';
import { module as filters } from '@/store/modules/filters.module';
import { module as dictionaries } from '@/store/modules/dictionaries';
import { User } from '@/models/users.model';


export enum ModuleNames {
   Documents = 'documents',
   Roles = 'roles',
   Session = 'session',
   Attributes = 'attributes',
   Dictionaries = 'dictionaries',
}

Vue.use(Vuex);

const versionChangePlugin: Plugin<any> = (store: Store<any>) => {
  store.subscribe((mutation: any, state: any) => {
    const path = (mutation.type as string).split('/');
    const moduleName = path[0];
    const mutationName = path[1];
    if (mutationName === 'setHasVersionsChanges') {
      return;
    }
    if (!state.documents.activeDocument) {
      store.commit('documents/setHasVersionsChanges', false);
      return;
    }
    if (state.documents.activeDocument
      && !state.documents.activeDocument.documentId) {
      store.commit('documents/setHasVersionsChanges', false);
      return;
    }
    const customMutations = [
      'addAttributeToActiveDocument',
      'updateDocumentActiveAttribute',
      'deleteCustomAttributeFromDocument',
      'deleteActiveDocumentComments',
      'deleteActiveDocumentNote',
    ];
    if (customMutations.includes(mutationName)) {
      store.commit('documents/setHasVersionsChanges', true);
      return;
    }
    if (!(mutation && mutation.payload && mutation.payload.prop)) {
      return;
    }
    const excluderProps = ['documentsCategory', 'favorite'];
    if (excluderProps.includes(mutation.payload.prop)) {
      return;
    }
    const versionChangingMutations = [
      'updateActiveDocumentProperty',
      'updateDocumentArrayProp',
      'addAttributeToActiveDocument',
      'updateDocumentActiveAttribute',
      'addAttributeToActiveDocument',
      'deleteCustomAttributeFromDocument',
      'deleteActiveDocumentComments',
      'deleteActiveDocumentNote',
    ];
    if (moduleName !== 'documents') {
      return;
    }
    if (!versionChangingMutations.includes(mutationName)) {
      return;
    }
    store.commit('documents/setHasVersionsChanges', true);
  });
};

export default new Vuex.Store({
  strict: true,
  state: {
    token: localStorage.getItem(`elib-token`),
    user: undefined,
    lastClickTime: 0,
  },
  mutations: {
    setAuthData(state: any, payload: { user: User, token: string }) {
      state.token = payload.token || state.token;
      state.user = payload.user || payload.user;
    },
    setLastClickTime(state: any, payload: number) {
      state.lastClickTime = payload;
    },
  },
  actions: {
    setLastClickTime({ commit }, payload: number) {
      commit('setLastClickTime', payload);
    },
  },
  modules: {
    [ModuleNames.Session]: session,
    users,
    systems,
    [ModuleNames.Attributes]: attributes,
    catalogues,
    [ModuleNames.Documents]: documents,
    [ModuleNames.Roles]: roles,
    filters,
    dictionaries,
  },
  plugins: [versionChangePlugin],
});

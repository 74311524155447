import { $HTTPServicesClient as axiosClient, getUrlString, QueryParams } from '@/commons/axios.config';
import { Catalogue } from '@/models/catalogues.model';
import { AUTH_TOKEN_HEADER, AUTH_TOKEN_NAME } from '@/commons/constants';

export async function fetchCatalogues(params: QueryParams) {
    let catalogues;
    try {
        const request = await axiosClient.get('/document-types', {
            params,
            headers: {
                [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
            },
        });
        catalogues = request.data.data as Catalogue[];
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return catalogues || [];
}

export async function findInDocumentTypeHierarchyByQuery(params: QueryParams) {
    let catalogues;
    try {
        const request = await axiosClient.get('/document-types/hierarchy', {
            params,
            headers: {
                [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
            },
        });
        catalogues = request.data as Catalogue[];
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return catalogues || [];
}

export async function queryDocumentTypes(params: QueryParams = {}): Promise<Catalogue[]> {
    try {
        const response = await axiosClient.get('/document-types/list', {
            params,
            headers: {
                [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
            },
        });
        return response.data as Catalogue[];
    } catch (error) {
        return [];
    }
}

export async function fetchDocumentTypeView(id: number | string): Promise<{[key: string]: any} | null> {
    try {
        const response = await axiosClient.get(`/document-types/list/${id}`, {
            headers: {
                'x-auth-token': localStorage.getItem('elib-token'),
            },
        });
        return response.data as Catalogue;
    } catch (error) {
        return null;
    }
}

export async function postNewCatalogue(token: string, data: Catalogue) {
    let catalogue;
    try {
        prepareNewCatalogueForPost(data);
        const request = await axiosClient.post('/document-types', JSON.stringify(data),
        {
            headers: {
                'x-auth-token': token,
                'content-type': 'application/json',
            },
        });
        catalogue = (request.data as {data: Catalogue}).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return catalogue;
}

export async function putCatalogue(token: string, data: Catalogue) {
    let catalogue;
    try {
        const request = await axiosClient.put(`/document-types/${data.id}`, JSON.stringify(data),
        {
            headers: {
                'x-auth-token': token,
                'content-type': 'application/json',
            },
        });
        catalogue = (request.data as {data: Catalogue}).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return catalogue;
}

export async function deleteCatalogue(token: string, data: Catalogue) {
    try {
        await axiosClient.delete(`/document-types/${data.id}`,
            {
                headers: {
                    'x-auth-token': token,
                },
            },
        );
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
}

function prepareNewCatalogueForPost(catalogue: Catalogue) {
    if (catalogue.parentId === 0) {
        delete catalogue.parentId;
        delete catalogue.parentTitle;
    }
}

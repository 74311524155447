import { Role } from '@/models/roles.model';
import { $HTTPServicesClient as axiosClient, getUrlString, QueryParams } from '@/commons/axios.config';
import { AUTH_TOKEN_HEADER, AUTH_TOKEN_NAME } from '@/commons/constants';

interface AuthResponse {
  data: Role[];
}

export async function fetchRoles(params: QueryParams = {}) {
  try {
    const response = await axiosClient.get('/roles', {
      params,
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
    });
    const data = (response.data as AuthResponse).data;
    return data.map((role) => setDefaultRolesParams(role));
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}

export async function fetchRoleById(id: number) {
  try {
    const response = await axiosClient.get(`/roles/${id}`, {
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
    });
    return setDefaultRolesParams((response.data as { data: Role }).data);
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}

export async function postNewRole(data: Role) {
  try {
    const response = await axiosClient.post(`/roles`, JSON.stringify(data), {
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
        'content-type': 'application/json',
      },
    });
    return setDefaultRolesParams((response.data as { data: Role }).data);
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}

export async function updateExistingRole(data: Role) {
  try {
    const request = await axiosClient.put(`/roles/${data.id}`, JSON.stringify(data),
      {
        headers: {
          [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
          'content-type': 'application/json',
        },
      },
    );
    return setDefaultRolesParams((request.data as { data: Role }).data);
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}

export async function deleteRole(data: Role) {
  try {
    await axiosClient.delete(`/roles/${data.id}`,
      {
        headers: {
          [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
        },
      },
    );
    return true;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
    return false;
  }
}

function setDefaultRolesParams(role: Role) {
  role.roleAuthorities = role.roleAuthorities.filter((auth) => auth && !!auth);
  return role;
}


















import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-icon-version';

@Component({
  name: baseClassName,
})
export default class IconVersion extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => 19 }) private width!: number;
  @Prop({ default: () => 16 }) private height!: number;
  private baseClassName = baseClassName;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }
}

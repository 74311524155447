






import { Vue, Component } from 'vue-property-decorator';

const baseClassName = 'elib-tooltip';

@Component({
  name: baseClassName,
})
export default class Tooltip extends Vue {
  private baseClassName = baseClassName;
}











































import Component, { mixins } from 'vue-class-component';
import FilterBarMixin from './bar.mixin';
import FiltersItem from './FiltersItem.vue';


const baseClassName = 'elib-documents-filter-bar';

@Component({
  name: baseClassName,
  components: { FiltersItem },
})
export default class DocumentFiltersBar extends mixins(FilterBarMixin) {
  private baseClassName = baseClassName;
}

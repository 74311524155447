

















































import { Vue, Component, Prop } from 'vue-property-decorator';
import DocumentsSidebar from '@/components/UserDocuments/Sidebar.vue';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { Document, createEmptyDocument } from '@/models/user-documents.model';
import { createNewDocumentsFilter } from '@/models/filters.model';
import { hasAuthoritiesByCode } from '@/commons/authoritiesCheck';
import DocumentsListItem from '@/components/UserDocuments/DocumentsListItem.vue';
import DocumentFiltersBar from '@/components/UserDocuments/Filters/FiltersBar.vue';
import { Route } from 'vue-router';
import { ModuleNames } from '@/store/store';
import { debounce } from 'debounce';
import * as types from '@/store/types';

const baseClassName = 'elib-documents-list';

@Component({
  name: baseClassName,
  components: {
    DocumentsSidebar,
    'elib-documents-list-item': DocumentsListItem,
    'elib-documents-filter-bar': DocumentFiltersBar,
  },
  computed: {
    ...mapState('documents', [types.DOCUMENTS_LIST, types.DOCUMENTS_LIST_REPORT, types.SEARCH_IN_CONTENT]),
    ...mapState('catalogues', ['cataloguesList']),
    ...mapState('filters', ['activeFilter']),
    ...mapGetters('catalogues', ['cataloguesTreeViewNodeById']),
    ...mapGetters('session', ['hasAuthorityByCode']),
  },
  methods: {
    ...mapMutations('documents', ['setActiveDocument', 'setFilterString',
      types.SET_SEARCH_BY_CONTENT, types.SET_DOCUMENTS_LIST, types.SET_DOCUMENTS_LIST_REPORT,
      types.FETCH_DOCUMENTS_BY_FILTERS, types.SET_FILTERS_LIST]),
    ...mapActions('documents', [types.GET_USER_DOCUMENTS, types.GET_USER_DOCUMENTS_REPORT,
    types.POST_USER_DOCUMENTS_REPORT]),
    ...mapActions('catalogues', ['getCatalogues']),
    ...mapMutations('filters', ['setActiveFilter']),
    ...mapActions('filters', ['getFilterItems']),
  },
  beforeRouteEnter(to: Route, from: Route, next: (...args: any) => any) {
    next((vm: Vue) => {
      vm.$store.commit('documents/resetDocumentsList');
      vm.$store.dispatch(`${ModuleNames.Documents}/${types.GET_USER_DOCUMENTS}`, { offset: 0 });
    });
  },
})
export default class DocumentsList extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private self: any;
  private baseClassName = baseClassName;
  private stringFilter = '';
  private showFilter = false;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get filteredString() {
    return this.stringFilter;
  }

  private set filteredString(value: string) {
    this.stringFilter = value;
    const normalized = value.trim().replaceAll(/\[|\]/gim, '');
    this.self.setFilterString(normalized);
    this.debounceDocumentsFetch(normalized);
  }

  private get fullTextSearch() {
    return this.self[types.SEARCH_IN_CONTENT];
  }

  private set fullTextSearch(value: boolean) {
    this.self[types.SET_SEARCH_BY_CONTENT](value);
  }

  private get documents() {
    return ([... this.self[types.DOCUMENTS_LIST]] as Document[]);

  }
  private get documentsReport() {
    return ([... this.self[types.DOCUMENTS_LIST_REPORT]] as Document[]);

  }
  private debounceDocumentsFetch(value: string = '') {
    const debouncQueryFunction = (query: string, itnerval = 600) => debounce(async () => {
      this.self[types.SET_DOCUMENTS_LIST]([]);
      const documents = await this.self[types.GET_USER_DOCUMENTS]({ query });
    }, itnerval);
    if (value.length >= 4) {
      debouncQueryFunction(value)();
    }
    if (value.length <= 3) {
      debouncQueryFunction(value, 1500)();
    }
  }

  private addNewDocument() {
    this.self.setActiveDocument(createEmptyDocument());
    this.$router.push({ name: 'create-new-document' });
  }

  private createFilter() {
    this.self.setFiltersList([]);
    this.self.setActiveFilter(null);
  }

  private beforeCreate() {
    this.self = this;
  }

  private created() {
    this.self.getCatalogues();
    this.self.getFilterItems();
  }

  private mounted() {
    (this.$refs.list as HTMLElement).addEventListener('scroll', this.onScroll);
  }

  private beforeDestroy() {
    (this.$refs.list as HTMLElement).removeEventListener('scroll', this.onScroll);
  }

  private onScroll() {
    const list = this.$refs.list as HTMLElement;
    if (list.clientHeight >= (list.scrollHeight - list.scrollTop)) {
      this.self.getUserDocuments();
    }
  }

  private downloadCsv() {
    if (!hasAuthoritiesByCode(33)) {
      return;
    }
    const documentIds = this.self[types.DOCUMENTS_LIST].map((doc: Document) => {
      return doc.documentId;
    });
    this.self[types.POST_USER_DOCUMENTS_REPORT](documentIds).then((data: any) => {
      const docTypeFunction = this.self.cataloguesTreeViewNodeById;
      import('../../commons/xlsx').then((moduleData) => {
        moduleData.createXlsxReport(data, docTypeFunction);
      });
    });

  }

  private openFiltersBar() {
    this.showFilter = !this.showFilter;
    if (!this.self.activeFilter) {
      this.self.setActiveFilter(createNewDocumentsFilter());
    }
  }
}

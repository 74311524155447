

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapActions, mapMutations, mapState } from 'vuex';
import { TreeViewData } from '../../models/view.model';

const baseClassName = 'elib-trees-view';

@Component({
  name: baseClassName,
})
export default class TreesView extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private value!: TreeViewData;
  @Prop() private lastLevelIcon!: string;
  private baseClassName = baseClassName;
  private showChildren = false;

  private get className() {
    return (this.incomingClasses || []).reduce(
      (acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get isLastLevel() {
    return this.value.level === 5 || this.value.children.length === 0;
  }

  private onTreeNodeSelect() {
    this.$emit('select', this.value);
  }

  private onChildSelect(node: TreeViewData) {
    this.$emit('select', node);
  }
}

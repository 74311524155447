import Vue, { PluginFunction } from 'vue';
import IconDocumentAttributes from '@/components/Icons/Icon__document-attributes.vue';
import IconDocumentsCatalogue from '@/components/Icons/Icon__documents-catalogue.vue';
import IconExternalSystems from '@/components/Icons/Icon__external-systems.vue';
import IconUserRoles from '@/components/Icons/Icon__user-roles.vue';
import IconUsersList from '@/components/Icons/Icon__users-list.vue';
import IconLens from '@/components/Icons/Icon__lens.vue';
import IconAvatar from '@/components/Icons/Icon__avatar.vue';
import IconRequired from '@/components/Icons/Icon__required.vue';
import IconPencil from '@/components/Icons/Icon__pencil.vue';
import IconPlus from '@/components/Icons/Icon__plus.vue';
import IconTrash from '@/components/Icons/Icon__trash.vue';
import IconCopy from '@/components/Icons/Icon__copy.vue';
import IconUndo from '@/components/Icons/Icon__undo.vue';
import IconType from '@/components/Icons/Icon__type.vue';
import IconCheckCircle from '@/components/Icons/Icon__check-circle.vue';
import IconClose from '@/components/Icons/Icon__close.vue';
import IconError from '@/components/Icons/Icon__error.vue';
import IconWarn from '@/components/Icons/Icon__warn.vue';
import IconArrow from '@/components/Icons/Icon__arrow.vue';
import IconArrowTwist from '@/components/Icons/Icon__arrow-twisty.vue';
import IconCancel from '@/components/Icons/Icon__cancel.vue';
import IconBack from '@/components/Icons/Icon__back.vue';
import IconStar from '@/components/Icons/Icon__star.vue';
import IconDocument from '@/components/Icons/Icon__document.vue';
import IconDownload from '@/components/Icons/Icon__download.vue';
import IconArrowLeft from '@/components/Icons/Icon__arrow-left.vue';
import IconArrowRight from '@/components/Icons/Icon__arrow-right.vue';
import IconDatepicker from '@/components/Icons/Icon__datepicker.vue';
import IconFilter from '@/components/Icons/Icon__filter.vue';
import IconUpload from '@/components/Icons/Icon__upload.vue';
import IconVersion from '@/components/Icons/Icon__version.vue';
import IconClearSearch from '@/components/Icons/Icon__clear-search.vue';
import IconBurger from '@/components/Icons/Icon__burger.vue';
import IconQuestion from '@/components/Icons/Icon__question.vue';

export default (vue: typeof Vue, options: { [key: string]: any }): void => {
        Vue.component('elib-icon-document-attributes', IconDocumentAttributes);
        Vue.component('elib-icon-documents-catalogue', IconDocumentsCatalogue);
        Vue.component('elib-icon-external-systems', IconExternalSystems);
        Vue.component('elib-icon-user-roles', IconUserRoles);
        Vue.component('elib-icon-users-list', IconUsersList);
        Vue.component('elib-icon-lens', IconLens);
        Vue.component('elib-icon-avatar', IconAvatar);
        Vue.component('elib-icon-required', IconRequired);
        Vue.component('elib-icon-pencil', IconPencil);
        Vue.component('elib-icon-plus', IconPlus);
        Vue.component('elib-icon-trash', IconTrash);
        Vue.component('elib-icon-copy', IconCopy);
        Vue.component('elib-icon-undo', IconUndo);
        Vue.component('elib-icon-type', IconType);
        Vue.component('elib-icon-check-circle', IconCheckCircle);
        Vue.component('elib-icon-close', IconClose);
        Vue.component('elib-icon-error', IconError);
        Vue.component('elib-icon-warn', IconWarn);
        Vue.component('elib-icon-arrow', IconArrow);
        Vue.component('elib-icon-arrow-twist', IconArrowTwist);
        Vue.component('elib-icon-cancel', IconCancel);
        Vue.component('elib-icon-back', IconBack);
        Vue.component('elib-icon-star', IconStar);
        Vue.component('elib-icon-document', IconDocument);
        Vue.component('elib-icon-download', IconDownload);
        Vue.component('elib-icon-arrow-left', IconArrowLeft);
        Vue.component('elib-icon-arrow-right', IconArrowRight);
        Vue.component('elib-icon-datepicker', IconDatepicker);
        Vue.component('elib-icon-filter', IconFilter);
        Vue.component('elib-icon-upload', IconUpload);
        Vue.component('elib-icon-version', IconVersion);
        Vue.component('elib-icon-clear-search', IconClearSearch);
        Vue.component('elib-icon-burger', IconBurger);
        Vue.component('elib-icon-question', IconQuestion);
};

import { Module } from 'vuex';
import { DocumentsFilter, DocumentsFilterItem, DocumentsFilterDto } from '@/models/filters.model';
import { postNewFilter, fetchFilters, deleteFilter, putFilter } from '@/controllers/filters.controller';
import { User } from '@/models/users.model';
import { QueryParams } from '@/commons/axios.config';
import types from '../types';

interface ModuleState {
    activeFilter: DocumentsFilterDto | null;
    filterItems: DocumentsFilterItem[];
    filters: DocumentsFilter[];
}

export const module: Module<ModuleState, any> = {
    namespaced: true,
    state: {
        activeFilter: null,
        filterItems: [],
        filters: [],
    },
    mutations: {
        setDocumentFilters(state: ModuleState, payload: DocumentsFilter[]) {
            const rawArray = [...state.filters, ...payload];
            const ids = [...new Set(rawArray.map((v) => v.id))];
            state.filters = ids.map((id) => rawArray.find((attr) => attr.id === id) as DocumentsFilter);
            state.filters.sort((a, b) => a.name > b.name ? 1 : -1);
        },
        [types.SET_ACTIVE_FILTER](state: ModuleState, payload: DocumentsFilterDto | null) {
            state.activeFilter = payload;
        },
        setActiveFilterName(state: ModuleState, payload: string) {
            if (state.activeFilter) {
                state.activeFilter.name = payload;
            }
        },
        setFilterItems(state: ModuleState, payload: DocumentsFilterItem[]) {
            state.filterItems = [...state.filterItems, ...payload];
        },
        addNewFilter(state: ModuleState, payload: DocumentsFilter) {
            if (!payload && payload === null) {
                return;
            }
            const index = state.filters.findIndex((v) => v.id === payload.id);
            index >= 0 ? state.filters[index] = payload : state.filters.push(payload);
            state.filters = [...state.filters];
        },
        removeFilter(state: ModuleState, payload: number) {
            state.filters = [...state.filters.filter((v) => v.id !== payload)];
        },
        resetState(state: ModuleState) {
            state.activeFilter = null;
            state.filters = [];
            state.filterItems = [];
        },
    },
    actions: {
        async getFilterItems({ commit, rootState, state }, params?: QueryParams) {
            const query = { ...{ offset: state.filters.length, limit: 100 }, ...params };
            const filtersDto = await fetchFilters(rootState.token || '', query as QueryParams) as DocumentsFilterDto[];
            commit('setDocumentFilters', filtersDto);
            return filtersDto || [];
        },
        async [types.SAVE_FILTER]({ commit, state, rootState}, filter: DocumentsFilterDto) {
            filter.author = rootState.user as User;
            filter.authorId = rootState.user.id;
            const dto = filter.id ? await putFilter(filter) : await postNewFilter(filter);
            if (dto) {
                commit(types.SET_ACTIVE_FILTER, dto);
                commit('addNewFilter', dto);
            }
            return dto;
        },
        async removeFilter({ commit }, filterId: number) {
            const deleted = await deleteFilter(filterId);
            if (deleted) {
                commit('removeFilter', filterId);
            }
        },
    },
};

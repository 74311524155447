import { DocumentsAttribute } from './document-attributes.model';
import { ExternalSystem } from './external-systems.model';
import { User } from './users.model';
import moment from 'moment';
import { DictionaryValueDTO } from './dictionaries.model';
import { Store } from '@/models/commons';

export enum DocumentsStatus { ACTIVE = 'Действующий', ARCHIVE = 'Архив', DELETED = 'Удален' }
export enum Notifications { NotActive = '15027' }

export enum DocumentClassification { C0 = 'ГС ГО', C1 = 'ГС РегСеть', C2 = 'ОПК ГО', C3 = 'ОПК РегСеть' }
export enum DocumentFileType { Digital = 'Электронный вид', Paper = 'Скан копия / бумажный документ' }

export enum DocumentOwnershipType { BANK = 'Документ Банка', CLIENT = 'Документ Клиента' }
export enum DocumentKindType { COMPANY_GROUP = 'Документ ГК', LEGAL_ENTITY = 'Документ ЮЛ' }
export enum DocumentFormat {
    EDocWithESignature = 'Электронный документ с электронной подписью', ScanDocWithESignature = 'Скан-копия документа с электронной подписью',
    ScanDocWithoutESignature = 'Скан-копия документа без электронной подписи',
}
export const LegalEntityId = 3;
export enum DocumentsCategory {
    RED = '#FA6D74',
    ORANGE = '#F6B880', YELLOW = '#F6D982', GREEN = '#D1DD85', TEAL = '#7BBAB6', BLUE = '#6CBAE6', PURPLE = '#B985E9',
}

export interface DocumentCustomAttribute {
    attributeDefinition: DocumentsAttribute;
    code: string;
    value: any;
    dictionaryValue?: Store | null;
    title?: string;
    description?: string;
    identity?: string | number;
}

export interface Document {
    attachments?: File[];
    quarter?: number;
    dossierId?: number;
    documentFullName?: DictionaryValueDTO;
    documentShortName?: DictionaryValueDTO | null;
    additionalDate: number | null;
    author: User | null;
    attributes: DocumentCustomAttribute[]; // Documents attribute +
    childDocuments?: Document[];
    childrenIds: number[]; // Child documents id's
    clientCRMId?: string;
    contentId?: string;
    creator?: User;
    crmSegmentationGroupName?: string;
    crmSegmentationGroupId?: string;
    created: number;
    createDate: number; // TS: Дата создания документа
    companyGroup?: DictionaryValueDTO | null;
    contents?: DocumentsContent[]; // Base64 content of the uploaded file +
    currentVersionId: number; // Current version id +
    currentVersionNumber: number; // Current version number +
    customerName: string; // Название клиента
    deleted: boolean; // Check mark for logical deleted +
    description: string;
    documentsCategory?: DocumentsCategory;
    documentClassification?: DocumentClassification; // Documents classification +
    documentFormat?: DocumentFormat;
    documentComments: string[]; // TS: Комментарии
    documentsDate: number | null; // TS: Дата документа
    documentId: number; // id +
    documentName: string; // name + TS: Название документа.
    documentNotes: string[]; // TS: Примечания.
    documentNumber: string; // TS: Номер документа

    documentStatus: DocumentsStatus; // status +
    documentTypeId?: number; // Id of the documents type
    ownershipType?: DocumentOwnershipType;
    documentKind?: DocumentKindType;
    documentVersions?: Document[];
    dossier: DictionaryValueDTO | null;
    legalEntity: DictionaryValueDTO | null;
    forgetOldParents: boolean;
    isChild: boolean;
    favorite: boolean; // Check mark as favorite documents +
    foundData?: string;
    fromUI: true;
    valid: boolean; // TS: Валидность документа.
    storeTill?: number | null; // TS: Дата окончания хранения документа
    storeOriginalTill: number | null;
    operationName?: string; // TS: Операция
    outerSystem?: ExternalSystem; // TS: Источник данных
    parentDocuments?: Document[];
    parentIds: number[]; // parent of the document +
    requestedVersionNumber: number;
    signatories: Array<string | null>;
    updated: number;
    versionName?: '';
    versionNumber?: number;
    notifications?: string;
    watcher?: User; // Набдюдатель
    watchers?: User[];

    _documentFileType: DocumentFileType;
}

export interface DocumentsContent {
    b64content: string;
    fileName: string;
    mimeType: string;
    size: number;
}

export interface DocumentsDeletedInfo {
    documentId: number;
    value: boolean;
    versionNumber: number;
}

export interface DocumentsFilter {
    id: number;
    name: string;
    items: DocumentsFilterItem[];
}

export interface DocumentsFilterItem {
    attribute: DocumentsAttribute;
    value: string | number | boolean | Array<string | number>;
    valuesRepresentation: Array<{ id: number, value: string }>;
}

export function createEmptyDocument(): Document {
    return {
        attachments: [],
        additionalDate: null,
        author: null,
        attributes: [],
        childrenIds: [],
        created: 0,
        createDate: 0,
        documentComments: [],
        currentVersionId: 0,
        currentVersionNumber: 0,
        customerName: '',
        legalEntity: null,
        deleted: false,
        description: '',
        documentsDate: 0,
        documentId: 0,
        documentName: '',
        documentNumber: '',
        notifications: 'Неактивно',
        documentClassification: ('' as any),
        documentStatus: ('ACTIVE' as any),
        dossier: null,
        forgetOldParents: false,
        isChild: false,
        favorite: false,
        fromUI: true,
        valid: true,
        documentNotes: [],
        parentIds: [],
        signatories: [null, null],
        storeTill: null,
        storeOriginalTill: null,
        requestedVersionNumber: 0,
        updated: 0,
        _documentFileType: DocumentFileType.Digital,
    };
}


export function createNewDocumentsFilter(): DocumentsFilter {
    return {
        id: 0,
        name: '',
        items: [],
    };
}

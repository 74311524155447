import { ExternalSystem } from '@/models/external-systems.model';
import { $HTTPServicesClient as axiosClient, getUrlString, QueryParams } from '@/commons/axios.config';


export async function fetchExternalSystems(token: string, params?: QueryParams) {
    let externalSystems;
    try {
        const url = getUrlString('dictionaries/outer-systems', params);
        const request = await axiosClient.get(url, {
            headers: {
                'x-auth-token': token,
            },
        });
        externalSystems = (request.data as {data: ExternalSystem[]}).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return externalSystems;
}

export async function fetchExternalSystemById(token: string, id: number) {
    let externalSystem;
    try {
        const request = await axiosClient.get(`/dictionaries/outer-systems/${id}`, {
            headers: {
                'x-auth-token': token,
            },
        });
        externalSystem = (request.data as {data: ExternalSystem}).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return externalSystem;
}

export async function queryItSystemsByName(name: string) {
    let externalSystem;
    try {
        const url = getUrlString('dictionaries/it-systems', {offset: 0, limit: 1000, query: name});
        const request = await axiosClient.get(url, {
            headers: {
                'x-auth-token': localStorage.getItem('elib-token'),
            },
        });
        externalSystem = (request.data as {data: ExternalSystem[]}).data.map((system) => {
            return {
                id: system.id,
                value: system.name,
                system,
            };
        });
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return externalSystem || [];
}

export async function queryExternalSystemsByName(name: string) {
    let externalSystem;
    try {
        const request = await axiosClient.get(`/dictionaries/outer-systems?query=${name}`, {
            headers: {
                'x-auth-token': localStorage.getItem('elib-token'),
            },
        });
        externalSystem = (request.data as {data: ExternalSystem[]}).data.map((system) => {
            return {
                id: system.id,
                value: system.name,
                system,
            };
        });
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return externalSystem || [];
}

export async function postNewExternalSystem(token: string, data: ExternalSystem) {
    let system;
    try {
        const request = await axiosClient.post(`/dictionaries/outer-systems`, JSON.stringify(data), {
            headers: {
                'x-auth-token': token,
                'content-type': 'application/json',
            },
        });
        system = (request.data as { data: ExternalSystem }).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return system;
}

export async function updateExistingExternalSystem(token: string, data: ExternalSystem) {
    let system;
    try {
        const request = await axiosClient.put(`/dictionaries/outer-systems/${data.id}`, JSON.stringify(data),
            {
                headers: {
                    'x-auth-token': token,
                    'content-type': 'application/json',
                },
            },
        );
        system = (request.data as { data: ExternalSystem }).data;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return system;
}

export async function deleteExternalSystem(token: string, data: ExternalSystem) {
    try {
        await axiosClient.delete(`/dictionaries/outer-systems/${data.id}`,
            {
                headers: {
                    'x-auth-token': token,
                },
            },
        );
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
}








































import { Vue, Component, Prop } from 'vue-property-decorator';
import { MultiselectAssumption } from '@/models/view.model';

const baseClassName = 'elib-input-multiselect';

@Component({
  name: baseClassName,
})
export default class InputMultiselect extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string;
  @Prop() private value!: string;
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => [] }) private assumptions!: MultiselectAssumption[];
  private baseClassName = baseClassName;
  private isFocused = false;
  private query = '';
  private selectedValues: MultiselectAssumption[] = this.assumptions.filter((v) => v.selected);

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get assumptionItems() {
    return this.assumptions.filter((v) => new RegExp(`^.*(${this.query}).*$`, 'gi').test(v.value));
  }

  private onOptionSelect(mark: boolean, item: MultiselectAssumption) {
    if (mark) {
      this.selectedValues.push(item);
      return;
    }
    this.selectedValues = this.selectedValues.filter((v) => v.id !== item.id);
    this.$emit('selected', this.selectedValues);
  }
}























































































import { Prop, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import FilterItemMixin from './filter.mixin';

const baseClassName = 'elib-filters-item';

@Component({
  name: baseClassName,
})
export default class FiltersItem extends mixins(FilterItemMixin) {
  @Prop({ default: () => false }) private readonly!: boolean;
  private baseClassName = baseClassName;
  private isActive: boolean = false;

  private get title() {
    return this.filterItem.attribute.title;
  }

  private get defaultInput() {
    return this.isString || this.isNumber;
  }

  @Watch('isActive', { immediate: true })
  private onActiveStateChange(val: boolean, oldVal: boolean) {
    if (val) {
      this.queryDictionaryValues();
    }
  }

  private created() {
    this.isActive = !this.filterMode;
    this.initializeDictionaryValues();
    this.runValueInitializer();
  }
}

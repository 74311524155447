import Vue from 'vue';
import NotifierTemplate from './Notifier.vue';
import { CombinedVueInstance } from 'vue/types/vue';

interface NotifierOptions {
    id: string;
    title: string;
    message: string;
    type: 'success' | 'warn' | 'error';
    onClose: (id: string) => any | undefined | void;
    offset: number;
    animationDuration?: number;
}
type NotifierVueInstance = CombinedVueInstance<Record<never, any> &
    Vue, NotifierOptions, object, object, Record<never, any>>;

let instanceCount = -1;
let instances: NotifierVueInstance[] = [];
const NotifierConstructor = Vue.extend(NotifierTemplate);

const notifier = (options: NotifierOptions)  => {
    const id = `notifier-instance__${++instanceCount}`;
    options.animationDuration = options.animationDuration || 500;
    options.offset = instances.reduce((acc, v) => {
        const offsetHeight = (v.$el as HTMLElement).offsetHeight;
        return acc += (offsetHeight + 16);
    }, 16);
    options.onClose = () => closeNotifierInstance(id);
    const instance = new NotifierConstructor({
        data: options,
    }) as NotifierVueInstance;
    instance.id = id;
    instance.$mount();
    document.body.appendChild(instance.$el);
    instances.push(instance);
    return instance;
};

function closeNotifierInstance(id: string) {
    const index = instances.findIndex((v) => v.id === id);
    const instance = instances[index];
    instances = instances.filter((v) => v.id !== id);
    if (instance) {
        instance.$destroy();
        document.body.removeChild(instance.$el);
    }
    return index;
}

export default notifier;

import Vue from 'vue';
import DropdownDotted from '@/components/Dropdowns/Dropdown__dotted.vue';
import DropdownFiltersMenu from '@/components/Dropdowns/Dropdown__filters-menu.vue';
import DropdownAttributes from '@/components/Dropdowns/Dropdown__attributes.vue';

export default (vue: typeof Vue, options: {[key: string]: any}): void => {
        Vue.component('elib-dropdown-dotted', DropdownDotted);
        Vue.component('elib-dropdown-filters-menu', DropdownFiltersMenu);
        Vue.component('elib-dropdown-attributes', DropdownAttributes);
};

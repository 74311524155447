











import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-icon-arrow';

@Component({
  name: baseClassName,
})
export default class IconArrow extends Vue {
  @Prop({ default: () => 8 }) private width!: number;
  @Prop({ default: () => 6 }) private height!: number;
  private baseClassName = baseClassName;
}

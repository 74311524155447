













































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { Document, DocumentsCategory, DocumentClassification } from '@/models/user-documents.model';
import { TreeViewData } from '@/models/view.model';
import { getDateRepresentation } from '@/commons/filters.main';
import { navigateToDocumentsEditForm } from '@/components/UserDocuments/UserDocuments.module';
import * as types from '@/store/types';

const baseClassName = 'elib-documents-list-item';

@Component({
  name: baseClassName,
  computed: {
    ...mapGetters('catalogues', ['cataloguesTreeViewNodeById']),
    ...mapGetters('session', ['canEditDocument']),
    ...mapState('documents', [types.SEARCH_IN_CONTENT, types.SEARCH_STRING]),
  },
  methods: {
    ...mapMutations('documents', ['setActiveDocument']),
    ...mapActions('documents', [types.FETCH_DOCUMENT_BY_ID]),
  },
})
export default class DocumentsListItem extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ required: true }) private usersDocument!: Document;
  private baseClassName = baseClassName;
  private documentsCategory = DocumentsCategory;
  private documentClassification = DocumentClassification;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get documentsTypePath() {
    const node = (this as any).cataloguesTreeViewNodeById(this.usersDocument.documentTypeId) as TreeViewData;
    if (!node) {
      return;
    }
    return node.path;
  }

  private get documentsName() {
    const document = this.usersDocument as Document;
    return `${document.documentName} №${document.documentNumber || '---'} `
      + (document.documentsDate ? `от ${getDateRepresentation(document.documentsDate)}` : '');
  }

  private get documentAdditionalInfo() {
    const author = !!this.usersDocument.author ? this.usersDocument.author.fullName : '';
    const client = this.usersDocument.clientCRMId || '';
    const createDate = `Хранится до ` +
      `${this.usersDocument.storeTill ? getDateRepresentation(this.usersDocument.storeTill) : ''}`;
    return [author, client, createDate].filter((v) => v !== '').join('  •  ');
  }

  private get isFullTextSearch() {
    return (this as any)[types.SEARCH_IN_CONTENT]
      && (this as any)[types.SEARCH_STRING]
      && (this as any)[types.SEARCH_STRING].trim().length > 0;
  }

  private get tokensArray() {
    const text = this.usersDocument.foundData || '';
    const result = [];
    const tag = 'em';
    let index = 0;
    while (index > -1) {
      const from = index + text.substring(index).search(new RegExp('<' + tag + '>', 'gim')) + tag.length + 1;
      const till = from + text.substring(from).search(new RegExp('</' + tag + '>', 'gim'));
      if (till <= from) {
        break;
      }
      result.push({ isToken: false, value: text.substring(index ? index + 2 : index, from - tag.length - 2) });
      result.push({ isToken: true, value: text.substring(from + 1, till) });
      index = from > -1 ? till + tag.length + 1 : -1;
    }
    return result;
  }

  private async openDocument() {
    this.$router.push({ name: 'documents-profile', params: { id: this.usersDocument.documentId.toString() } });
  }

  private navigateToEditForm(ev: Event) {
    ev.stopPropagation();
    navigateToDocumentsEditForm(this.usersDocument);
  }
}




















import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-icon-filter';

@Component({
  name: baseClassName,
})
export default class IconFilter extends Vue {
  @Prop({ default: () => 18 }) private width!: number;
  @Prop({ default: () => 12 }) private height!: number;
  @Prop({ default: () => false }) private isActive!: boolean;
  private baseClassName = baseClassName;
}


































import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-input-autocomplete-functional';

@Component({
  name: baseClassName,
})
export default class InputAutocompleteFunctional extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string;
  @Prop({ default: () => '' }) private value!: string;
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => [] }) private assamptions!: Array<{ id: string | number, value: any }>;
  @Prop({ default: () => false }) private required!: boolean;
  @Prop({ default: () => false }) private horizontal!: boolean;
  @Prop({ default: () => () => null}) private queryFunction!: (...args: any) => Array<{[key: string]: any}>;
  @Prop({ default: () => 'id'}) private idField!: string;
  @Prop() private valueField!: string;
  @Prop() private formatFunction!: (...args: any) => any;
  @Prop({ default: () => 100}) private queryLimit!: number;
  private items: Array<{id: number, value: string}> = [];
  private query = '';
  private baseClassName = baseClassName;
  private isFocused = false;

  private get isValid() {
    return (this.required && this.value !== '') || !this.required;
  }

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private async onInput(query: string) {
    const data = await this.queryFunction({offset: 0, query, limit: this.queryLimit});
    this.items = data.map((item: {[key: string]: any}) => {
      const value = this.formatFunction ? this.formatFunction(item) : item[this.valueField];
      return {id: item[this.idField], value, item};
    });
  }

  private onSelect($event: {[key: string]: any}) {
    this.query = $event.item[this.valueField];
    this.isFocused = false;
    this.$emit('select', $event.item);
  }

  private created() {
    this.query = this.value;
    this.onInput('');
  }

  private mounted() {
    this.onComponentEnter();
  }

  private beforeDestroy() {
    this.onComponentLeave();
  }

  private onComponentEnter() {
    window.addEventListener('click', this.onClickOutised);
  }

  private onComponentLeave() {
    window.removeEventListener('click', this.onClickOutised);
  }

  private onClickOutised(event: Event) {
    const element = event.target as HTMLElement;
    this.isFocused = (this.$refs.section as HTMLElement).contains(element) || element === this.$refs.section;
    const checkedValue = this.items.find((item) => item.value === this.query);
    if (!checkedValue) {
      this.query = this.value;
    }
  }
}

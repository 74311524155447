



































































import { Vue, Prop, Component } from 'vue-property-decorator';
import { DocumentsAttribute, DocumentsStaticAttribute } from '../../models/document-attributes.model';
import { DictionariesValue } from '../../models/dictionaries.model';
import { DocumentsFilterItem } from '../../models/user-documents.model';

const baseClassName = 'elib-dropdown-attributes';

@Component({
  name: baseClassName,
})
export default class DropdownAttributes extends Vue {
  @Prop() private attribute!: DocumentsAttribute | DocumentsStaticAttribute;
  @Prop() private initialValue!: number | string | boolean | any[];
  @Prop({ default: () => [] }) private initialValuepresentations!: Array<{ id: number, value: string }>;
  @Prop({ default: () => false }) private viewOnly!: boolean;
  private value: number | string | boolean = '';
  private values: any[] = [];
  private valuesRepresentation: Array<{ id: number, value: string }> = [];
  private baseClassName = baseClassName;
  private showValues = false;


  private get filterItemValues(): DocumentsFilterItem {
    return {
      attribute: this.attribute as DocumentsAttribute,
      value: [...this.values],
      valuesRepresentation: [...this.valuesRepresentation || []],
    };
  }

  private get filterItemValue(): DocumentsFilterItem {
    return {
      attribute: this.attribute as DocumentsAttribute,
      value: this.value,
      valuesRepresentation: [],
    };
  }

  private applyFilter() {
    this.values.length ? this.$emit('apply', this.filterItemValues) : this.$emit('apply', this.filterItemValue);
    this.showValues = false;
    this.values = [];
    this.value = '';
  }

  private created() {
    if (this.initialValue && this.initialValue instanceof Array) {
      this.values = [...this.initialValue];
    } else if (this.initialValue !== undefined) {
      this.value = this.initialValue as any;
    }
    if (this.initialValuepresentations) {
      this.valuesRepresentation = [...this.initialValuepresentations];
    }
  }

  private onAssumptionsSelect($event: any[], idKey: string, valueKey: string) {
    this.values = $event.map((v) => v[idKey]);
    this.valuesRepresentation = $event.map((v) => {
      const formatFunction = (this.attribute as DocumentsStaticAttribute).formatFunction;
      const valuesRepresentation = formatFunction ? formatFunction(v.item) : v.item[valueKey];
      return { id: v[idKey], value: valuesRepresentation };
    });
  }

  private onDictionaryValueSelect(dicValue: DictionariesValue, mark: boolean) {
    if (!mark) {
      this.values = [...this.values.filter((id) => id !== dicValue.id)];
      return;
    }
    const index = this.values.findIndex((v) => v.id === dicValue.id);
    index >= 0 ? this.values[index] = dicValue.id : this.values.push(dicValue.id);
  }
}

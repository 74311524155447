



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { User } from '@/models/users.model';
import { Catalogue, createNewCatalogue } from '@/models/catalogues.model';
import { DocumentsFilterItemDto } from '@/models/filters.model';
import types from '@/store/types';

const baseClassName = 'elib-document-types-tree';

@Component({
  name: baseClassName,
  computed: {
    ...mapState('catalogues', ['cataloguesList', 'filterString']),
  },
  methods: {
    ...mapActions('catalogues', ['getCatalogues']),
    ...mapMutations('documents', [types.SET_DOCUMENTS_LIST, types.SET_FILTERS_LIST, types.SET_ACTIVE_DOCUMENT_TYPE]),
    ...mapActions('documents', [types.GET_USER_DOCUMENTS]),
    ...mapMutations('filters', ['setActiveFilter']),
  },
})
export default class CatalogueList extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private newCatalogueLevel: Catalogue | null = null;
  private self: any;

  private created() {
    this.onComponentEnter();
  }

  private beforeCreate() {
    this.self = this;
  }

  private activated() {
    this.onComponentEnter();
  }

  private onComponentEnter() {
    this.self.getCatalogues();
  }

  private resetFilters() {
     this.self.setDocumentsList([]);
     this.self.setFiltersList([]);
     this.self.setActiveDocumentType(null);
     this.self.getUserDocuments({offset: 0});
     this.self.setActiveFilter(null);
  }

}

export function getDateRepresentation(value: number | null) {
    if (!value || value === null) {
        return '';
    }
    const date = new Date(value);
    if (isNaN(date.valueOf())) {
        return 'Invalid date';
    }
    const day = date.getDate() > 9 ? date.getDate().toString() : '0' + date.getDate().toString();
    const month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1).toString() : '0' + (date.getMonth() + 1).toString();
    return `${day}.${month}.${date.getFullYear()}`;
}

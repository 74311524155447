var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.className},[(!_vm.loading)?_c('section',{ref:"content",class:(_vm.baseClassName + "__content")},[(_vm.activeDocument)?_c('form',{class:(_vm.baseClassName + "__form"),on:{"submit":function($event){$event.preventDefault();}}},[_c('section',{class:(_vm.baseClassName + "__title-section")},[_c('span',{directives:[{name:"elib-tooltip",rawName:"v-elib-tooltip",value:(_vm.activeDocument.documentName),expression:"activeDocument.documentName"}],class:(_vm.baseClassName + "__title-section__name")},[_vm._v(_vm._s(_vm.activeDocument.documentName))]),_c('span',{directives:[{name:"elib-tooltip",rawName:"v-elib-tooltip",value:(_vm.documentsNumberDateString),expression:"documentsNumberDateString"}],class:(_vm.baseClassName + "__title-section__date-number")},[_vm._v(_vm._s(_vm.documentsNumberDateString))])]),_c('span',{class:(_vm.baseClassName + "__documents-type")},[_vm._v(_vm._s(_vm.documentsTypePath))]),_c('span',{class:(_vm.baseClassName + "__last-change-info")},[_vm._v(_vm._s(_vm.changeLogString))]),_c('section',{class:[(_vm.baseClassName + "__document-upload-section")]},[_c('h5',[_vm._v("Вложение")]),_vm._l((_vm.fileInfos),function(fileInfo){return _c('elib-input-file',{key:fileInfo.fileName,class:[(_vm.baseClassName + "__document-upload"), 'attachment'],attrs:{"storeTill":_vm.activeDocument.storeTill,"placeholder":"Перетащите файл в эту область или выберите на диске","fileInfo":fileInfo,"disabled":!_vm.canEditAttachments,"fetchFucntion":function () { return _vm.fetchDocumentsContent(
                _vm.activeDocument.documentId,
                fileInfo.fileName,
                _vm.activeDocument.currentVersionNumber
              ); }},on:{"delete":function($event){return _vm.deleteFilesAttachment($event)}}})})],2),_c('linked-documents',{key:_vm.documentId,class:[(_vm.baseClassName + "__linked-documetns")],attrs:{"usersDocument":_vm.activeDocument,"preview":true},on:{"document-link-click":_vm.navigateToDocument}}),_c('section',{class:(_vm.baseClassName + "__attributes-section")},[_c('h5',[_vm._v("Атрибуты")]),_vm._l((_vm.sortedAttributes),function(attrib){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(attrib.content),expression:"attrib.content"}],key:attrib.title,class:(_vm.baseClassName + "__attributes-row")},[_c('span',[_vm._v(_vm._s(attrib.title))]),_c('span',{directives:[{name:"elib-tooltip",rawName:"v-elib-tooltip",value:(attrib.content),expression:"attrib.content"}],class:(_vm.baseClassName + "__attributes-content")},[_vm._v(_vm._s(attrib.content))])])})],2)],1):_vm._e(),(_vm.activeDocument)?_c('section',{class:(_vm.baseClassName + "__additional-info")},[_c('div',{class:(_vm.baseClassName + "__valid-tag")},[(_vm.activeDocument.valid === false)?_c('span',{class:(_vm.baseClassName + "__document-not-valid")},[_vm._v("Не верифицирован")]):_vm._e(),(_vm.activeDocument.valid === true)?_c('span',{class:(_vm.baseClassName + "__document-valid")},[_vm._v("Верифицирован")]):_vm._e()]),_vm._l((_vm.visibleAdditinalRows),function(attrib){return _c('div',{key:attrib.title,class:(_vm.baseClassName + "__additional-info-row")},[_c('span',[_vm._v(_vm._s(attrib.title))]),_c('span',[_vm._v(_vm._s(attrib.content))])])}),_c('div',{class:(_vm.baseClassName + "__additional-info-row")},[_c('span',[_vm._v("Метка")]),_c('div',{class:(_vm.baseClassName + "__documents-category"),style:({
            backgroundColor:
              _vm.documentsCategory[_vm.activeDocument.documentsCategory],
          })})]),_c('elib-icon-version',{attrs:{"incomingClasses":[(_vm.baseClassName + "__icon-version")]},nativeOn:{"click":function($event){return _vm.toggleVersionBar($event)}}}),_c('div',{class:(_vm.baseClassName + "__info-actions")},_vm._l((_vm.profileActions),function(action,key){return _c('div',{key:key,class:(_vm.baseClassName + "__info-action"),on:{"click":action.action}},[_c(action.icon,{key:action.icon,tag:"component",class:(_vm.baseClassName + "__content-section"),attrs:{"fill":"#282423"}}),_c('span',[_vm._v(_vm._s(action.title))])],1)}),0)],2):_vm._e()]):_vm._e(),(_vm.showVersions && !_vm.loading)?_c('elib-documents-versions-bar',{class:(_vm.baseClassName + "__versions-info"),attrs:{"userDocument":_vm.activeDocument},on:{"close":function($event){_vm.showVersions = false},"clicked":_vm.updateAdditinalAttributes}}):_vm._e(),_c('loading-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"title":'Пожалуйста подождите, идет загрузка документа'}}),(!_vm.loading)?_c('button',{class:(_vm.baseClassName + "__back-to-list btn btn_md btn_back"),on:{"click":function($event){return _vm.$router.push({ name: 'documents' })}}},[_vm._v(" К списку ")]):_vm._e(),(_vm.showModal)?_c('elib-modal',[_c('elib-documents-delete-dialog',{attrs:{"active-document":_vm.activeDocument,"permanently-delete":_vm.permanentlyDelete},on:{"close":function($event){_vm.showModal = false},"delete":_vm.onComponentEnter}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { Module } from 'vuex';
import { User } from '@/models/users.model';
import { authenticateToApplication, fetchRestoreSession, getServerVersion } from '@/controllers/sessions.controllers';
import { Document } from '@/models/user-documents.model';
import { DocumentStaticAttributes } from '@/models/document-attributes.model';
import * as types from '@/store/types';
import { RolesDocumentAttribute } from '@/models/roles.model';

interface ModuleState {
    user: User | undefined;
    token: string | null;
    serverVersion: string | undefined;
    isAdmin: boolean;
    grantedAuthorities: Set<number>;
    authoritiesNames: Set<string>;
}

export const module: Module<ModuleState, any> = {
    namespaced: true,
    state: {
        user: undefined,
        token: localStorage.getItem('elib-token'),
        serverVersion: undefined,
        isAdmin: false,
        grantedAuthorities: new Set(),
        authoritiesNames: new Set(),
    },
    mutations: {
        setActiveUser(state: ModuleState, payload: User) {
            state.user = payload;
            if (payload) {
                state.grantedAuthorities = new Set(payload.roles.
                    filter((v) => v && !!v).
                    map((role) => {
                        return role.roleAuthorities.filter((v) => !!v).map((v) => v.id);
                    }).flat().sort());
                state.authoritiesNames = new Set(payload.roles.map((role) => {
                    return role.roleAuthorities.filter((v) => !!v).map((v) => v.title);
                }).flat().sort((a, b) => a > b ? 1 : -1));
            }
        },
        setSessionToken(state: ModuleState, payload: string) {
            localStorage.setItem('elib-token', payload);
            state.token = payload;
        },
        setServerVersion(state: ModuleState, payload: string) {
            state.serverVersion = payload;
        },
        resetState(state: ModuleState) {
            state.user = undefined;
            state.token = null;
            state.isAdmin = false;
        },
        isAdmin(state: ModuleState) {
            if (state.user) {
                // const rolesString = JSON.stringify(state.user.roles.map((v) => v.title));
                // state.isAdmin = RegExp(/(админ)|(admin)/, 'gi').test(rolesString);
                const adminAuthorities = [1, 2, 3, 4, 5, 6, 30, 32];
                [...state.grantedAuthorities].forEach((value) => {
                    if (!state.isAdmin) {
                        state.isAdmin = adminAuthorities.includes(value);
                    }
                });
            } else {
                state.isAdmin = false;
            }

        },
    },
    getters: {
        [types.HAS_AUTHORITY_BY_CODE]: ({ grantedAuthorities }) => {
            return (code: number) => grantedAuthorities.has(code);
        },
        [types.USER_ACCESS_DOCUMENT_ATTRIBUTES]: ({user}) => {
            return !!user ? user.roles.map((role) => role.attributes).flatMap((attribs) => attribs) : [];
        },
        [types.CHECK_AUTHORITIES_FOR_DOCUMENTS_EDIT]: ({ grantedAuthorities, user }, getters) => {
            return (doc: Document) => {
                if (grantedAuthorities.has(24)) {
                    return true;
                }
                const userDepartment = user && user.department && !!user.department ?
                    user.department : undefined;
                const docDepartment = doc.author && !!doc.author && doc.author.department ?
                    doc.author.department : undefined;
                const isOwnDepartment = userDepartment && docDepartment ?
                    userDepartment.id === docDepartment.id : false;
                if (isOwnDepartment && grantedAuthorities.has(26)) {
                    return true;
                }
                const isOwnDocument = user && !!doc.author ? user.id === doc.author.id : false;
                if (isOwnDocument && grantedAuthorities.has(25)) {
                    return true;
                }
                const isCreator = user && !!doc.creator ? user.id === doc.creator.id : false;
                if (isCreator && grantedAuthorities.has(25)) {
                    return true;
                }
                if (getters[types.HAS_ACCESS_BY_DOCUMENT_ATTRIBUTES](doc)) {
                    return true;
                }
                return false;
            };
        },
        [types.HAS_ACCESS_BY_DOCUMENT_ATTRIBUTES]: ({user}, getters) => {
            return (doc: Document) => {
                const roleAttribs = getters[types.USER_ACCESS_DOCUMENT_ATTRIBUTES] as RolesDocumentAttribute[];
                const hasAccessByStaticAttributes = !!doc.attributes && doc.attributes
                .some((attrib) => {
                    switch (attrib.code) {
                        case DocumentStaticAttributes.DOCUMENT_DATE:
                            return doc.documentsDate && doc.documentsDate.toString() === attrib.value;
                        case DocumentStaticAttributes.DOCUMENT_NUMBER:
                            return doc.documentNumber === attrib.value;
                        case DocumentStaticAttributes.DOCUMENT_OPERATION:
                            return doc.operationName === attrib.value;
                        case DocumentStaticAttributes.DOCUMENT_TYPE:
                            return doc.documentTypeId && doc.documentTypeId.toString() === attrib.value;
                        default:
                            return false;
                    }
                });
                const hasAccessByCustomAttributes =  !!doc.attributes
                    && doc.attributes
                    .some((attrib) => !!roleAttribs.find((v) => v.code === attrib.code && v.value === attrib.value));
                return hasAccessByCustomAttributes || hasAccessByStaticAttributes;
            };
        },
    },
    actions: {
        async authenticate({ commit }, payload: { login: string, password: string }) {
            const sessionData = await authenticateToApplication(payload);
            if (sessionData?.user && sessionData?.token) {
                commit('setActiveUser', sessionData.user);
                commit('setSessionToken', sessionData.token);
                commit('setAuthData', sessionData, { root: true });
                commit('isAdmin');
            }
            return sessionData;
        },
        async restoreSession({ commit, state }) {
            const user = await fetchRestoreSession();
            const serverVersion = await getServerVersion();
            commit('setServerVersion', serverVersion);
            if (user) {
                commit('setActiveUser', user);
                commit('setAuthData', { user }, { root: true });
                commit('isAdmin');
            }
            return user;
        },
    },
};










import { Vue, Component, Prop } from 'vue-property-decorator';
import { User } from '@/models/users.model';

export const baseClassName = 'elib-profile-account-info';

@Component({
  name: baseClassName,
})
export default class ProfileAccountInfo extends Vue {
    @Prop({ default: () => [] }) private incomingClasses!: string[];
    @Prop() private value!: User;
    private baseClassName = baseClassName;
}

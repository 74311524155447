






































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapState, mapMutations, mapActions } from 'vuex';
import { ExternalSystem, getEmptyExternalSystem, ITSystem } from '@/models/external-systems.model';
import { queryItSystemsByName } from '@/controllers/external-systems.controllers';
import { User } from '@/models/users.model';
import { SelectOption } from '@/models/view.model';
import { fetchUsers } from '@/controllers/users.controllers';

interface LocalSelectOption extends SelectOption {
  user: User;
}

interface SystemsAssumption {
  id: number;
  value: string;
  system: ExternalSystem;
}

const baseClassName = 'elib-external-system-edit-form';

@Component({
  name: baseClassName,
  computed: {
    ...mapState('systems', ['activeExternalSystem']),
    ...mapState('users', ['hdfsUsersList']),
  },
  methods: {
    ...mapMutations('systems', ['setActiveExternalSystem', 'updateActiveExternalSystemProperty',
      'setActiveITSystem', 'mergeExternalSystemProperties']),
    ...mapActions('users', ['getHdfsUsers']),
    ...mapActions('systems', ['updateExternalSystem', 'createNewExternalSystem', 'removeExternalSystem']),
  },
})
export default class ExternalSystemEditForm extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private isFocused = false;
  private systemsAssumptions: SystemsAssumption[] = [];
  private showModal = false;
  private fetchUsers = fetchUsers;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get externalSystem() {
    return Object.assign({}, (this as any).activeExternalSystem) as ExternalSystem;
  }

  private get userName() {
    const activeSystem = (this as any).activeExternalSystem as ExternalSystem;
    return activeSystem && activeSystem.user ? activeSystem.user.login : 'Выберите';
  }

  private get usersAsOptions(): LocalSelectOption[] {
    const users = ((this as any).hdfsUsersList || []) as User[];
    return users.map((user) => {
      return {
        id: user.id,
        name: user.login || user.fullName,
        selected: false,
        user,
      };
    });
  }

  private onSystemNameInput(name: string) {
    this.externalSystem.name = name || this.externalSystem.name;
    queryItSystemsByName(name).then((data) => this.systemsAssumptions = data as any);
    if (name !== undefined && name.trim().length === 0) {
      (this as any).mergeExternalSystemProperties({name: '', innerCode: '', outerCode: ''});
    }
  }

  private onITSystemSelect($event: { system: ITSystem }) {
    const activeSystem = (this as any).activeExternalSystem as ExternalSystem;
    delete $event.system.id;
    delete $event.system.user;
    $event.system.description = activeSystem.description || '';
    const mergeProps = {name: $event.system.name, innerCode: $event.system.code, outerCode: $event.system.code};
    (this as any).mergeExternalSystemProperties(mergeProps);
  }

  private created() {
    (this as any).getHdfsUsers();
    (this as any).onSystemNameInput();
  }

  private onLoginSelect(value: User) {
    (this as any).updateActiveExternalSystemProperty({ prop: 'user', value });
    this.isFocused = false;
  }

  private async saveSystem() {
    if (!this.formIsValid()) {
      return;
    }
    let transactionCommited = false;
    const system = (this as any).activeExternalSystem;
    if (system && system.id) {
      transactionCommited = await (this as any).updateExternalSystem(system);
    } else if (system && !system.id) {
      transactionCommited = await (this as any).createNewExternalSystem(system);
    }
    transactionCommited ? this.$emit('close') :
      this.$notifier({ title: 'Ошибка данных!',
        message: 'Не удалось сохранить внешнюю систему', type: 'error'});
  }

  private formIsValid() {
    const mainForm = this.$refs.mainForm as HTMLFormElement;
    if (!mainForm.checkValidity()) {
      this.$notifier({ title: 'Ошибка формы!', message: 'Не заполнены обязательны поля',
        type: 'error'});
      return false;
    }
    const systemName = this.externalSystem ? this.externalSystem.name : '';
    const systemNameIsValid = this.systemsAssumptions.find((v) => v.value.trim() === systemName.trim());
    if (!systemNameIsValid) {
      this.$notifier({ title: 'Ошибка в данных!', message: 'Не корректно заполнено наименование системы',
        type: 'error'});
      return false;
    }
    if (!this.externalSystem.user) {
      this.$notifier({ title: 'Ошибка в данных!', message: 'Не заполнен пользователь системы',
        type: 'error'});
      return false;
    }
    return true;
  }
}

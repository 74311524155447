



































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { DocumentsStatus, Document, DocumentCustomAttribute } from '@/models/user-documents.model';
import { Dictionary, DictionariesValue } from '@/models/dictionaries.model';
import { SelectOption } from '../../models/view.model';
import { User } from '../../models/users.model';
import { DocumentsAttribute } from '../../models/document-attributes.model';
import DocumentAttribute from '@/components/UserDocuments/EditForm/FormAttribute.vue';

const baseClassName = 'elib-documents-form-custom-attributes';

@Component({
  name: baseClassName,
  components: {
    DocumentAttribute,
  },
  computed: {
    ...mapState('documents', ['activeDocument']),
    ...mapState('attributes', ['documentAttributesList']),
  },
  methods: {
    ...mapMutations('documents', ['updateActiveDocumentProperty', 'updateDocumentActiveAttribute',
      'updateDocumentArrayProp', 'addAttributeToActiveDocument', 'deleteCustomAttributeFromDocument',
      'setDocumentMandatoryAttributes']),
    ...mapActions('attributes', ['getDocumentsAttribute']),
  },
})
export default class DocumentFormCustomAttributes extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private selectNextCustomAttribute = false;
  private self!: any;
  private date = '';
  private manualyPositionedAttributes = [
    'company_group',
    'name_of_the_legal_entity',
    'dossier_section',
    'document_format',
    'document_period',
    'verification_completed',
    'document_card_date_create',
    'document_validity',
    'catalogue_storage_place',
    'short_title',
    'full_title',
    'inn',
    'document_ownership',
    'document_kind',
    'dossier_kind',
    'notifications',
    'document_period_year',
  ];


  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get atrributeAssamptions() {
    const addedAttributeCodes = (this.self.activeDocument as Document).attributes.map((v) => v.code);
    const attributesList = this.self.documentAttributesList as DocumentsAttribute[];
    return attributesList.filter((attr) => !addedAttributeCodes.includes(attr.code)).
      map((attr, index) => {
        return {
          id: index,
          value: attr.title,
          attribute: attr,
        };
      });
  }

  private get documentAttributes() {
    return this.self.activeDocument.attributes as DocumentCustomAttribute[];
  }

  private get documentVisibleAttributes() {
    return this.documentAttributes.filter((attr) => !this.manualyPositionedAttributes.includes(attr.code));
  }

  private deleteAttribute(data: DocumentsAttribute) {
    this.self.deleteDocumentActiveAttribute(data);
    this.selectNextCustomAttribute = false;
  }

  private addNewAttribute(data: { attribute: DocumentsAttribute }) {
    this.self.addAttributeToActiveDocument(data.attribute);
    this.selectNextCustomAttribute = false;
  }

  private getDictionaryValueTitle(attribute: DocumentCustomAttribute, value: number) {
    if (typeof value === 'string') {
      value = parseInt(value, 10);
    }
    if (!value) {
      return '';
    }
    const dictionary = attribute.attributeDefinition.dictionary as Dictionary;
    const dictionariesValue = dictionary.dictionaryValues.find((v) => v.id === value) as DictionariesValue;
    return dictionariesValue ? dictionariesValue.title : '';
  }

  private created() {
    this.onComponentEnter();
  }

  private async onComponentEnter() {
    await this.self.getDocumentsAttribute({ limit: 1000 });
    this.self.setDocumentMandatoryAttributes(this.self.documentAttributesList);
  }

  private beforeCreate() {
    this.self = this;
  }

}

import Vue from 'vue';
import Router, { Route } from 'vue-router';
import store from '@/store/store';
import Home from './views/Home.vue';
import AuthPage from '@/views/Authentication.vue';
import AdminPage from '@/views/Administration.vue';
import DocumentsPage from '@/views/Documents.vue';
import DocumentsList from '@/components/UserDocuments/DocumentsList.vue';
import DocumentsEditForm from '@/components/UserDocuments/EditForm/Form.vue';
import DocumentsProfile from '@/components/UserDocuments/DocumentsProfile.vue';
import { AUTH_ROUTE_NAME } from '@/commons/constants';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      children: [
        {
          path: '/admin/:contentId',
          name: 'adminWithContentId',
          component: AdminPage,
          props: true,
        },
        {
          path: '/admin',
          name: 'admin',
          component: AdminPage,
        },
      ],
    },
    {
      path: '/auth',
      name: AUTH_ROUTE_NAME,
      component: AuthPage,
    },
    {
      path: '/documents',
      component: DocumentsPage,
      children: [
        {
          path: '/',
          name: 'documents',
          component: DocumentsList,
        },
        {
          path: '/create-new-document',
          name: 'create-new-document',
          component: DocumentsEditForm,
          beforeEnter: (to: Route, from: Route, next: (...arg: any) => any) => {
            const grantedAuthorities = (store.state as any).session.grantedAuthorities as Set<number>;
            if (!grantedAuthorities.has(17)) {
              (window as any).$app.$notifier({ title: 'Недостаточно прав!', message: 'У вас нет прав на создание документа',
              type: 'error'});
              return;
            }
            next();
          },
        },
        {
          path: '/documents/:id',
          name: 'documents-profile',
          component: DocumentsProfile,
          props: true,
          beforeEnter: (to: Route, from: Route, next: (...arg: any) => any) => {
            const grantedAuthorities = (store.state as any).session.grantedAuthorities as Set<number>;
            if (!grantedAuthorities.has(21)) {
              (window as any).$app.$notifier({ title: 'Недостаточно прав!', message: 'У вас нет прав на просмотр карточки документа',
              type: 'error'});
              return;
            }
            next();
          },
        },
        {
          path: '/edit-document/:id',
          name: 'edit-document',
          component: DocumentsEditForm,
          props: true,
        },
      ],
    },
  ],
});

router.beforeEach(async (to: Route, from: Route, next: (...args: any) => any) => {
  if (!store.state.user && to.name !== AUTH_ROUTE_NAME) {
    const user = await store.dispatch(`session/restoreSession`);
    if (!user) {
      next({ name: AUTH_ROUTE_NAME });
      return;
    }
  }
  next();
});

export { router };

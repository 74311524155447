





















import { Vue, Component } from 'vue-property-decorator';
import { queryDocumentAttributesByName } from '@/controllers/document-attributes.controllers';
import { DocumentsAttribute, AttributesTypeEnum } from '@/models/document-attributes.model';
import { DictionariesValue } from '@/models/dictionaries.model';
import { mapMutations, mapGetters } from 'vuex';
import { RolesDocumentAttribute } from '@/models/roles.model';
import * as types from '@/store/types';
import RolesAttributesEdit from '@/components/Administration/Roles/RolesAttributesEdit.vue';

type SelectedAttribute = RolesDocumentAttribute | null;

const baseClassName = 'roles-edit-document-attributes';

@Component({
  name: baseClassName,
  components: {
    RolesAttributesEdit,
  },
  computed: {
    ...mapGetters('roles', [types.ROLE_DOCUMENT_ATTRIBUTES]),
  },
  methods: {
    ...mapMutations('roles', ['updateActiveRoleProperty']),
  },
})
export default class RolesEditDocumentAttributes extends Vue {
  private baseClassName = baseClassName;
  private self: any;
  private documentAttributes: SelectedAttribute[] = [];
  private isNewAttributeEdit = false;

  private beforeCreate() {
    this.self = this;
  }

  private created() {
    this.documentAttributes = this.self[types.ROLE_DOCUMENT_ATTRIBUTES];
  }

  private addNewAttribute() {
    this.isNewAttributeEdit = true;
    this.documentAttributes.unshift(null);
  }

  private onAttributesChange(newValue: DocumentsAttribute, oldValue: SelectedAttribute, index: number) {
    this.documentAttributes[index] = this.mapDocumentsAttributeToRoleAttribute(newValue);
    if (oldValue === null || !oldValue) {
      this.isNewAttributeEdit = false;
    }
    this.documentAttributes = [...this.documentAttributes];
    this.$emit('change', this.documentAttributes.filter((v) => !!v));
  }

  private onValueChange($event: string, index: number) {
    this.documentAttributes.forEach((attrib, i) => {
      if (i === index && attrib) {
        attrib.value = $event;
      }
    });
    this.$emit('change', this.documentAttributes.filter((v) => !!v));
  }

  private onDocAttributeDelete(index: number) {
    this.isNewAttributeEdit = false;
    this.documentAttributes[index] = null;
    this.documentAttributes = this.documentAttributes.filter((v) => !!v);
    this.self.updateActiveRoleProperty({ prop: 'attributes', value: this.documentAttributes});
    this.$emit('change', this.documentAttributes);
  }

  private mapDocumentsAttributeToRoleAttribute(definition: DocumentsAttribute | null): RolesDocumentAttribute | null {
    if (definition) {
      return { attributeDefinition: definition, value: '', code: definition.code };
    }
    return null;
  }
}

import { fetchFilterAttributes } from '@/controllers/filters.controller';
import { filters, staticAttributes } from '@/dictionaries/filters';
import { Value } from '@/models/commons';
import { DocumentsAttribute, DocumentsAttributeOriginal } from '@/models/document-attributes.model';
import { DocumentsFilterItem } from '@/models/user-documents.model';
import { DocumentsFilterDto, DocumentsFilterItemDto } from '@/models/filters.model';
import { QueryParams } from '@/commons/axios.config';

export function mapDefinitionToValueItem(attrib: DocumentsAttribute) {
    const value = attrib.attributeType.code === 'DICTIONARY' ? [] : '';
    return { attribute: attrib, value, valuesRepresentation: [] };
}

export async function getAttributesDefinition(params: QueryParams = {}): Promise<DocumentsAttributeOriginal[]> {
    const attributes = await fetchFilterAttributes(params);
    const predefinedAttributes = [...staticAttributes].map((attrib) => {
        const value = { ...attrib };
        value.originalAttributeType = attributes.find((v) => v.code === attrib.code);
        return value;
    });
    const predefinedItems = [
        ...attributes.filter((attrib) => !staticAttributes.some((v) => v.code === attrib.code)),
        ...predefinedAttributes]
        .filter((attrib) => attrib.attributeType && filters[attrib.code])
        .sort((a, b) => filters[a.code].order - filters[b.code].order);
    const items = attributes
        .filter((attrib) => attrib.attributeType && !filters[attrib.code]);
    const pattern = new RegExp(params.query || '', 'gim');
    return [...predefinedItems, ...items].filter((attrib) => params.query ? pattern.test(attrib.title) : !!attrib);
}

export async function getAttributesDefinitionForRoles(params: QueryParams = {}): Promise<DocumentsAttributeOriginal[]> {
    return (await getAttributesDefinition(params)).filter((attrib) => {
        const staticAttrib = staticAttributes.find((v) => v.code === attrib.code);
        return staticAttrib ? staticAttrib.showInRoles : !!attrib;
    });
}

export async function getFilterItems(params: QueryParams = {}) {
    const definitions = await getAttributesDefinition(params);
    return [...definitions].map(mapDefinitionToValueItem);
}

export function setFilterItemsValue(storedFilters: DocumentsFilterItemDto[], filterDefinitions: DocumentsFilterItem[]) {
    return [...filterDefinitions].map((filter) => {
        const defenitionCode = filter.attribute.code.toLocaleLowerCase();
        const externalTable = filters[defenitionCode]?.externalTable;
        const externalField = filters[defenitionCode]?.externalField;
        const isExternal = externalTable && externalField;
        const isDictionary = filter.attribute?.attributeType?.code?.toLocaleUpperCase() === 'DICTIONARY';
        let value: string | number | boolean | Array<string | number> = '';
        if (isDictionary && !isExternal) {
            value = storedFilters.filter((v) => v.attributeDefinitionId === filter.attribute.id)
                .map((v) => v.dictionaryValueId as number);
        } else if (isDictionary && isExternal) {
            value = storedFilters
                .filter((v) => v.externalTable === externalTable && v.externalField === externalField)
                .map((v) => v.externalId as number);
        } else if (!isDictionary && isExternal) {
            const item = storedFilters
                .find((v) => v.externalTable === externalTable && v.externalField === externalField);
            value = item?.booleanValue || item?.dateValue || item?.numberValue || item?.stringValue || '';
        } else {
            const item = storedFilters.find((v) => v.attributeDefinitionId === filter.attribute.id);
            value = item?.booleanValue || item?.dateValue || item?.numberValue || item?.stringValue || '';
        }
        filter.value = value;
        return { ...filter };
    });
}

export async function valueIsProvided(item: DocumentsFilterItemDto) {
    if (valueIsTruthy(item.stringValue) && typeof item.stringValue === 'string' && item.stringValue.length) {
        return item.stringValue;
    }
    if (valueIsTruthy(item.numberValue) && typeof item.numberValue === 'number' && item.numberValue) {
        return item.numberValue;
    }
    if (valueIsTruthy(item.externalId)) {
        return item.externalId;
    }
    if (valueIsTruthy(item.booleanValue)) {
        return item.booleanValue;
    }
    return !!item.stringValue || !!item.numberValue || !!item.dictionaryValueId ||
        !!item.externalId || !!item.dateValue || item.booleanValue;
}

export function getComputedFilterCode(item: DocumentsFilterItemDto) {
    if (item.externalTable && item.externalField) {
        return item.externalTable + item.externalField;
    }
    return item.attributeDefinition?.code;
}

function valueIsTruthy(value: any) {
    return ![null, undefined].includes(value);
}

export function createFilterDTO(filter?: DocumentsFilterDto | null): DocumentsFilterDto {
    return {
        author: null,
        authorId: filter ? filter.authorId : 0,
        created: filter ? filter.created : Date.now().valueOf(),
        id: filter ? filter.id : 0,
        name: filter ? filter.name : '',
        specification: [],
    };
}

export function setFilterValues(filter: DocumentsFilterDto, items: DocumentsFilterItem[]): DocumentsFilterItem[] {
    const specification = filter.specification;
    return items.map((item) => {
        return { ...item, value: getValueFromFilterSpecification(specification, item) };
    });
}

function getValueFromFilterSpecification(specification: DocumentsFilterItemDto[], item: DocumentsFilterItem) {
    const code = item.attribute.code;
    const values = specification.filter((spec) => getFilterCode(spec) === code)
        .map((spec) => spec.booleanValue ?? spec.dateValue ?? spec.dictionaryValueId ??
            spec.externalId ?? spec.numberValue ?? spec.stringValue)
        .filter((v: any) => ![undefined, null].includes(v)) as Value;
    return extrudeValue(values, item);
}

function getFilterCode(item: DocumentsFilterItemDto) {
    const staticCode = Object.keys(filters).find((key) => filters[key].externalTable === item.externalTable
        && filters[key].externalField === item.externalField);
    return item.attributeDefinition?.code || staticCode;
}

function extrudeValue(values: Value, item: DocumentsFilterItem) {
    const code = item.attribute.code;
    const mapper = filters[code] && filters[code].mapper;
    const typeCode = item.attribute.attributeType.code;
    const value = typeCode === 'DICTIONARY' ? values : ((values as Array<string | number>)[0] || '');
    return mapper ? mapper(value) : value;
}













import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-icon-download';

@Component({
  name: baseClassName,
})
export default class IconDownload extends Vue {
  @Prop({ default: () => 10 }) private width!: number;
  @Prop({ default: () => 12 }) private height!: number;
  @Prop({ default: () => false }) private smallSize!: boolean;
  private baseClassName = baseClassName;
}











































import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-input-checkbox';

@Component({
  name: baseClassName,
})
export default class InputCheckbox extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private label!: string;
  @Prop({ default: () => '' }) private color?: string;
  @Prop({ default: () => false }) private colorBar?: boolean;
  @Prop({ default: () => false }) private value!: boolean;
  @Prop({ default: () => false }) private uppercase!: boolean;
  @Prop({ default: () => false }) private disabled!: boolean;
  private baseClassName = baseClassName;

  private get className() {
    const classes = (this.incomingClasses || []).concat(this.colorBar ? ['color-bar'] : []);
    return classes.reduce(
      (acc: string, value: string) => `${acc} ${value}`,
      baseClassName,
    );
  }
}














































import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { DocumentsAttribute, AttributesTypeFilter } from '@/models/document-attributes.model';
import { windowBottomIsReached } from '@/commons/dom.helpers';
import DocumentAttributesFilter from '@/components/Administration/DocumentAttributesFilter.vue';
import { VNode } from 'vue';
import types from '@/store/types';
import { QueryParams } from '@/commons/axios.config';
import debounce from 'debounce';

const baseClassName = 'elib-document-attributes-list';

@Component({
  name: baseClassName,
  components: {
    'elib-document-attributes-filter': DocumentAttributesFilter,
  },
  computed: {
    ...mapState('attributes', ['documentAttributesList', 'filterString', 'activeDocumentsAttribute', 'typeFilter']),
    ...mapGetters('attributes', ['actualDocumentAttributesList']),
    ...mapGetters('session', ['hasAuthorityByCode']),
  },
  methods: {
    ...mapMutations('attributes', [types.SET_DOCUMENT_ATTRIBUTES_LIST, types.SET_EMPTY_DOCUMENT_ATTRIBUTE,
      'setActiveDocumentsAttribute', 'setAttributesTypeFilter']),
    ...mapActions('attributes', ['getDocumentsAttribute', 'getActiveDocumentsAttributeById', 'setFilterString', 'getDictionaries']),
  },
})
export default class DocumentAttributesList extends Vue {
  protected actualDocumentAttributesList!: DocumentsAttribute[];
  protected documentAttributesList!: DocumentsAttribute[];
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private setAttributesTypeFilter!: (filter: AttributesTypeFilter | null) => void;
  private getDocumentsAttribute!: (params?: QueryParams) => DocumentsAttribute[];
  private typeFilter!: AttributesTypeFilter | null;
  private self: any;
  private isNewAttributeCreate = false;
  private showDeleted = false;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get userTableColumns() {
    return [{ key: 'title', title: 'Атрибут', width: 40 },
    { key: 'code', title: 'Код', width: 15 },
    { key: 'attributeType', title: 'Тип, умолчание', width: 30 },
    { key: 'attribute', title: '', width: 15, component: 'elib-document-attributes-features' }];
  }

  private get usersTableData() {
    return this.documentAttributesList.map((attribute) => {
        const deletedEntityStyle = {backgroundColor: 'rgba(40, 36, 35, 0.07)'} as CSSStyleDeclaration;
        const tdStyle = !!attribute.deleted ? { ...deletedEntityStyle } : undefined;
        const componentStyle = !!attribute.deleted ? { textDecoration: 'line-through' } : undefined;
        return {
          title: attribute.title,
          attribute,
          attributeType: this.getTypeDefaultValueField(attribute),
          code: attribute.code,
          group: attribute.group || '',
          tdStyle,
          componentStyle,
        };
      });
  }

  private get stringFilter() {
    return this.self.filterString;
  }

  private set stringFilter(query: string) {
    this.self.setFilterString(query);
    this.self.debounceSearch({ offset: 0, query});
  }

  private get debounceSearch() {
    return debounce(async (params: QueryParams) => {
      await this.getDocumentsAttribute({...params});
    }, 1000);
  }

  private getTypeDefaultValueField(attribute: DocumentsAttribute) {
    const typeTitle = attribute.attributeType ? attribute.attributeType.title : '';
    const defaulValue = attribute.defaultValue ? this.dictonoryValueById(attribute) : '';
    return [typeTitle, defaulValue];
  }

  private dictonoryValueById(attribute: DocumentsAttribute) {
    if (attribute.dictionary) {
      const dictionaryItemId = parseInt(attribute.defaultValue as string, 10);
      const dictionaryItem = attribute.dictionary.dictionaryValues.find((v) => v.id === dictionaryItemId);
      return dictionaryItem ? dictionaryItem.title : 'Error of getting default values';
    }
    return attribute.defaultValue || '';
  }

  private beforeCreate() {
    this.self = this;
  }

  private created() {
    this.onComponentEnter();
  }

  private mounted() {
    ((this.$refs.list as Vue).$el as HTMLElement).addEventListener('scroll', this.onScroll);
    (this.$el as HTMLElement).addEventListener('scroll', this.onScroll);
  }

  private activated() {
    this.onComponentEnter();
  }

  private beforeDestroy() {
    ((this.$refs.list as Vue).$el as HTMLElement).removeEventListener('scroll', this.onScroll);
    (this.$el as HTMLElement).removeEventListener('scroll', this.onScroll);
  }

  private onComponentEnter() {
    this.getDocumentsAttribute();
    this.self.getDictionaries();
  }

  private onScroll() {
    const elm = this.$el as HTMLElement;
    if (elm.clientHeight >= (elm.scrollHeight - elm.scrollTop)) {
      this.getDocumentsAttribute({ withDeleted: this.showDeleted });
    }
  }

  private onRowClick(row: { attribute: DocumentsAttribute }) {
    if (!this.self.hasAuthorityByCode(3)) {
      this.$notifier({
        title: 'Недостаточно прав!', message: 'У вас нет прав для редактирования аттрибутов',
        type: 'error',
      });
      return;
    }
    if (row.attribute && row.attribute.id) {
      this.self.getActiveDocumentsAttributeById(row.attribute.id);
    }
  }

  private createNewAttribute() {
    if (!this.self.hasAuthorityByCode(3)) {
      this.$notifier({
        title: 'Недостаточно прав!', message: 'У вас нет прав для редактирования аттрибутов',
        type: 'error',
      });
      return;
    }
    this.self[types.SET_EMPTY_DOCUMENT_ATTRIBUTE]();
    this.isNewAttributeCreate = true;
  }

  private onClickOutside() {
    if (this.self.activeDocumentsAttribute && !this.isNewAttributeCreate) {
      this.self.setActiveDocumentsAttribute(undefined);
    }
    this.isNewAttributeCreate = false;
  }

  private onShowDeleted(check: boolean) {
    this.showDeleted = check;
    this.self[types.SET_DOCUMENT_ATTRIBUTES_LIST]([]);
    this.getDocumentsAttribute({ withDeleted: this.showDeleted });
  }

  private applyAttributesTypeFilter(filter: AttributesTypeFilter | null) {
    this.setAttributesTypeFilter(filter);
    this.getDocumentsAttribute({ withDeleted: this.showDeleted, offset: 0 });
  }
}

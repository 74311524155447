import { Module } from 'vuex';
import { User, getEmptyUser } from '@/models/users.model';
import {
  fetchUsers,
  fetchHdfsUsers,
  fetchUserById,
  updateExistingUser,
} from '@/controllers/users.controllers';
import { QueryParams } from '@/commons/axios.config';
import types from '../types';

type StringFilter = string | undefined;
interface ModuleState {
  usersList: User[];
  hdfsUsersList: User[];
  offset: number;
  filterString: StringFilter;
  activeUser: User | undefined;
}

export const module: Module<ModuleState, any> = {
  namespaced: true,
  state: {
    usersList: [],
    hdfsUsersList: [],
    offset: 0,
    filterString: undefined,
    activeUser: undefined,
  },
  mutations: {
    setUsersList(state: ModuleState, payload: User[]) {
      const rawArray = [...state.usersList, ...payload];
      const ids = [...new Set(rawArray.map((v) => v.id))];
      state.usersList = ids.map((id) => rawArray.find((user) => user.id === id)) as User[];
      state.usersList = state.usersList.sort((a, b) => a.fullName > b.fullName ? 1 : -1);
    },
    setHdfsUsersList(state: ModuleState, payload: User[]) {
      state.hdfsUsersList = payload;
    },
    setActiveUser(state: ModuleState, payload: User) {
      state.activeUser = payload;
    },
    setEmptyActiveUser(state: ModuleState) {
      state.activeUser = getEmptyUser();
    },
    filterString(state: ModuleState, stringFilter: StringFilter) {
      state.filterString = stringFilter;
      state.usersList = [];
      state.offset = state.usersList.length + 1;
    },
    updateActiveRoleProperty(state: ModuleState, payload: { prop: string; value: any }) {
      if (state.activeUser) {
        (state.activeUser as { [key: string]: any })[payload.prop] =
          payload.value;
      }
    },
    updateUsers(state: ModuleState, payload: User) {
      const index = state.usersList.findIndex((v) => v.id === payload.id);
      if (index > -1) {
        state.usersList[index] = payload;
        state.usersList = [...state.usersList];
      }
    },
  },
  getters: {
    filteredUsers({ usersList, filterString }) {
      return getFilteredUsers(usersList, filterString);
    },
    [types.FILTERED_USERS_LIST]({usersList}) {
      return (filterString: string) => getFilteredUsers(usersList, filterString);
    },
  },
  actions: {
    async [types.FETCH_USERS_ACTION]({ commit, rootState, state }, params?: QueryParams) {
      const query = {...{offset: state.usersList.length, query: state.filterString}, ...params};
      const users = await fetchUsers(query as QueryParams) || [];
      commit('setUsersList', users);
      return users;
    },
    async getHdfsUsers({ commit, rootState, state }, params?: QueryParams) {
      const query = {...{offset: 0, limit: 2000, query: state.filterString}, ...params};
      const users = await fetchHdfsUsers(rootState.token || '', query as QueryParams);
      if (users) {
        commit('setHdfsUsersList', users);
      }
    },
    async setFilterString({ commit }, stringFilter: string) {
      commit('filterString', stringFilter);
    },
    async getActiveUserById({ commit, rootState }, payload: number) {
      const user = await fetchUserById(payload);
      if (user) {
        commit('setActiveUser', user);
      }
    },
    async updateUser({ commit, rootState }, payload: User) {
      const user = await updateExistingUser(rootState.token, payload);
      if (user) {
        commit('updateUsers', user);
      }
    },
  },
};

function getFilteredUsers(usersList: User[], filter: StringFilter) {
  return usersList.filter((v) => {
    const filteredResult = filter ? RegExp(`^.*?(${filter})`, 'gi').test(v.fullName || v.email || v.login)
      : true;
    return filteredResult;
  });
}

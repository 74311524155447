








import { Vue, Prop, Component } from 'vue-property-decorator';

const baseClassName = 'elib-spinner-default';

@Component({
  name: baseClassName,
})
export default class SpinnerDefault extends Vue {
  @Prop({ default: () => 'Загрузка' }) private title!: string;
}

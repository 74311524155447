import { Document, DocumentsDeletedInfo } from '@/models/user-documents.model';
import { $HTTPServicesClient as axiosClient, getUrlString, QueryParams } from '@/commons/axios.config';
import { AUTH_TOKEN_HEADER, AUTH_TOKEN_NAME } from '@/commons/constants';
import { Store } from '@/models/commons';

export async function fetchUserDocuments(params: QueryParams) {
  let documents;
  try {
    const request = await axiosClient.get('/documents',
    {
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
      params,
    });
    documents = (request.data as { data: Document[] }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return documents;
}
export async function fetchUserDocumentsReport(params: QueryParams) {
  let documents;
  try {
    const request = await axiosClient.get('documents/report',
      {
        headers: {
          [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
        },
        params,
    });
    documents = (request.data as { data: Document[] }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return documents;
}
export async function postUserDocumentsReport(ids: any) {
  let documents;
  try {
    // const url = getUrlString('documents/report', '');
    const request = await axiosClient.post('/documents/report', ids, {
      headers: {
        'x-auth-token': localStorage.getItem('elib-token'),
      },
    });
    documents = (request.data as { data: Document[] });
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return documents;
}

export async function postNewUserDocument(data: Document) {
  let response;
  try {
    const request = await axiosClient.post(`/documents/multipart`, userDocumentsToFormData(data), {
      headers: {
        'x-auth-token': localStorage.getItem('elib-token'),
      },
    });
    response = (request.data as { data: Document }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return response;
}

export async function getDocumentVersionsCount(documentId: number) {
  try {
    const response = await axiosClient.get(`/documents/versions-count/${documentId}`, {
      headers: { [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME) },
    });
    return response.data.data as number;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}

export async function putUserDocument(data: Document) {
  let response;
  try {
    const request = await axiosClient.put(`/documents/multipart/${data.documentId}`, userDocumentsToFormData(data), {
      headers: { [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME) },
    });
    response = (request.data as { data: Document }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return response;
}

export async function fetchUserDocumentById(documentsId: number) {
  let response;
  try {
    const request = await axiosClient.get(`/documents/${documentsId}/metadata`, {
      params: { UIRequest: true },
      headers: { [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME) },
    });
    response = request.data.data as Document;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return response;
}

export async function fetchUserDocumentsVersionById(props: { documentsId: number, version: number, token: string }) {
  let response;
  try {
    const request = await axiosClient.get(`/documents/${props.documentsId}/metadata?versionNumber=${props.version}`, {
      params: { UIRequest: true },
      headers: { [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME) },
    });
    response = (request.data as { data: Document }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return response;
}

export async function getParentDocumentById(token: string, documentsId: number) {
  let response;
  try {
    const request = await axiosClient.get(`/documents/parents?childId${documentsId}`);
    response = (request.data as { data: Document[] }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return response;
}

export async function getChildDocumentById(token: string, documentsId: number) {
  let response;
  try {
    const request = await axiosClient.get(`/documents/parents?childId${documentsId}`, {
      headers: {
        'x-auth-token': token,
      },
    });
    response = (request.data as { data: Document[] }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return response;
}

export async function putDeletedDocument(token: string, data: DocumentsDeletedInfo) {
  try {
    const request = await axiosClient.put(`/documents/${data.documentId}/forDelete`, data, {
      headers: {
        'x-auth-token': token,
      },
    });
    return true;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
    return false;
  }
}

export async function deletePhysicallyDocument(token: string, doc: Document) {
  try {
    await axiosClient.delete(`/documents/${doc.documentId}`, {
      headers: {
        'x-auth-token': token,
      },
    });
    return true;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
    return false;
  }
}

export async function deletePhysicallyDocumentVersion(token: string, doc: Document) {
  try {
    await axiosClient.delete(`/documents/version/${doc.documentId}/${doc.currentVersionNumber}`, {
      headers: {
        'x-auth-token': token,
      },
    });
    return true;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
    return false;
  }
}

export async function fetchParentDocuments(token: string, documentsId: number) {
  let documents;
  try {
    const request = await axiosClient.get(`/documents/parents?childId=${documentsId}`, {
      headers: {
        'x-auth-token': token,
      },
    });
    documents = (request.data as { data: Document[] }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return documents;
}

export async function fetchChildDocuments(token: string, documentsId: number) {
  let documents;
  try {
    const request = await axiosClient.get(`/documents/children?parentId=${documentsId}`, {
      headers: {
        'x-auth-token': token,
      },
    });
    documents = (request.data as { data: Document[] }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return documents;
}

export async function fetchDocumentVersions(documentsId: number) {
  try {
    const request = await axiosClient.get(`/documents/${documentsId}/versions`, {
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
    });
    return (request.data as { data: Document[] }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}

export async function fetchDocumentsContent(documentsId: number, fileName: string, versionNumber?: string | number) {
  let content;
  try {
    const request = await axiosClient
      .get(`/documents/${documentsId}/content`, {
        params: {
          fileName: encodeURIComponent(fileName),
          versionNumber,
        },
        headers: {
          'x-auth-token': localStorage.getItem('elib-token'),
        },
        responseType: 'blob',
      });
    content = request.data as Blob;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return content;
}

export async function fetchDocumentsByPredefinedFilters(filtersName: string, params: QueryParams, token = '') {
  let documents;
  try {
    const url = getUrlString(`documents/${filtersName}`, params);
    const request = await axiosClient.get(url, {
      headers: {
        'x-auth-token': token || localStorage.getItem('elib-token'),
      },
    });
    documents = (request.data as { data: Document[] }).data;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
  return documents;
}

export async function fetchChangeDocumentsCategory(documentId: number, category: string, token = '') {
  try {
    await axiosClient.get(`/documents/changeMark/${documentId}?documentsCategory=${category}`, {
      headers: {
        'x-auth-token': token || localStorage.getItem('elib-token'),
      },
    });
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}

export async function fetchChangeDocumentsFavorites(documentId: number, favor: boolean, token = '') {
  try {
    await axiosClient.get(`/documents/changeFavor/${documentId}?favor=${favor}`, {
      headers: {
        'x-auth-token': token || localStorage.getItem('elib-token'),
      },
    });
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}

export async function fetchDocumentsWithTemporaryFilter(params: Store) {
  try {
    const request = await axiosClient.post(`/documents/applyFilter`, params, {
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
    });
    return request.data.data as Document[];
  } catch (error) {
    return [];
  }
}
export async function fetchDocumentsWithTemporaryFilterReport(params: Store) {
  try {
    const request = await axiosClient.post(`/documents/report/applyFilter`, params, {
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
    });
    return request.data.data as Document[];
  } catch (error) {
    return [];
  }
}

function isValidTemporaryFilter(params: { [key: string]: any }) {
  return params && params.documentFilterDTO &&
    params.documentFilterDTO.specification &&
    params.documentFilterDTO.specification.length;
}

function userDocumentsToFormData(document: Document) {
  const fromData = new FormData();
  const attachments = document.attachments || [];
  for (const attachment of attachments) {
    fromData.append('attachments', attachment);
  }
  if (!!document.attachments) {
    delete document.attachments;
  }
  fromData.append('properties', JSON.stringify(document));
  return fromData;
}

export async function fetchUserDocumentsByTypeId(typeId: number, params: QueryParams) {
  try {
    const request = await axiosClient.get(`/documents/${typeId}/hierarchy`, {
      params,
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
    });
    return request.data.data as Document[];
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}

export async function fetchUserDocumentsByINN(params: QueryParams) {
  try {
    // tslint:disable-next-line
    const dictionarySearchCodes = ['name_of_the_legal_entity', 'company_group',
    'abbreviated_name_of_the_document', 'title_of_the_document'].join(',');
    const request = await axiosClient.get(`/documents/on-dictionary-value?codes=${encodeURIComponent(dictionarySearchCodes)}`, {
      params,
      headers: {
        [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
      },
    });
    return request.data as Document[];
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }
}













import { Vue, Component, Prop } from 'vue-property-decorator';
import { User } from '@/models/users.model';

export const baseClassName = 'elib-profile-employee-info';

@Component({
  name: baseClassName,
})
export default class ProfileEmployeeInfo extends Vue {
    @Prop({ default: () => [] }) private incomingClasses!: string[];
    @Prop() private value!: User;
    private baseClassName = baseClassName;

    private get className() {
        return (this.incomingClasses || []).
            reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
    }
}

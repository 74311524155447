







import { Component, Vue } from 'vue-property-decorator';

const baseClassName = 'elib-home-page';

@Component({
  name: baseClassName,
})
export default class Home extends Vue {
   private baseClassName = baseClassName;

   private created() {
     if (this.$route.path === '/') {
       this.$router.push({name: 'documents'});
     }
   }
}

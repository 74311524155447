import { LegalEntityId } from './../models/user-documents.model';
import { FilterViewType } from '@/models/filters.model';
import { attributeTypes, DocumentsAttribute, DocumentsAttributeOriginal } from '@/models/document-attributes.model';
import { QueryParams } from '@/commons/axios.config';
import { Store } from '@/models/commons';
import { queryDocumentTypes } from '@/controllers/catalogues.controllers';
import { fetchAdditionalDictionaryAttributeValuesById } from '@/controllers/dictionaries.controller';
import { DocumentClassification, DocumentsCategory, DocumentsStatus } from '@/models/user-documents.model';
import { fetchUserById, fetchUsers } from '@/controllers/users.controllers';

export interface FilterDictionary {
    [key: string]: {
        order: number;
        view?: FilterViewType;
        static?: boolean;
        limit?: number;
        dependsOn?: string;
        searchField?: string;
        searchFunction?: (...args: any) => any;
        searchFunctionUseCondition?: (...args: any) => any;
        searchParamsBuilder?: (...args: any) => any;
        getter?: (...args: any) => any;
        setter?: (...args: any) => any;
        mapper?: (...args: any) => any;
        initializer?: (...args: any) => any;
        externalField?: string;
        externalId?: number;
        externalTable?: string;
    };
}

export const filters: FilterDictionary = {
    company_group: {
        order: 1,
        limit: 20,
    },
    name_of_the_legal_entity: {
        dependsOn: 'company_group',
        order: 2,
        limit: 20,
    },
    inn: {
        order: 3,
        view: FilterViewType.AUTOCOMPLETE,
        dependsOn: 'name_of_the_legal_entity',
        searchField: 'description',
        searchFunction: fetchAdditionalDictionaryAttributeValuesById,
        searchFunctionUseCondition: (ctx: Store) => {
            return !!ctx && !!ctx.masterValueDictionaryId &&
            ![null, undefined].includes(ctx.masterFilterDictionaryValueId);
        },
        searchParamsBuilder: (ctx: Store, params: Store) => {
            return  [
                ctx.dictionaryId,
                'description',
                {...params, masterDictionaryValueId: ctx.masterFilterDictionaryValueId},
            ];
        },
        externalField: 'ID',
        externalTable: 'DICTIONARY',
        getter: async (params: QueryParams) => {
            return (await fetchAdditionalDictionaryAttributeValuesById(LegalEntityId, 'description', params))
                .slice(0, 3);
        },
        setter: (attribute: DocumentsAttribute, values: number[]) => {
            return values.map((value) => ({
                externalField: 'ID',
                externalId: value as number,
                externalTable: 'DICTIONARY',
            }));
        },
    },
    dossier_section: {
        order: 4,
        static: true,
        limit: 20,
    },
    document_format: {
        order: 5,
        view: FilterViewType.AUTOCOMPLETE,
    },
    dossier_kind: {
        order: 6,
        view: FilterViewType.CHECKBOX,
    },
    document_type: {
        order: 7,
        view: FilterViewType.TYPE,
        externalField: 'ID',
        externalTable: 'DOCUMENT_TYPE',
        getter: async (params: QueryParams) => {
            return (await queryDocumentTypes(params)).slice(0, 3);
        },
        setter: (attribute: DocumentsAttribute, values: number[]) => {
            return values.map((value) => ({
                externalField: 'ID',
                externalId: value as number,
                externalTable: 'DOCUMENT_TYPE',
            }));
        },
    },
    short_title: {
        order: 8,
        limit: 20,
    },
    full_title: {
        order: 9,
        limit: 20,
    },
    document_classification: {
        order: 10,
        view: FilterViewType.CHECKBOX,
        static: true,
        externalField: 'CLASSIFICATION',
        externalTable: 'DOCUMENT_VERSION',
        getter: () => {
            return Object.keys(DocumentClassification)
                .map((key, index) => {
                    return {
                        id: index,
                        title: (DocumentClassification as Store)[key],
                        value: (DocumentClassification as Store)[key],
                    };
                });
        },
        setter: (attribute: DocumentsAttribute, values: number[]) => {
            return values.map((value) => ({
                externalField: 'CLASSIFICATION',
                externalId: value,
                externalTable: 'DOCUMENT_VERSION',
            }));
        },
    },
    document_watcher: {
        order: 11,
        view: FilterViewType.AUTOCOMPLETE,
        limit: 3,
        externalField: 'USER_ID',
        externalTable: 'DOCUMENT_WATCHERS',
        searchField: 'fullName',
        getter: async (param: QueryParams) => await fetchUsers(param),
        initializer: async (id: number) => await fetchUserById(id),
        setter: (attribute: DocumentsAttribute, values: number[]) => {
            return values.map((value) => ({
                externalField: 'USER_ID',
                externalId: value as number,
                externalTable: 'DOCUMENT_WATCHERS',
            }));
        },
    },
    document_category: {
        order: 12,
        view: FilterViewType.COLORS,
        static: true,
        externalField: 'DOCUMENT_CATEGORY',
        externalTable: 'DOCUMENT_VERSION',
        getter: () => {
            return Object.keys(DocumentsCategory)
                .map((key, index) => {
                    return {
                        id: index,
                        value: false,
                        color: (DocumentsCategory as Store)[key],
                    };
                });
        },
        setter: (attribute: DocumentsAttribute, values: string[]) => {
            return values.map((value) => ({
                externalField: 'DOCUMENT_CATEGORY',
                externalId: Object.values(DocumentsCategory).findIndex((v) => v === value),
                externalTable: 'DOCUMENT_VERSION',
            }));
        },
        mapper: (values: number[]) => {
            return values && values.length && values[0] ?
                [Object.values(DocumentsCategory)[values[0]]] : values;
        },
    },
    document_number: {
        order: 13,
        externalField: 'DOCUMENT_NUMBER',
        externalTable: 'DOCUMENT_VERSION',
        setter: (attribute: DocumentsAttribute, stringValue: string) => {
            return {
                externalField: 'DOCUMENT_NUMBER',
                stringValue,
                externalTable: 'DOCUMENT_VERSION',
            };
        },
    },
    document_kind: {
        order: 15,
        view: FilterViewType.CHECKBOX,
    },
    document_status: {
        order: 16,
        static: true,
        limit: 3,
        view: FilterViewType.CHECKBOX,
        externalField: 'STATUS',
        externalTable: 'DOCUMENT_VERSION',
        getter: () => {
            return Object.keys(DocumentsStatus)
                .map((key, index) => {
                    return {
                        id: index,
                        title: (DocumentsStatus as Store)[key],
                        value: (DocumentsStatus as Store)[key],
                    };
                });
        },
        setter: (attribute: DocumentsAttribute, values: number[]) => {
            return values.map((value) => ({
                externalField: 'STATUS',
                externalId: value,
                externalTable: 'DOCUMENT_VERSION',
            }));
        },
    },
    notifications: {
        order: 17,
        view: FilterViewType.CHECKBOX,
    },
    document_period_year: {
        order: 18,
        limit: 20,
    },
    document_period: {
        order: 19,
        static: true,
        limit: 20,
    },
    document_ownership: {
        order: 20,
        view: FilterViewType.CHECKBOX,
    },

    document_date: {
        order: 21,
        view: FilterViewType.DATE,
        externalField: 'DOCUMENTS_DATE',
        externalTable: 'DOCUMENT_VERSION',
        setter: (attribute: DocumentsAttribute, dateValue: number) => {
            return {
                externalField: 'DOCUMENTS_DATE',
                dateValue,
                externalTable: 'DOCUMENT_VERSION',
            };
        },
    },
    document_validity: {
        order: 22,
        view: FilterViewType.DATE,
        externalField: 'STORE_TILL',
        externalTable: 'DOCUMENT_VERSION',
        setter: (attribute: DocumentsAttribute, dateValue: number) => {
            return {
                externalField: 'STORE_TILL',
                dateValue,
                externalTable: 'DOCUMENT_VERSION',
            };
        },
    },
    verification_completed: {
        order: 23,
        externalField: 'IS_VALID',
        externalTable: 'DOCUMENT_VERSION',
        setter: (attribute: DocumentsAttribute, booleanValue: boolean) => {
            return {
                externalField: 'IS_VALID',
                booleanValue,
                externalTable: 'DOCUMENT_VERSION',
            };
        },
    },
    document_storage_date: {
        order: 24,
    },
    document_card_date_create: {
        order: 25,
        view: FilterViewType.DATE,
        externalField: 'CREATED',
        externalTable: 'DOCUMENT_VERSION',
        setter: (attribute: DocumentsAttribute, dateValue: number) => {
            return {
                externalField: 'CREATED',
                dateValue,
                externalTable: 'DOCUMENT_VERSION',
            };
        },
    },
    document_id: {
        order: 27,
        externalField: 'DOCUMENT_ID',
        externalTable: 'DOCUMENT_VERSION',
        setter: (attribute: DocumentsAttribute, stringValue: number) => {
            return {
                externalField: 'DOCUMENT_ID',
                stringValue,
                externalTable: 'DOCUMENT_VERSION',
            };
        },
    },
    document_card_date_create_legacy: {
        order: 29,
        view: FilterViewType.DATE,
    },
    document_card_date_update: {
        order: 28,
        externalField: 'UPDATED',
        externalTable: 'DOCUMENT_VERSION',
        setter: (attribute: DocumentsAttribute, dateValue: number) => {
            return {
                externalField: 'UPDATED',
                dateValue,
                externalTable: 'DOCUMENT_VERSION',
            };
        },
    },
    document_card_date_delete: {
        order: 29,
        view: FilterViewType.DATE,
        externalField: 'DELETED',
        externalTable: 'DOCUMENT_VERSION',
        setter: (attribute: DocumentsAttribute, dateValue: number) => {
            return {
                externalField: 'DELETED',
                dateValue,
                externalTable: 'DOCUMENT_VERSION',
            };
        },
    },
    document_deleted_author: {
        order: 31,
        view: FilterViewType.AUTOCOMPLETE,
        limit: 3,
        externalField: 'AUTHOR',
        externalTable: 'DOCUMENT_VERSION',
        searchField: 'fullName',
        getter: async (params: QueryParams) => await fetchUsers(params),
        initializer: async (id: number) => await fetchUserById(id),
        setter: (attribute: DocumentsAttribute,  values: number[]) => {
            return values.map((value) => ({
                externalField: 'AUTHOR',
                externalId: value as number,
                externalTable: 'DOCUMENT_VERSION',
            }));
        },
    },
    document_parentId: {
        order: 32,
        externalField: 'DOCUMENT_PARENT_ID',
        externalTable: 'DOCUMENT_RELATION',
        setter: (attribute: DocumentsAttribute, externalId: number) => {
            return {
                externalField: 'DOCUMENT_PARENT_ID',
                externalId,
                externalTable: 'DOCUMENT_RELATION',
            };
        },
    },
    document_update_author: {
        order: 33,
        view: FilterViewType.AUTOCOMPLETE,
        limit: 3,
        externalField: 'AUTHOR_ID',
        externalTable: 'DOCUMENT_VERSION',
        searchField: 'fullName',
        getter: async (params: QueryParams) => await fetchUsers(params),
        initializer: async (id: number) => await fetchUserById(id),
        setter: (attribute: DocumentsAttribute, values: number[]) => {
            return values.map((value) => ({
                externalField: 'AUTHOR_ID',
                externalId: value as number,
                externalTable: 'DOCUMENT_VERSION',
            }));
        },
    },

};

export const staticAttributes: DocumentsAttributeOriginal[] = [
    {
        attributeType: attributeTypes[4],
        authorId: 0,
        code: 'document_type',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'Место хранения в каталоге',
        showInRoles: true,
    },
    {
        attributeType: attributeTypes[4],
        authorId: 0,
        code: 'document_classification',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'Классификация',
        showInRoles: true,
    },
    {
        attributeType: attributeTypes[4],
        authorId: 0,
        code: 'document_watcher',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'Наблюдатель',
        showInRoles: false,
    },
    {
        attributeType: attributeTypes[4],
        authorId: 0,
        code: 'document_status',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'Статус',
        showInRoles: true,
    },
    {
        attributeType: attributeTypes[4],
        authorId: 0,
        code: 'document_category',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'Метка',
        showInRoles: false,
    },
    {
        attributeType: attributeTypes[1],
        authorId: 0,
        code: 'document_validity',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'Срок действия документа',
        showInRoles: true,
    },
    {
        attributeType: attributeTypes[4],
        authorId: 0,
        code: 'inn',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'ИНН',
        showInRoles: false,
    },
    {
        attributeType: attributeTypes[3],
        authorId: 0,
        code: 'document_id',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'ID документа',
        showInRoles: true,
    },
    {
        attributeType: attributeTypes[1],
        authorId: 0,
        code: 'document_card_date_update',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'Дата изменения документа',
        showInRoles: false,
    },
    {
        attributeType: attributeTypes[4],
        authorId: 0,
        code: 'document_update_author',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'Автор изменения документа',
        showInRoles: false,
    },
    {
        attributeType: attributeTypes[1],
        authorId: 0,
        code: 'document_card_date_delete',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'Дата удаления',
        showInRoles: false,
    },
    {
        attributeType: attributeTypes[4],
        authorId: 0,
        code: 'document_deleted_author',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'Кто удалил',
        showInRoles: false,
    },
    {
        attributeType: attributeTypes[0],
        authorId: 0,
        code: 'document_parentId',
        defaultValue: null,
        deleted: false,
        documentDuplicatesControl: false,
        group: '',
        guid: '',
        id: 0,
        required: false,
        searchable: true,
        title: 'ID документа родителя',
        showInRoles: false,
    },
];

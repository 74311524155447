import { Store } from '@/models/commons';
import { Document, DocumentCustomAttribute } from '@/models/user-documents.model';

export default function getDocumentHashSum(document?: Document) {
    if (!document) {
        return '';
    }

    return ''.concat(
        getTrivialFieldHash(document),
        getDocumentAttributesHash(document.attributes),
        getDocumentContentsHash(document),
        document.documentShortName?.value || '',
        document.documentFullName?.value || '',
        document.documentClassification || '',
        document.companyGroup?.value || '',
        document.dossier?.value || '',
        document.legalEntity?.value || '',
        getDocumentWatchersHash(document),
    );
}

function getTrivialFieldHash(document: Store) {
    return Object.keys(document)
        .sort()
        .filter((key) => !!document[key] && ['string', 'number', 'boolean'].includes(typeof document[key]))
        .reduce((acc, key) => acc.concat(key, document[key]), '');
}

function getDocumentContentsHash(document: Document) {
    return document.contents?.map((v) => v).sort((a, b) => a > b ? 1 : -1)
        .reduce((acc, value) => acc.concat(value.fileName, value.size.toString()), '') || '';
}

function getDocumentWatchersHash(document: Document) {
    return document.watchers?.map((v) => v)
        .filter((v) => !!v)
        .sort((a, b) => a.id >= b.id ? 1 : -1)
        .reduce((acc, value) => acc.concat(value.id.toString()), '') || '';
}

function getDocumentAttributesHash(attributes: DocumentCustomAttribute[]) {
    return attributes
        .map((v) => v)
        .sort((a, b) => a.code >= b.code ? 1 : -1)
        .filter((attrib) => !!attrib.value)
        .reduce((acc, attrib) => acc.concat(attrib.code, attrib.value), '');
}

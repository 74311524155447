import { $app } from '@/main';
import { User } from '@/models/users.model';
import { $HTTPServicesClient as axiosClient } from '@/commons/axios.config';
import { AUTH_TOKEN_HEADER, AUTH_TOKEN_NAME } from '@/commons/constants';

interface AuthResponse {
    data: User;
}

export async function authenticateToApplication(authData: { login: string, password: string }) {
    try {
        const dataString = `${authData.login}:${authData.password}`;
        const authString = getBase64String(dataString);
        const request = await axiosClient.get('/authenticate/', {
            headers: {
                Authorization: `Basic ${authString}`,
            },
        });
        return {user: request.data.data, token: request.headers[AUTH_TOKEN_HEADER]};
    } catch (error) {
        return;
    }
}


export async function getUserSessionIsAlive() {
    try {
        const response = await axiosClient.get('/authenticate/ping-session', {
            headers: {
                [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
            },
        });
        return response.data;
    } catch (error) {
        return false;
    }
}

export async function fetchRestoreSession(): Promise<User | undefined> {
    try {
        const request = await axiosClient.get('/authenticate/', {
            headers: {
                [AUTH_TOKEN_HEADER]: localStorage.getItem(AUTH_TOKEN_NAME),
            },
        });
        return (request.data as AuthResponse).data as User;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
        return;
    }
}

function getBase64String(text: string): string {
    return window.btoa(getUnescapedString(text));
}

function getUnescapedString(text: string) {
    return unescape(encodeURIComponent(text));
}

export async function getServerVersion() {
    let version;
    try {
        const response = await axiosClient.get('/version');
        version = response.data as string;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
    }
    return version;
}

export async function sessionLogout() {
    try {
        await axiosClient.delete('/authenticate/', {
            headers: {
                'x-auth-token': localStorage.getItem('elib-token'),
            },
        });
        return true;
    } catch (error) {
        // tslint:disable-next-line: no-console
        console.error(error);
        return false;
    }
}

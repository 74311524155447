































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import types from '@/store/types';
import moment from 'moment';
import { Role, roleChapters, systemRolesChapter, RolesAuthority, roleAuthorities } from '@/models/roles.model';
import RolesForm from '@/components/Administration/Roles/RolesForm.vue';

const baseClassName = 'elib-roles-list';

@Component({
  name: baseClassName,
  components: {RolesForm},
  computed: {
    ...mapState('roles', ['rolesList', 'activeRole', 'filterString']),
    ...mapGetters('session', ['hasAuthorityByCode']),

  },
  methods: {
    ...mapActions('roles', ['getRoles', 'getActiveRoleById', 'setFilterString']),
    ...mapMutations('roles', [types.SET_ROLES_LIST, types.SET_EMPTY_ACTIVE_ROLE, 'setActiveRole']),
  },
})
export default class RolesList extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private self: any;
  private isNewRoleCreation = false;
  private showDeleted = false;
  private stringFilter = '';

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get userTableColumns() {
    return [
      { key: 'title', title: 'Роль', width: 20 },
      { key: 'permissions', title: 'Права', width: 40 },
      { key: 'attributes', title: 'Атрибуты', width: 40 },
    ];
  }

  private get usersTableData() {
    return (this.self.rolesList as Role[]).
      map((role) => {
        const deletedEntityStyle = {
          backgroundColor: '#f1f1f1',
          textDecoration: 'line-through',
        } as CSSStyleDeclaration;
        const trStyle = role.deleted ? { ...deletedEntityStyle } : {};
        const tdStyle = role.deleted ? { ...deletedEntityStyle } : {};
        const componentStyle = role.deleted ? { ...deletedEntityStyle } : {};
        return {
          title: role.title,
          role,
          permissions: this.mapRolesPermissionPreview(role),
          attributes: this.mapRolesDocAttributesPreview(role),
          trStyle,
          tdStyle,
          componentStyle,
        };
      });
  }

  private mapRolesPermissionPreview(role: Role) {
    const authorities = role.roleAuthorities.reduce((acc, v) => [...acc, ...[v]], [] as RolesAuthority[]);
    const ids = authorities.map((v) => v.id);
    const chapter = (roleChapters as Array<[string, number[]]>).
      map((value) => {
        return this.authoritiesChapterString(value, ids, role);
      }).
      filter((v) => v !== '');
    const extendedString = `${this.authoritiesChapterString(systemRolesChapter, ids, role)}`;
    chapter.push(extendedString);
    return chapter;
  }

  private mapRolesDocAttributesPreview(role: Role) {
    return (role.attributes || [])
    .filter((item) => !!item)
    .map((item) => {
      let value = item.value;
      if (item.attributeDefinition.attributeType.code === 'DATE') {
        value = moment(parseInt(item.value as string, 10)).format('DD.MM.yyyy');
      }
      if (item.attributeDefinition.attributeType.code === 'BOOLEAN') {
        value = item.value === 'true' ? 'Да' : 'Нет';
      }
      if (item.attributeDefinition.attributeType.code === 'DICTIONARY') {
        const dicValue = item.attributeDefinition.dictionary?.dictionaryValues
          .find((v) => v.id === parseInt(item.value as string, 10));
        value = !!dicValue ? dicValue.title : item.value;
      }
      return `${item.attributeDefinition.title.toUpperCase()}: ${value}`;
    });
  }

  private authoritiesChapterString(rolesChapter: [string, number[]], authorityIds: number[], role: Role) {
    const title = this.createAuthoritiesTitle(rolesChapter, role);
    const ids = rolesChapter[1].filter((id) => authorityIds.includes(id));
    const authorityTitles = roleAuthorities.filter((v) => ids.includes(v.id)).map((v) => v.title);
    return authorityTitles.length > 0 ? title + authorityTitles.join(', ') : '';
  }

  private createAuthoritiesTitle(rolesChapter: [string, number[]], role: Role) {
    if (role.outerSystem && !!role.outerSystem && rolesChapter[0] === 'Система') {
      return `${role.outerSystem.name}: `;
    }
    return `${rolesChapter[0]}: `.toUpperCase();
  }

  private beforeCreate() {
    this.self = this;
  }

  private created() {
    this.onComponentEnter();
  }

  private activated() {
    this.onComponentEnter();
  }

  private mounted() {
    ((this.$refs.list as Vue).$el as HTMLElement).addEventListener('scroll', this.onScroll);
    (this.$el as HTMLElement).addEventListener('scroll', this.onScroll);
  }

  private beforeDestroy() {
    ((this.$refs.list as Vue).$el as HTMLElement).removeEventListener('scroll', this.onScroll);
    (this.$el as HTMLElement).removeEventListener('scroll', this.onScroll);
  }

  private onComponentEnter() {
    this.self.getRoles({ withDeleted: this.showDeleted });
  }

  private onRowClick(row: { role: Role }) {
    if (!this.self.hasAuthorityByCode(37)) {
      this.$notifier({
        title: 'Недостаточно прав!', message: 'У вас нет прав для редактирования ролей',
        type: 'error',
      });
      return;
    }
    if (row.role && row.role.id) {
      this.self.getActiveRoleById(row.role.id);
    }
  }

  @Watch('stringFilter', {immediate: false})
  private onFilterStringChange(newValue: string, oldValue: string) {
    this.self[types.SET_ROLES_LIST]([]);
    this.self.getRoles({ withDeleted: this.showDeleted, query: newValue, offset: 0, limit: 20 });
  }

  private createNewRole() {
    if (!this.self.hasAuthorityByCode(37)) {
      this.$notifier({
        title: 'Недостаточно прав!', message: 'У вас нет прав для редактирования ролей',
        type: 'error',
      });
      return;
    }
    this.self[types.SET_EMPTY_ACTIVE_ROLE]();
    this.isNewRoleCreation = true;
  }

  private onClickOutside() {
    if (this.self.activeRole && !this.isNewRoleCreation) {
      this.self.setActiveRole(undefined);
    }
    this.isNewRoleCreation = false;
  }

  private onScroll() {
    const elm = this.$el as HTMLElement;
    if (elm.clientHeight >= (elm.scrollHeight - elm.scrollTop)) {
      this.self.getRoles({ withDeleted: this.showDeleted, query: this.stringFilter });
    }
  }

  private onShowDeleted(check: boolean) {
    this.showDeleted = check;
    this.self[types.SET_ROLES_LIST]([]);
    this.self.getRoles({ withDeleted: this.showDeleted });
  }
}

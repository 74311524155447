
import '@/commons/classComponentHooks';

import Vue from 'vue';
import App from './App.vue';
import store from './store/store';
import { router } from './router';
import { globalComponentRegistration, globalDirectiveRegistration } from '@/commons/main.config';
import { runSessionPing } from './commons/globalTasks';
import './styles.scss';

if (window.sessionStorage) {
  window.sessionStorage.setItem('session-initialization', '1');
}

runSessionPing();

globalComponentRegistration();
globalDirectiveRegistration();

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.config.silent = false;
Vue.config.performance = true;

export const $app = new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');

(window as any).$app = $app;










import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-icon-external-systems';

@Component({
    name: baseClassName,
})
export default class IconExternalSystems extends Vue {
    @Prop({default: () => 16}) private width!: number;
    @Prop({default: () => 10}) private height!: number;
    private baseClassName = baseClassName;
}

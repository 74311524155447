import { DocumentsAttribute, AttributesType } from './document-attributes.model';
import { SparkMD5 } from '@/commons/md5hashing';
import { TreeViewData } from './view.model';

export interface AttributeDefinition {
    attributeDefinition: DocumentsAttribute[];
    attributeDefinitionId: number;
    displayIndex: number;
    documentTypeId: number;
    required: boolean;
    versioned: boolean;
}

export interface Catalogue {
    attributes: AttributeDefinition[];
    authorId: number;
    children: Catalogue[];
    code: string;
    deleted: boolean;
    description: string;
    id: number;
    parentId?: number;
    parentTitle?: string;
    path: string;
    title: string;
    type: boolean;
}

export interface CataloguesView extends Catalogue {
    fold?: boolean;
    inEdit?: boolean;
    founded?: boolean;
    level: number;
    children: CataloguesView[];
}

export function createNewCatalogue(parent?: Catalogue, type = false): Catalogue {
    const code = SparkMD5.hash((new Date().getUTCDate().toString())
        + (parent ? parent.id : ''), false);
    const newCatalogue = Object.assign({}, {
        attributes: [],
        authorId: 0,
        children: [],
        code,
        deleted: false,
        description: '',
        id: new Date().valueOf(),
        parentId: parent ? parent.parentId : 0,
        parentTitle: parent ? parent.parentTitle : '',
        path: parent ? parent.path : '',
        title: '',
        type,
    });
    return newCatalogue;
}

import { ExternalSystem } from './external-systems.model';
import { DocumentsAttribute } from './document-attributes.model';

export interface RolesDocumentAttribute {
  code: string;
  value: string | number | boolean;
  attributeDefinition: DocumentsAttribute;
}

export interface Role {
  code: string;
  id: number;
  outerSystem: ExternalSystem | null;
  roleAuthorities: RolesAuthority[];
  title: string;
  attributes: RolesDocumentAttribute[];
  deleted?: boolean;
}

export interface RolesAuthority {
  code: string;
  id: number;
  title: string;
}
export interface RolesChapters {
  title: string;
  authorities: RolesDescription[];
}
export interface RolesDescription {
  description: RolesAuthority[];
  selected: boolean;
}
export function getEmptyRole(): Role {
  return {
    code: '',
    id: 0,
    outerSystem: null,
    roleAuthorities: [],
    title: '',
    attributes: [],
  };
}

export const roleAuthorities: RolesAuthority[] = [
  { code: 'USER_EDIT', id: 1, title: 'Изменение записей справочника (CRUD)' },
  { code: 'USER_VIEW', id: 2, title: 'Просмотр справочника' },
  { code: 'DOCUMENT_ATTRIBUTE_EDIT', id: 3, title: 'Изменение записей справочника (CRUD)' },
  { code: 'DOCUMENT_ATTRIBUTE_VIEW', id: 4, title: 'Просмотр справочника' },
  { code: 'DOCUMENT_TYPE_VIEW', id: 5, title: 'Просмотр справочника' },
  { code: 'DOCUMENT_TYPE_EDIT', id: 6, title: 'Изменение записей справочника (CRUD)' },
  { code: 'DOCUMENT_VIEW_ARCHIVED', id: 11, title: 'Просмотр архивных документов' },
  { code: 'DOCUMENT_C0', id: 12, title: 'Просмотр документов ГС ГО' },
  { code: 'DOCUMENT_C1', id: 13, title: 'Просмотр документов ГС РегСеть' },
  { code: 'DOCUMENT_C2', id: 14, title: 'Просмотр документов ОПК ГО' },
  { code: 'DOCUMENT_C3', id: 15, title: 'Просмотр документов ОПК РегСеть' },
  { code: 'DOCUMENT_SEARCH', id: 16, title: 'Поиск документов' },
  { code: 'DOCUMENT_CREATE', id: 17, title: 'Создание документа' },
  { code: 'DOCUMENT_VERSION', id: 18, title: 'Просмотр версий документа' },
  { code: 'DOCUMENT_DOWNLOAD', id: 19, title: 'Скачивание документа' },
  { code: 'DOCUMENT_VIEW_PROFILE', id: 21, title: 'Просмотр карточки документа' },
  { code: 'DOCUMENT_CREATE_LIKE', id: 23, title: 'Создание документа на подобии «Копировать как»' },
  { code: 'DOCUMENT_PROFILE_EDIT_ALL', id: 24, title: 'Редактирование карточки документа (все документы)' },
  { code: 'DOCUMENT_UPLOAD', id: 27, title: 'Загрузка документов' },
  { code: 'DOCUMENT_PARENT_VIEW', id: 28, title: 'Родительские документы' },
  { code: 'DOCUMENT_CHILDREN_VIEW', id: 29, title: 'Дочерние документы' },
  { code: 'OUTER_SYSTEM_VIEW', id: 30, title: 'Просмотр справочника' },
  { code: 'OUTER_SYSTEM_EDIT', id: 32, title: 'Изменение записей справочника (CRUD)' },
  { code: 'REPORT_DOWNLOAD', id: 33, title: 'Выгрузка документов' },
  { code: 'OUTER_SYSTEM_DOC_VIEW', id: 34, title: 'Просмотр документов' },
  { code: 'OUTER_SYSTEM_DOC_EDIT', id: 35, title: 'Редактирование документов' },
  { code: 'OUTER_SYSTEM_ROLES_VIEW', id: 36, title: 'Просмотр справочника' },
  { code: 'OUTER_SYSTEM_ROLES_EDIT', id: 37, title: 'Изменение записей справочника (CRUD)' },
];

export const usersAccessAuthority = [2, 1];
export const documentAttributesAccessAuthority = [4, 3];
export const documentTypesAccessAuthority = [5, 6];
export const documentsAccess = [11, 12, 13, 14, 15];
export const dashboardAccess = [16, 17, 18, 19, 21, 23];
export const documentProfileAccess = [24, 27, 28, 29];
export const outerSystemsAuthorities = [30, 32];
export const rolesAuthorities = [36, 37];
export const externalSystemsAuthorities = [34, 35];
export const reportsAccessAuthority = [33];

export const roleChapters = [
  ['Каталог документов', documentTypesAccessAuthority],
  ['Атрибуты документа', documentAttributesAccessAuthority],
  ['Пользователи', usersAccessAuthority],
  // ['Внешние системы', outerSystemsAuthorities],
  ['Роли', rolesAuthorities],
  ['Просмотр документов', documentsAccess],
  ['Дашборд', dashboardAccess],
  ['Карточка документа', documentProfileAccess],
  ['Отчетность', reportsAccessAuthority],
];

export const systemRolesChapter = ['Система', [...externalSystemsAuthorities]] as [string, number[]];

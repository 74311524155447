
























































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import {
  DocumentsAttribute, getEmptyDocumentsAttribute, attributeTypes,
  AttributesType,
} from '@/models/document-attributes.model';
import { Dictionary, DictionariesValue, DictionaryValueDTO } from '@/models/dictionaries.model';
import { SelectOption } from '@/models/view.model';
import types from '../../store/types';
import { getAttributesDefinition } from '../UserDocuments/Filters/filters.module';
import { fetchDictionaryValueById, fetchDictionaryValuesById } from '@/controllers/dictionaries.controller';
import { Store } from '@/models/commons';
import { filters } from '@/dictionaries/filters';
import { Catalogue } from '@/models/catalogues.model';
import { QueryParams } from '@/commons/axios.config';
import { FilterViewType } from '@/models/filters.model';

interface DictionarySelect extends SelectOption {
  dictionary: Dictionary;
}

const baseClassName = 'elib-document-attributes-edit-form';

@Component({
  name: baseClassName,
  computed: {
    ...mapState('attributes', ['activeDocumentsAttribute', 'activeDictionary']),
    ...mapGetters('attributes', ['dictionaryValueOptions']),
    ...mapGetters('catalogues', ['catalogueTreeViewData', 'catalogueByNodeId']),
  },
  methods: {
    ...mapMutations('attributes', ['updateActiveAttributeProperty', 'updateActiveAttributeProperty']),
    ...mapActions('attributes', ['getActiveDocumentsAttributeById', types.DELETE_DOCUMENTS_ATTRIBUTE,
      'updateDocumentsAttribute', 'createNewDocumentsAttribute', 'getDictionaries', 'setActiveDictionary']),
    ...mapActions('catalogues', ['getCatalogues']),

  },
})
export default class DocumentAttributeEditForm extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private self: any;
  private query: any = '';
  private isFocused = false;
  private showModal = false;
  private definitionQuery = '';
  private definition: DocumentsAttribute | null = null;
  private attributesSuggestions: DocumentsAttribute[] = [];
  private selectedUserName = 'Selected name';
  private dictionaryValues: Array<DictionaryValueDTO | Store> = [];
  private catalogueByNodeId!: (id?: number) => Store | null;
  private getCatalogues!: (param?: QueryParams) => Promise<Catalogue[]>;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }


  private get documentsAttribute() {
    return Object.assign({}, this.self.activeDocumentsAttribute) as DocumentsAttribute;
  }

  private get isNewAttributteCreation() {
    return this.documentsAttribute.id === 0;
  }

  private get attributeTypeName() {
    return this.documentsAttribute ? this.documentsAttribute.attributeType.title : '';
  }
  private get dictionaryView() {
    return this.attributeCode && (filters[this.attributeCode] &&
      filters[this.attributeCode]?.view) || FilterViewType.AUTOCOMPLETE;
  }
  private get typeOptions(): SelectOption[] {
    return attributeTypes.map((type) => {
      return {
        id: type.id,
        name: type.title,
        selected: false,
        type,
      };
    });
  }

  private get dictionaryOptions(): SelectOption[] {
    return (this.self.dictionaryValues as Dictionary[]).
      map((v) => {
        return {
          id: v.id,
          name: v.title,
          selected: false,
          dictionary: v,
        };
      });
  }

  private get dictionaryItems(): SelectOption[] {
    return (this.self.dictionaryValueOptions as DictionariesValue[]).
      map((v) => {
        return {
          id: v.id,
          name: v.title,
          selected: false,
          option: v,
        };
      });
  }

  private get hasDefaultValues() {
    return !['Справочник'].includes(this.attributeTypeName);
  }

  private get isSelectFromReference() {
    return this.attributeTypeName === 'Справочник';
  }

  private get isSelectFromBoolean() {
    return this.attributeTypeName === 'Логический';
  }

  private get isSelectFromNumber() {
    return this.attributeTypeName === 'Число';
  }

  private get isSelectFromDate() {
    return this.attributeTypeName === 'Дата';
  }

  private get YesNoValues() {
    return [{ id: 0, value: true, label: 'Да' }, { id: 1, value: false, label: 'Нет' }];
  }
  private get isDictionary() {
    return this.documentsAttribute?.dictionary;
  }
  private get dictionaryReferenceName() {
    const attribute = this.self.activeDocumentsAttribute as DocumentsAttribute;
    return attribute.dictionary ? attribute.dictionary.title : '';
  }

  private get defaultValueAsDictionoryItem() {
    const activeAttribute = this.self.activeDocumentsAttribute as DocumentsAttribute;
    const valueId = parseInt(activeAttribute.defaultValue as string, 10);
    const activeDictionary = this.self.activeDictionary as Dictionary;
    const value = activeDictionary && activeDictionary.dictionaryValues ?
      activeDictionary.dictionaryValues.find((v) => v.id === valueId) : '';
    return value ? value.title : 'Выберите значение....';
  }
  private get valueGetter() {

    return this.filterParams && this.filterParams.getter;
  }
  private get valueSetter() {
    return this.filterParams && this.filterParams.setter;
  }
  private get attributeCode() {
    return this.definition?.code;
  }
  private get filterParams() {
    return (this.attributeCode && filters[this.attributeCode]) || null;
  }
  private get documentTypeName() {
    return this.documentsAttribute && this.catalogueByNodeId((this.documentsAttribute.defaultValue as number))?.title;
  }

  private beforeCreate() {
    this.self = this;
  }

  private created() {
    this.initDictionaryValue();
  }

  private async initDictionaryValue() {
    await this.getCatalogues();
    if (!this.isDictionary) {
      this.definitionQuery = this.definition?.title || '';
      this.query = this.documentTypeName;
      return;
    }
    if (this.documentsAttribute?.defaultValue) {
      const dto = await fetchDictionaryValueById(parseInt(this.documentsAttribute?.defaultValue as string, 10)) || null;
      this.query = dto?.value;
      this.definitionQuery = this.documentsAttribute?.dictionary?.title || '';
    }
  }

  private async queryAttributes(query: string) {
    this.definitionQuery = query;
    await this.fetchSuggestions(query);
  }

  private async fetchSuggestions(query: string) {
    this.attributesSuggestions = await getAttributesDefinition({ query });
    this.attributesSuggestions = this.attributesSuggestions
      .filter((attr: DocumentsAttribute) => (attr.attributeType.code === 'DICTIONARY')
        && (attr.code !== 'document_type') && (attr.code !== 'document_category'));
  }

  private async queryDictionaryValues(query?: string) {
    const params = { query, offset: 0, limit: this.filterParams?.limit || 3 };
    const activeAttribute = this.definition;
    const dictionary = activeAttribute?.dictionary;
    if (dictionary?.id) {
      const dictionaryValues = await fetchDictionaryValuesById(dictionary.id, params);

      this.dictionaryValues = [...dictionaryValues];
    }
    if (this.valueGetter) {
      const dictionaryValues = await this.valueGetter(params);
      this.dictionaryValues = [...dictionaryValues];
    }
  }

  private saveDocumentsAttribute() {
    if (!this.formIsValid()) {
      return;
    }
    const attribute = this.self.activeDocumentsAttribute;
    if (attribute && attribute.id !== 0) {
      this.self.updateDocumentsAttribute(attribute).then(() => this.$emit('close'));
    } else if (attribute && attribute.id === 0) {
      this.self.createNewDocumentsAttribute(attribute).then(() => this.$emit('close'));
    }
    this.$emit('close');
  }

  private deleteAttribute() {
    const activeDocumentsAttribute = this.self.activeDocumentsAttribute as DocumentsAttribute;
    this.self[types.DELETE_DOCUMENTS_ATTRIBUTE](activeDocumentsAttribute);
    this.$emit('close');
  }

  private onDictionarySelect(option: DocumentsAttribute) {
    this.definition = option;
    this.definitionQuery = option.title;
    if (this.isDictionary) {
      this.self.updateActiveAttributeProperty({ prop: 'dictionary', value: option.dictionary });
      this.self.setActiveDictionary(option.dictionary);
    } else {
      this.self.updateActiveAttributeProperty({ prop: '_documentType', value: { title: option.title, id: option.id } });
    }
    this.queryDictionaryValues();
  }

  private onTypeSelect($event: { type: AttributesType }) {
    this.self.updateActiveAttributeProperty({ prop: 'attributeType', value: $event.type });
    this.self.updateActiveAttributeProperty({ prop: 'defaultValue', value: '' });
  }

  private formIsValid() {
    const activeAttribute = this.self.activeDocumentsAttribute as DocumentsAttribute;
    const mainForm = this.$refs.mainForm as HTMLFormElement;
    if (!mainForm.checkValidity()) {
      this.$notifier({
        title: 'Ошибка формы!', message: 'Не заполнены обязательны поля',
        type: 'error',
      });
      return false;
    }
    if (activeAttribute.attributeType.code === 'dictionary' && !activeAttribute.dictionary) {
      this.$notifier({
        title: 'Ошибка формы!', message: 'Не заполнены тип справочника',
        type: 'error',
      });
      return false;
    }
    return true;
  }
}

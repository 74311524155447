import Vue from 'vue';
import NavBar from '@/components/TheNavBar.vue';
import Icons from '@/components/Icons/index';
import Modals from '@/components/Modals/index';
import Inputs from '@/components/Inputs/index';
import Profiles from '@/components/Profile/index';
import DataTable from '@/components/DataTable/DataTable.vue';
import LoadingSpinner from '@/components/Spinners/Spinner.vue';
import Dropdowns from '@/components/Dropdowns/index';
import Adminstration from '@/components/Administration/index';
import notifier from '@/components/Notifier/Notifier.ts';
import tooltipDirective from '@/components/Tooltips/Tooltip.ts';
import Treesview from '@/components/Treeview/index';
import Spinners from '@/components/Spinners/index';
import ToggleButton from '@/components/Buttons/Button__toggle.vue';
import { directive as ClickOutSideDirective } from '@/directives/ClickOutside.directive';
import { directive as CatalogueEditDirective } from '@/directives/CatalogueEdit.directive';
import { directive as ClickCheck } from '@/directives/ClickCheck.directive';
import { directive as TooltipDirective } from '@/directives/Tooltip.directive';
import { getDateRepresentation } from '@/commons/filters.main';

export function globalComponentRegistration() {
    Vue.component('elib-navbar', NavBar);
    Vue.component('elib-data-table', DataTable);
    Vue.component('loading-spinner', LoadingSpinner);
    Vue.component('elib-toggle-button', ToggleButton);
    Vue.use(Modals);
    Vue.use(Icons);
    Vue.use(Inputs);
    // Vue.use(Tooltip);
    Vue.use(Profiles);
    Vue.use(Adminstration);
    Vue.use(Dropdowns);
    Vue.use(Treesview);
    Vue.use(Spinners);
    Vue.filter('getDateRepresentation', getDateRepresentation);
    Vue.prototype.$notifier = notifier;
    // Vue.prototype.$tooltip = tooltip;
}

export function globalDirectiveRegistration() {
    Vue.directive('elib-click-outside', ClickOutSideDirective);
    Vue.directive('elib-catalogue-edit', CatalogueEditDirective);
    Vue.directive('elib-inside-click', ClickCheck);
    Vue.directive('elib-tooltip', tooltipDirective);
}

import { Role } from '@/models/roles.model';
import { User } from './users.model';

export interface ExternalSystem {
    deleted: boolean;
    description: string;
    id?: number;
    innerCode: string;
    name: string;
    outerCode: string;
    roles: Role[];
    user?: User;
}

export interface ITSystem extends ExternalSystem {
    code: string;
}

export function getEmptyExternalSystem(): ExternalSystem {
    return {
        deleted: false,
        description: '',
        id: 0,
        innerCode: '',
        name: '',
        outerCode: '',
        roles: [],
    };
}

import { Role, getEmptyRole } from '@/models/roles.model';

export interface User {
    activated: boolean;
    department: Department;
    departmentId: number;
    email: string;
    external: boolean;
    firstName: string;
    fullName: string;
    id: number;
    lastName: string;
    login: string;
    middleName: string;
    password: string;
    phone: string;
    position: Position;
    positionId: number;
    roles: Role[];
}

export interface UserAdditionalInfo {
    favoriteDocuments: number[];
}

export interface Department {
    code: string;
    headId: number;
    id: number;
    parentId: number;
    parentTitle: string;
    path: string;
    title: string;
}

export interface Position {
    id: number;
    title: string;
}

function getEmptyDepartment(): Department {
    return {
        code: '0',
        headId: 0,
        id: 0,
        parentId: 0,
        parentTitle: '',
        path: '',
        title: '',
    };
}

export function getEmptyUser(): User {
    return {
        activated: true,
        department: getEmptyDepartment(),
        departmentId: 0,
        email: '',
        external: false,
        firstName: '',
        fullName: '',
        id: 0,
        lastName: '',
        login: '',
        middleName: '',
        password: '',
        phone: '',
        position: {id: 0, title: ''},
        positionId: 0,
        roles: [getEmptyRole()],
    };
}















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import moment from 'moment';
import { Document } from '@/models/user-documents.model';
import { getDocumentVersionsCount } from '@/controllers/user-documents.controllers';
import types from '@/store/types';

const baseClassName = 'elib-documents-delete-dialog';

@Component({
  name: baseClassName,
  methods: {
    ...mapActions('documents', [types.DELETE_DOCUMENT, types.DELETE_DOCUMENT_PHYSICALLY]),
  },
})
export default class DocumentDeleteDialog extends Vue {
  @Prop({ required: true }) private activeDocument!: Document;
  @Prop({ required: true }) private permanentlyDelete!: boolean;
  private baseClassName = baseClassName;
  private loading = false;
  private self: any;

  private beforeCreate() {
    this.self = this;
  }

  private get versionDetailes() {
    return `Версия: ${this.activeDocument.currentVersionNumber} от ${moment(this.activeDocument.updated).format('DD.MM.YYY, HH:mm')}`;
  }

  private async removeActiveDocument() {
    this.loading = true;
    const documentId = (this.self.activeDocument as Document).documentId;
    const deleted = this.permanentlyDelete ?
      await this.self[types.DELETE_DOCUMENT_PHYSICALLY](this.self.activeDocument) :
      await this.self[types.DELETE_DOCUMENT](this.self.activeDocument);
    const versionsCount = await getDocumentVersionsCount(documentId);
    this.loading = false;
    if (deleted && this.permanentlyDelete && versionsCount) {
      this.$emit('delete');
    } else {
      this.$router.push({ name: 'documents'});
    }
    this.$emit('close');
  }
}

import { DirectiveOptions, VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

let focusedElement: {[key: string]: any} | null = null;

export const directive: DirectiveOptions = {
    bind: (el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldNode: VNode) => {
        focusedElement = {
            el,
            bind: false,
            handler: binding.value,
            listener: (ev: Event) => {
                const target = ev.target as HTMLElement;
                const isClickInside = el === ev.target || el.contains(target) || !target.offsetHeight;
                if (!isClickInside && !!focusedElement) {
                    binding.value();
                    return;
                }
            },
        };
        document.addEventListener('click', focusedElement.listener);
    },
    unbind: (el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldNode: VNode) => {
        if (!!focusedElement) {
            document.removeEventListener('click', focusedElement.listener);
            focusedElement = null;
        }
    },
};

import Vue from 'vue';
import CatalogueList from '@/components/Administration/CataloguesList.vue';
import CatalogueTree from '@/components/Administration/CataloguesTree.vue';
import DocumentAttributesFeatures from '@/components/Administration/Administration__document-attributes-features.vue';
import DocumentAttributeEditForm from '@/components/Administration/Administration__document-attributes-form.vue';
import DocumentAttributesList from '@/components/Administration/DocumentAttributesList.vue';
import ExternalSystemEditForm from '@/components/Administration/Administration__external-system-form.vue';
import ExternalSystemsList from '@/components/Administration/Administration__external-systems-list.vue';
import RolesList from '@/components/Administration/Roles/RolesList.vue';
import UsersEditForm from '@/components/Administration/UserEditForm.vue';
import UsersList from '@/components/Administration/UsersList.vue';
import CatalogueInput from '@/components/Administration/CataloguesInput.vue';


export default (vue: typeof Vue, options: {[key: string]: any}): void => {
        Vue.component('elib-catalogues-list', CatalogueList);
        Vue.component('elib-catalogue-tree', CatalogueTree);
        Vue.component('elib-document-attributes-features', DocumentAttributesFeatures);
        Vue.component('elib-document-attributes-edit-form', DocumentAttributeEditForm);
        Vue.component('elib-document-attributes-list', DocumentAttributesList);
        Vue.component('elib-external-system-edit-form', ExternalSystemEditForm);
        Vue.component('elib-external-systems-list', ExternalSystemsList);
        Vue.component('elib-roles-list', RolesList);
        Vue.component('elib-users-edit-form', UsersEditForm);
        Vue.component('elib-users-list', UsersList);
        Vue.component('elib-catalogue-input', CatalogueInput);
};

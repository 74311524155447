




















import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-input-search';

@Component({
  name: baseClassName,
})
export default class InputSearch extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop({ default: () => '' }) private placeholder!: string;
  @Prop({ default: () => '' }) private value!: string;
  @Prop({ default: () => false }) private readonly!: boolean;
  private baseClassName = baseClassName;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }
}

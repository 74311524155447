import { DocumentsFilterItemDto } from './../../models/filters.model';
import Vue from 'vue';
import { Module } from 'vuex';
import {
    Document,
    DocumentCustomAttribute,
    DocumentsDeletedInfo,
} from '@/models/user-documents.model';
import {
    postNewUserDocument,
    fetchUserDocuments,
    fetchUserDocumentById,
    putUserDocument,
    putDeletedDocument,
    fetchParentDocuments,
    fetchChildDocuments,
    fetchDocumentVersions,
    fetchDocumentsByPredefinedFilters,
    fetchUserDocumentsVersionById,
    fetchDocumentsWithTemporaryFilter,
    fetchDocumentsWithTemporaryFilterReport,
    fetchChangeDocumentsFavorites,
    fetchChangeDocumentsCategory,
    deletePhysicallyDocumentVersion,
    fetchUserDocumentsReport,
    fetchUserDocumentsByTypeId, postUserDocumentsReport,
} from '@/controllers/user-documents.controllers';
import { filtersModelToDto, DocumentsFilter } from '@/models/filters.model';
import { DocumentsAttribute, defaultValueIsBoolean } from '@/models/document-attributes.model';
import { parseBooleanAsString } from '@/commons/dom.helpers';
import { QueryParams } from '@/commons/axios.config';
import * as types from '@/store/types';
import { fetchDictionaryValueById } from '@/controllers/dictionaries.controller';
import { fetchRequiredDocumentAttributes } from '@/controllers/document-attributes.controllers';
import { Catalogue } from '@/models/catalogues.model';
import getDocumentHashSum from '@/commons/hashSum';
import moment from 'moment';


type StringFilter = string | undefined;

interface ModuleState {
    [types.DOCUMENTS_LIST]: Document[];
    [types.DOCUMENTS_LIST_REPORT]: Document[];
    offset: number;
    [types.SEARCH_STRING]: StringFilter;
    activeDocument: Document | undefined;
    activeDocumentHash: string;
    activeFilterId: number | undefined;
    predefinedFiltersName: string | undefined;
    temporaryFilter: { [key: string]: any } | undefined;
    hasVersionsChanges: boolean;
    [types.SEARCH_IN_CONTENT]: boolean;
    [types.ACTIVE_DOCUMENT_TYPE]: Catalogue | null;
    [types.FILTERS_LIST]: DocumentsFilterItemDto[];
}

interface PredefinedFilterActionParams {
    filtersName: string;
    query: QueryParams;
}

export const module: Module<ModuleState, any> = {
    namespaced: true,
    state: {
        [types.DOCUMENTS_LIST]: [],
        [types.DOCUMENTS_LIST_REPORT]: [],
        offset: 0,
        [types.SEARCH_STRING]: undefined,
        activeDocument: undefined,
        activeDocumentHash: '',
        activeFilterId: undefined,
        predefinedFiltersName: undefined,
        temporaryFilter: undefined,
        hasVersionsChanges: false,
        [types.SEARCH_IN_CONTENT]: false,
        [types.ACTIVE_DOCUMENT_TYPE]: null,
        [types.FILTERS_LIST]: [],
    },
    mutations: {
        [types.SET_DOCUMENTS_LIST](state: ModuleState, payload: Document[]) {
            state[types.DOCUMENTS_LIST] = payload || [];
        },
        [types.SET_DOCUMENTS_LIST_REPORT](state: ModuleState, payload: Document[]) {
            state[types.DOCUMENTS_LIST_REPORT] = payload;
        },
        [types.APPEND_TO_DOCUMENTS_LIST](state: ModuleState, payload: Document[]) {
            const data = [...state[types.DOCUMENTS_LIST], ...payload || []];
            state[types.DOCUMENTS_LIST] = data.reduce((acc, doc) => {
                return acc.some((v) => v.documentId === doc.documentId) ? acc : [...acc, doc];
            }, [] as Document[]);
        },
        [types.APPEND_TO_DOCUMENTS_LIST_REPORT](state: ModuleState, payload: Document[]) {
            state[types.DOCUMENTS_LIST_REPORT] = [...state[types.DOCUMENTS_LIST_REPORT], ...payload];
        },
        setActiveDocument(state: ModuleState, payload: Document) {
            state.activeDocument = payload;
            state.activeDocumentHash = getDocumentHashSum(payload);
        },
        removeDocumentFromList(state: ModuleState, id: number) {
            state.documentsList = [...state.documentsList.filter((v) => v.documentId !== id)];
        },
        [types.UPDATE_ACTIVE_DOCUMENTS_PROP](state: ModuleState, payload: { prop: string, value: any }) {
            if (state.activeDocument) {
                Vue.set(state.activeDocument, payload.prop, payload.value);
                const index = state.documentsList.
                    findIndex((role) => role.documentId === (state.activeDocument as Document).documentId);
                state.documentsList[index] = state.activeDocument;
                state.documentsList = [...state.documentsList];
                state.activeDocument = { ...state.activeDocument };
            }
        },
        updateDocumentArrayProp(state: ModuleState, payload: { prop: string, index: number, value: any }) {
            if (state.activeDocument) {
                const doc = (state.activeDocument as { [key: string]: any });
                const prop = doc[payload.prop] as any[];
                prop[payload.index] = payload.value;
                doc[payload.prop] = [...doc[payload.prop]];
                const index = state.documentsList.
                    findIndex((role) => role.documentId === (state.activeDocument as Document).documentId);
                state.documentsList[index] = state.activeDocument;
                state.documentsList = [...state.documentsList];
                state.activeDocument = { ...state.activeDocument };
            }
        },
        addAttributeToActiveDocument(state: ModuleState, payload: DocumentsAttribute) {
            if (state.activeDocument) {
                const defaultValue = defaultValueIsBoolean(payload) ?
                    parseBooleanAsString(payload.defaultValue as string) : payload.defaultValue;
                const newAttribute: DocumentCustomAttribute = {
                    code: payload.code,
                    value: defaultValue || null,
                    attributeDefinition: payload,
                };
                state.activeDocument.attributes.push(newAttribute);
                state.activeDocument.attributes = [...state.activeDocument.attributes];
            }
        },
        updateDocumentActiveAttribute(state: ModuleState, payload: DocumentCustomAttribute) {
            if (state.activeDocument) {
                const index = state.activeDocument.attributes.findIndex((attrib) => attrib.code === payload.code);
                state.activeDocument.attributes[index] = payload;
                state.activeDocument.attributes = [...state.activeDocument.attributes];
            }
        },
        deleteCustomAttributeFromDocument(state: ModuleState, payload: DocumentCustomAttribute) {
            if (state.activeDocument) {
                state.activeDocument.attributes = [...state.activeDocument.attributes.
                    filter((v) => v.code !== payload.code)];
            }
        },
        deleteActiveDocumentComments(state: ModuleState, payload: number) {
            if (state.activeDocument) {
                state.activeDocument.documentComments = state.activeDocument.documentComments.
                    filter((v, i) => i !== payload);
            }
        },
        deleteActiveDocumentNote(state: ModuleState, payload: number) {
            if (state.activeDocument) {
                state.activeDocument.documentNotes = state.activeDocument.documentNotes.
                    filter((v, i) => i !== payload);
            }
        },
        [types.SET_FILTER_STRING](state: ModuleState, payload: string) {
            state.filterString = payload;
        },
        setActiveFilterId(state: ModuleState, payload: number) {
            state.documentsList = [];
            state.activeFilterId = payload ? payload : undefined;
            state.predefinedFiltersName = undefined;
        },
        setPredefinedFiltersName(state: ModuleState, payload: string) {
            state.documentsList = [];
            state.activeFilterId = undefined;
            state.predefinedFiltersName = payload;
            state.temporaryFilter = undefined;
        },
        updateActiveDocument(state: ModuleState, payload: Document) {
            if (state.activeDocument && state.activeDocument.documentId === payload.documentId) {
                state.activeDocument = payload ? payload : undefined;
            }
        },
        [types.CREATE_NEW_DOCUMENT_ON_BASIS](state: ModuleState) {
            if (state.activeDocument) {
                const documentsCopy: Document = {
                    ...state.activeDocument,
                    documentId: 0,
                    documentNumber: state.activeDocument.documentNumber + ' (Копия)',
                };
                state.activeDocument = documentsCopy;
            }
        },
        resetDocumentsList(state: ModuleState) {
            state.documentsList = [];
            state.filterString = undefined;
        },
        replaceDocumentsList(state: ModuleState, payload: Document[]) {
            state.documentsList = payload;
        },
        resetState(state: ModuleState) {
            state.activeDocument = undefined;
            state.documentsList = [];
            state.filterString = undefined;
            state.predefinedFiltersName = undefined;
            state.activeFilterId = undefined;
        },
        setTemporaryFilter(state: ModuleState, payload: { [key: string]: any }) {
            state.temporaryFilter = payload;
            state.predefinedFiltersName = undefined;
        },
        setHasVersionsChanges(state: ModuleState, payload: boolean) {
            state.hasVersionsChanges = payload;
        },
        clearTemporaryFilterItems(state: ModuleState) {
            if (state.temporaryFilter && state.temporaryFilter.documentFilterDTO) {
                state.temporaryFilter.documentFilterDTO.specification = [];
            }
        },
        setDocumentMandatoryAttributes(state: ModuleState, payload: DocumentsAttribute[]) {
            if (state.activeDocument) {
                const definitions = payload.filter((v) => v.required && v.attributeType)
                    .map((attributeDefinition) => ({
                        code: attributeDefinition.code,
                        value: getDefaultValue(attributeDefinition),
                        attributeDefinition,
                    }));
            }
        },
        [types.SET_SEARCH_BY_CONTENT](state: ModuleState, value: boolean) {
            state[types.SEARCH_IN_CONTENT] = value;
        },
        [types.SET_ACTIVE_DOCUMENT_TYPE](state: ModuleState, payload: Catalogue | null) {
            state.activeDocumentType = payload;
        },
        [types.SET_FILTERS_LIST](state: ModuleState, payload: DocumentsFilterItemDto[]) {
            state.filtersList = payload || [];
        },
        setActiveDocumentHash(state: ModuleState, payload: string) {
            state.activeDocumentHash = payload;
        },
    },
    getters: {
        [types.DOCUMENT_HAS_CHANGES]({activeDocument, activeDocumentHash}) {
            return !!activeDocument && activeDocumentHash !== getDocumentHashSum(activeDocument);
        },
    },
    actions: {
        async [types.GET_USER_DOCUMENTS]({ commit, rootState, state, dispatch }, payload?: QueryParams) {
            const params = prepareQueryParam(state, rootState, payload);
            const { filtersList, activeDocumentType } = state;
            let documents: Document[] = [];
            if (filtersList && filtersList.length) {
                documents = await fetchDocumentsWithTemporaryFilter({
                    ...params,
                    documentFilterDTO: { specification: state.filtersList },
                });
            } else if (activeDocumentType && activeDocumentType.id) {
                documents = await fetchUserDocumentsByTypeId(activeDocumentType.id, params) || [];
            } else {
                documents = await fetchUserDocuments(params) || [];
            }
            commit(types.APPEND_TO_DOCUMENTS_LIST, documents || []);
            return documents;
        },
        async [types.GET_USER_DOCUMENTS_REPORT]({ commit, rootState, state, dispatch }, params?: QueryParams) {
            const query = prepareQueryParamReport(state, rootState, params);
            let documents: Document[] = [];
            if (isValidTemporaryFilter(state)) {
                const temporaryFilter = { ...state.temporaryFilter } as any;
                temporaryFilter.offset = state.documentsListReport.length;
                documents = await fetchDocumentsWithTemporaryFilterReport(temporaryFilter);
            } else if (state.predefinedFiltersName) {
                documents = await dispatch('getUserDocumentsByPredefinedFilter',
                    { filtersName: state.predefinedFiltersName, query }) || [];
            } else {
                documents = await fetchUserDocumentsReport(query as QueryParams) || [];
            }
            commit(types.APPEND_TO_DOCUMENTS_LIST_REPORT, documents || []);
            return documents;
        },
        async [types.POST_USER_DOCUMENTS_REPORT]({ commit, rootState, state, dispatch }, ids?: any) {
            const documents = await postUserDocumentsReport(ids as any) || [];
            commit(types.APPEND_TO_DOCUMENTS_LIST_REPORT, documents || []);
            return documents;
        },
        async getUserDocumentsByPredefinedFilter({ rootState }, payload: PredefinedFilterActionParams) {
            const documents = await fetchDocumentsByPredefinedFilters(
                payload.filtersName,
                payload.query,
                rootState.token);
            return documents;
        },
        async [types.CREATE_NEW_DOCUMENT_ON_BASIS]({ commit, rootState, state, dispatch }) {
            commit(types.CREATE_NEW_DOCUMENT_ON_BASIS);
        },
        async [types.FETCH_DOCUMENT_BY_ID]({ commit, rootState, state, dispatch }, documentId: number) {
            const document = await fetchUserDocumentById(documentId);
            await prepareDocumentForLoading(document as Document, rootState);
            commit('setActiveDocument', document);
            const parents = await fetchParentDocuments(rootState.token, documentId) || [];
            commit(types.UPDATE_ACTIVE_DOCUMENTS_PROP, { prop: 'parentDocuments', value: parents });
            const children = await fetchChildDocuments(rootState.token, documentId) || [];
            commit(types.UPDATE_ACTIVE_DOCUMENTS_PROP, { prop: 'childDocuments', value: children });
            return state.activeDocument;
        },
        async [types.FETCH_DOCUMENT_VERSIONS]({ commit, state }, documentId: number) {
            const versions = await fetchDocumentVersions(documentId) || [];
            commit(types.UPDATE_ACTIVE_DOCUMENTS_PROP, { prop: 'documentVersions', value: versions });
            return state.activeDocument;
        },
        async [types.FETCH_REQUIRED_DOCUMENT_ATTRIBUTES]({ commit, state }) {
            const definitionCodes = [
                ...await fetchRequiredDocumentAttributes() || [],
                ...state.activeDocument?.attributes.map((v) => v.attributeDefinition) || [],
            ].reduce((acc, value) => ({ ...acc, [value.code]: value }), {} as { [key: string]: DocumentsAttribute });
            const functor = (definition: DocumentsAttribute) => {
                const docAttribute = state.activeDocument?.attributes.find((attrib) => attrib.code === definition.code);
                return {
                    attributeDefinition: definition,
                    code: definition.code,
                    value: [undefined, null].includes(docAttribute?.value) ? null : docAttribute?.value,
                    title: docAttribute?.title,
                    description: docAttribute?.description,
                    identity: docAttribute?.identity,
                };
            };
            const attributes = Object.values(definitionCodes).map(functor);
            commit(types.UPDATE_ACTIVE_DOCUMENTS_PROP, { prop: 'attributes', value: attributes });
            return attributes;
        },
        async setActiveVersionById({ commit, rootState, state }, payload: { documentsId: number, version: number }) {
            const requestProps = { ...payload, ...{ token: rootState.token || '' } };
            const document = await fetchUserDocumentsVersionById(requestProps);
            await prepareDocumentForLoading(document as Document, rootState);
            commit('setActiveDocument', {
                ...state.activeDocument,
                ...document,
                ...{ currentVersionNumber: payload.version },
            });
            return state.activeDocument;
        },
        async [types.SAVE_NEW_DOCUMENT]({ rootState }, payload: Document) {
            const document = prepareDocumentForSave({ ...payload }, { ...rootState });
            return await postNewUserDocument(document);
        },
        async [types.UPDATE_DOCUMENT]({ rootState, commit }, payload: Document) {
            const document = prepareDocumentForSave({ ...payload }, { ...rootState });
            const updatedDocument = await putUserDocument(document);
            if (updatedDocument && !!updatedDocument) {
                commit('updateActiveDocument', payload);
            }
            return updatedDocument;
        },
        async [types.DELETE_DOCUMENT]({ rootState, commit }, payload: Document) {
            const info: DocumentsDeletedInfo = {
                value: true,
                versionNumber: payload.currentVersionId,
                documentId: payload.documentId,
            };
            const deleted = await putDeletedDocument(rootState.token, info);
            if (deleted) {
                commit('removeDocumentFromList', payload.documentId);
            }
            return deleted;
        },
        async [types.DELETE_DOCUMENT_PHYSICALLY]({ rootState, commit }, payload: Document) {
            const info: DocumentsDeletedInfo = {
                value: true,
                versionNumber: payload.currentVersionId,
                documentId: payload.documentId,
            };
            const deleted = await deletePhysicallyDocumentVersion(rootState.token, payload);
            if (deleted) {
                commit('removeDocumentFromList', payload.documentId);
            }
            return deleted;
        },
        async [types.FETCH_DOCUMENTS_BY_FILTERS]({ commit }, specification: DocumentsFilterItemDto[]) {
            const params = { offset: 0 };
            const documents = await fetchDocumentsWithTemporaryFilter({
                ...params,
                documentFilterDTO: { specification },
            });
            commit('replaceDocumentsList', documents);
        },
        async applyTemporaryFilter({ rootState, commit }, payload: DocumentsFilter) {
            const filtersDTO = filtersModelToDto(payload);
            const temporaryFilter = {
                offset: 0,
                documentFilterDTO: {
                    specification: filtersDTO.specification,
                },
            };
            const documents = await fetchDocumentsWithTemporaryFilter(temporaryFilter);
            commit('replaceDocumentsList', documents);
            commit('setTemporaryFilter', temporaryFilter);
        },
        async applyCatalougeFilter({ rootState, commit }, payload: any) {
            const filter: DocumentsFilterItemDto = {
                externalField: 'ID',
                externalId: payload.id,
                externalTable: 'DOCUMENT_TYPE',
                stringValue: payload.title,

            };
            const temporaryFilter = {
                offset: 0,
                documentFilterDTO: {
                    specification: [filter],
                },
            };

            const documents = await fetchDocumentsWithTemporaryFilter(temporaryFilter);
            commit('replaceDocumentsList', documents);
            commit('setTemporaryFilter', temporaryFilter);
        },
        async [types.UPDATE_NON_VERSIONED_PROPS]({ rootState }, payload: Document) {
            if (payload) {
                await fetchChangeDocumentsFavorites(payload.documentId, payload.favorite, rootState.token);
                if (payload.documentsCategory) {
                    await fetchChangeDocumentsCategory(payload.documentId, payload.documentsCategory, rootState.token);
                }
            }
        },
        async [types.FETCH_DOCUMENTS_BY_TYPE_ID]({ commit, state, rootState }, documentType: Catalogue) {
            const query = prepareQueryParam(state, rootState, {});
            const documents = await fetchUserDocumentsByTypeId(documentType.id, query) || [];
            commit(types.SET_ACTIVE_DOCUMENT_TYPE, documentType);
            commit(types.APPEND_TO_DOCUMENTS_LIST, documents || []);
            return documents;
        },
        updateActiveDocumentHashSum({commit, state}) {
            commit('setActiveDocumentHash', getDocumentHashSum(state.activeDocument));
        },
    },
};

function prepareDocumentForSave(document: Document, state: any) {
    if (document.signatories && !!document.signatories) {
        document.signatories = [...(document.signatories as string[]).filter((v) => v && !!v)];
    }
    if (document.documentVersions) {
        delete document.documentVersions;
    }
    document.documentName = document.documentName.trim();
    document.attributes = document.attributes.filter((v) => v.value !== null);
    document.author = state.session.user;
    return document;
}

async function prepareDocumentForLoading(document: Document, state: any) {
    document.companyGroup = document?.companyGroup?.id ?
        await fetchDictionaryValueById(document?.companyGroup?.id) : null;
    document.documentShortName = document?.documentShortName?.id ?
        await fetchDictionaryValueById(document?.documentShortName?.id) : null;
    if (!document.author || document.author === null) {
        document.author = state.session.user;
    }
    if (!document.signatories || document.signatories === null) {
        document.signatories = ['', ''];
    }
    if (document.signatories instanceof Array && !document.signatories.length) {
        document.signatories = ['', ''];
    }
    if (document.signatories && document.signatories !== null && document.signatories.length === 1) {
        document.signatories.push('');
    }
    if (!document.documentComments) {
        document.documentComments = [];
    }
    if (!document.documentNotes) {
        document.documentNotes = [];
    }
    if (document.attributes) {
        document.attributes.forEach((v) => {
            if (v.attributeDefinition.attributeType &&
                v.attributeDefinition.attributeType.code === 'BOOLEAN') {
                v.value = v.value === 'true' ? true : false;
            }
            if (v.attributeDefinition.attributeType &&
                v.attributeDefinition.attributeType.code === 'DATE' &&
                typeof v.value === 'string') {
                    v.value = moment(v.value).valueOf();
                }
        });
    }
    return document;
}

function prepareQueryParam(state: ModuleState, rootSate: any, params: QueryParams | undefined) {
    const newParams = {
        ...{ offset: state.documentsList.length, query: state.filterString, searchInContent: state.searchInContent },
        ...params,
    } as any;
    if (hasValidFilter(rootSate) && state.activeFilterId) {
        newParams.docFilterId = state.activeFilterId;
    }
    newParams.searchInContent = state.searchInContent && !!state.filterString && state.filterString.length >= 4;
    return newParams;
}

function prepareQueryParamReport(state: ModuleState, rootSate: any, params: QueryParams | undefined) {
    const newParams = {
        ...{
            offset: state.documentsListReport.length, query: state.filterString, searchInContent: state.searchInContent,
        },
        ...params,
    } as any;
    if (hasValidFilter(rootSate) && state.activeFilterId) {
        newParams.docFilterId = state.activeFilterId;
    }
    newParams.searchInContent = state.searchInContent && !!state.filterString && state.filterString.length >= 4;
    return newParams;
}

function hasValidFilter(rootSate: any) {
    return rootSate.filters &&
        rootSate.filters.activeFilter &&
        rootSate.filters.activeFilter.items &&
        rootSate.filters.activeFilter.items.length;
}

function isValidTemporaryFilter(state: ModuleState) {
    return state.temporaryFilter &&
        state.temporaryFilter.documentFilterDTO &&
        state.temporaryFilter.documentFilterDTO.specification.length;
}

function getDefaultValue(attribute: DocumentsAttribute) {
    if (attribute.defaultValue === undefined) {
        return null;
    }
    if (['STRING', 'DATE'].includes(attribute.attributeType.code)) {
        return attribute.defaultValue;
    }
    if (attribute.attributeType.code === 'NUMBER') {
        const value = parseInt(attribute.defaultValue as string, 10);
        return isNaN(value) ? value : null;
    }
    if (attribute.attributeType.code === 'BOOLEAN') {
        return parseBooleanAsString(attribute.defaultValue as string);
    }
    return attribute.defaultValue;
}

export const CHECK_AUTHORITIES_FOR_DOCUMENTS_EDIT = 'canEditDocument';
export const SET_SEARCH_BY_CONTENT = 'setSearchByDocumentsContent';
export const SEARCH_STRING = 'filterString';
export const SEARCH_IN_CONTENT = 'searchInContent';
export const HAS_ACCESS_BY_DOCUMENT_ATTRIBUTES = 'hasAccessByDocumentAttributes';

export const LOADING = 'loading';
export const ERROR = 'error';

/// Roles
export const SET_ROLES_LIST = 'setRolesList';
export const ADD_ROLES_TO_LIST = 'addRolesToList';
export const ROLE_DOCUMENT_ATTRIBUTES = 'roleDocumentAttributes';
export const SET_EMPTY_ACTIVE_ROLE = 'setEmptyActiveRole';

/// Session
export const HAS_AUTHORITY_BY_CODE = 'hasAuthorityByCode';
export const USER_ACCESS_DOCUMENT_ATTRIBUTES = 'userAccessDocumentAttributes';

/// User documents
export const DOCUMENTS_LIST = 'documentsList';
export const DOCUMENTS_LIST_REPORT = 'documentsListReport';
export const ACTIVE_DOCUMENT = 'activeDocument';
export const CREATE_NEW_DOCUMENT_ON_BASIS = 'copyActiveDocumentToNew';
export const UPDATE_ACTIVE_DOCUMENTS_PROP = 'updateActiveDocumentProperty';
export const GET_USER_DOCUMENTS = 'getUserDocuments';
export const GET_USER_DOCUMENTS_REPORT = 'getUserDocumentsReport';
export const POST_USER_DOCUMENTS_REPORT = 'postUserDocumentsReport';
export const SET_FILTER_STRING = 'setFilterString';
export const SET_DOCUMENTS_LIST = 'setDocumentsList';
export const SET_DOCUMENTS_LIST_REPORT = 'setDocumentsListReport';
export const APPEND_TO_DOCUMENTS_LIST = 'appendToDocumentsList';
export const APPEND_TO_DOCUMENTS_LIST_REPORT = 'appendToDocumentsListReport';

export const SAVE_NEW_DOCUMENT = 'saveNewDocument';
export const UPDATE_NON_VERSIONED_PROPS = 'updateNonVersionedProps';
export const UPDATE_DOCUMENT = 'updateDocument';
export const DELETE_DOCUMENT = 'deleteDocument';
export const DELETE_DOCUMENT_PHYSICALLY = 'deleteDocumentPhysically';
export const FETCH_DOCUMENT_BY_ID = 'fetchDocumentById';
export const FETCH_DOCUMENT_VERSIONS = 'fetchDocumentVersions';
export const FETCH_DOCUMENTS_BY_TYPE_ID = 'fetchDocumentsByTypeId';
export const DOCUMENT_HAS_CHANGES = 'documentHasChanges';

// Users
export const FILTERED_USERS_LIST = 'filteredUsersList';
export const FETCH_USERS_ACTION = 'fetchUsersAction';

// external systems
export const ADD_SYSTEMS_TO_LIST = 'appendExternalSystemsToList';
export const SET_SYSTEMS_LIST = 'setExternalSystemsList';
export const SET_EMPTY_EXTERNAL_SYSTEM = 'setEmptyExternalSystem';

// document attributes
export const SET_DOCUMENT_ATTRIBUTES_LIST = 'setDocumentsAttributeList';
export const ADD_DOCUMENT_ATTRIBUTES_TO_LIST = 'addDocumentsAttributeList';
export const SET_EMPTY_DOCUMENT_ATTRIBUTE = 'setEmptyDocumentsAttribute';
export const DELETE_DOCUMENTS_ATTRIBUTE = 'deleteDocumentsAttribute';
export const STATIC_DICTIONARY_ATTRIBUTES = 'staticDictionaryAttributes';
export const FETCH_REQUIRED_DOCUMENT_ATTRIBUTES = 'fetchRequiredDocumentAttributes';

// filters
export const FILTERS_LIST = 'filtersList';
export const SET_FILTERS_LIST = 'setFiltersList';
export const SET_ACTIVE_FILTER = 'setActiveFilter';
export const SET_ACTIVE_FILTER_ITEMS = 'setActiveFilterItems';
export const FETCH_DOCUMENTS_BY_FILTERS = 'fetchDocumentsByFilters';
export const SAVE_FILTER = 'saveFilter';
export const ACTIVE_DOCUMENT_TYPE = 'activeDocumentType';
export const SET_ACTIVE_DOCUMENT_TYPE = 'setActiveDocumentType';

// Dictionaries
export const DICTIONARIES = 'dictionaries';
export const DOCUMENT_FORM_DICTIONARIES = 'documentFormDictionaries';
export const SET_DICTIONARIES = 'setDictionaries';
export const SET_DICTIONARY_VALUES = 'setDictionaryValues';
export const ADD_DICTIONARY_VALUES = 'addDictionaryValues';
export const FETCH_DICTIONARIES_INFO = 'fetchDictionariesInfo';
export const FETCH_DICTIONARY_VALUES = 'fetchDictionaryValues';
export const QUERY_DICTIONARY_VALUES = 'queryDictionaryValues';
export const LEGAL_ENTITIES_DICTIONARY = 'dictionaryLegalEntity';
export const FETCH_RELATED_DICTIONARY_VALUES = 'fetchRelatedDictionaryValues';
export const SET_RELATED_DICTIONARY_VALUES = 'setRelatedDictionaryValues';


export default {
    DOCUMENT_HAS_CHANGES,
    FETCH_DOCUMENT_VERSIONS,
    SET_FILTERS_LIST,
    FILTERS_LIST,
    SET_ACTIVE_DOCUMENT_TYPE,
    ACTIVE_DOCUMENT_TYPE,
    FETCH_DOCUMENTS_BY_TYPE_ID,
    SET_ACTIVE_FILTER,
    SAVE_FILTER,
    FETCH_DOCUMENTS_BY_FILTERS,
    FETCH_REQUIRED_DOCUMENT_ATTRIBUTES,
    FETCH_RELATED_DICTIONARY_VALUES,
    FETCH_DOCUMENT_BY_ID,
    LEGAL_ENTITIES_DICTIONARY,
    ADD_DICTIONARY_VALUES,
    QUERY_DICTIONARY_VALUES,
    SET_DICTIONARY_VALUES,
    FETCH_DICTIONARY_VALUES,
    DOCUMENT_FORM_DICTIONARIES,
    LOADING,
    ERROR,
    SET_DICTIONARIES,
    DICTIONARIES,
    FETCH_DICTIONARIES_INFO,
    HAS_ACCESS_BY_DOCUMENT_ATTRIBUTES,
    SET_ACTIVE_FILTER_ITEMS,
    STATIC_DICTIONARY_ATTRIBUTES,
    DELETE_DOCUMENT_PHYSICALLY,
    DELETE_DOCUMENT,
    DELETE_DOCUMENTS_ATTRIBUTE,
    SET_EMPTY_EXTERNAL_SYSTEM,
    SET_EMPTY_ACTIVE_ROLE,
    SET_EMPTY_DOCUMENT_ATTRIBUTE,
    UPDATE_DOCUMENT,
    UPDATE_NON_VERSIONED_PROPS,
    SAVE_NEW_DOCUMENT,
    SET_ROLES_LIST,
    ADD_ROLES_TO_LIST,
    SET_DOCUMENT_ATTRIBUTES_LIST,
    ADD_DOCUMENT_ATTRIBUTES_TO_LIST,
    SET_SYSTEMS_LIST,
    ADD_SYSTEMS_TO_LIST,
    FETCH_USERS_ACTION,
    FILTERED_USERS_LIST,
    SET_DOCUMENTS_LIST,
    APPEND_TO_DOCUMENTS_LIST,
    SET_FILTER_STRING,
    DOCUMENTS_LIST,
    GET_USER_DOCUMENTS,
    UPDATE_ACTIVE_DOCUMENTS_PROP,
    CREATE_NEW_DOCUMENT_ON_BASIS,
    HAS_AUTHORITY_BY_CODE,
    ACTIVE_DOCUMENT,
    CHECK_AUTHORITIES_FOR_DOCUMENTS_EDIT,
    SET_SEARCH_BY_CONTENT,
    SEARCH_STRING,
    SEARCH_IN_CONTENT,
    ROLE_DOCUMENT_ATTRIBUTES,
};

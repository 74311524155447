






























import { Vue, Component, Prop } from 'vue-property-decorator';
import { Document } from '../../models/user-documents.model';
import { getDateTimeSimpleRepresentation, getUserShortFullName } from '@/commons/dom.helpers';
import { mapActions, mapState } from 'vuex';

const baseClassName = 'elib-documents-versions-bar';

@Component({
  name: baseClassName,
  computed: {
    ...mapState('documents', ['activeDocument']),
  },
  methods: {
    ...mapActions('documents', ['setActiveVersionById']),
  },
})
export default class VersionsBar extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  @Prop() private userDocument!: Document;
  private height = 650;
  private baseClassName = baseClassName;
  private getDateTimeSimpleRepresentation = getDateTimeSimpleRepresentation;
  private selectedDocVersion: Document | null = null;
  private getUserShortFullName = getUserShortFullName;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private isCurrent(docVersion: Document) {
    return docVersion.versionNumber === this.userDocument.currentVersionNumber;
  }

  private getVersionRow(docVersion: Document) {
    return `Версия ${docVersion.versionNumber}` + (this.isCurrent(docVersion) ? ' (текущая)' : '');
  }

  private mounted() {
    const content = document.querySelector('.elib-documents-profile__content');
    if (!!content) {
      this.height = content.getBoundingClientRect().height - 75;
    }
  }

  private setActiveVersion(docVersion: Document) {
    if (docVersion.deleted) {
      this.$notifier({
        title: 'Ошибка данных!',
        message: `Версия документа ${docVersion.versionNumber} помечана на удаление`,
        type: 'error',
      });
      return;
    }

    this.selectedDocVersion = docVersion;
    (this as any).setActiveVersionById(
      { documentsId: this.userDocument.documentId, version: docVersion.versionNumber },
    ).then(() => {
      const doc = (this.userDocument as any).documentVersions[0];
      this.$emit('clicked', this.isCurrent(doc));
    });


  }
}

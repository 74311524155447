import { Store } from '@/models/commons';
import Vuex, { ModuleNames as Modules } from '@/store/store';
import { UPDATE_ACTIVE_DOCUMENTS_PROP } from '@/store/types';
import { PredefinedAttributes } from '@/models/document-attributes.model';

export const FieldsDictionary: Store = {
  company_group: {
    alias: 'dictionaryCompanyGroup',
    component: 'AUTOCOMPLETE',
    handler: (value: Store) => {
      [
        { prop: 'companyGroup', value: value?.dictionaryValue },
      ].forEach((payload) => Vuex.commit(`${Modules.Documents}/${UPDATE_ACTIVE_DOCUMENTS_PROP}`, payload));
    },
  },
  name_of_the_legal_entity: {
    alias: 'dictionaryLegalEntity',
    component: 'SELECT',
    dependsOn: 'company_group',
    handler: (value: Store) => {
      const payload = { prop: 'legalEntity', value: value?.dictionaryValue };
      Vuex.commit(`${Modules.Documents}/${UPDATE_ACTIVE_DOCUMENTS_PROP}`, payload);
    },
  },
  dossier_section: {
    alias: 'dictionaryDossier',
    component: 'SELECT',
    handler: (value: Store) => {
      const payload = { prop: 'dossier', value: value?.dictionaryValue };
      Vuex.commit(`${Modules.Documents}/${UPDATE_ACTIVE_DOCUMENTS_PROP}`, payload);
    },
  },
  inn: {
    component: 'STRING',
    dependsOn: 'name_of_the_legal_entity',
    path: ['dictionaryValue', 'description'],
  },
  abbreviated_name_of_the_document: {
    alias: 'dictionaryDocumentShortName',
    component: 'AUTOCOMPLETE',
    handler: (value: Store) => {
      [
        { prop: 'documentShortName', value: value?.dictionaryValue },
        { prop: 'documentFullName', value: null },
        { prop: 'documentName', value: '' },
      ].forEach((payload) => Vuex.commit(`${Modules.Documents}/${UPDATE_ACTIVE_DOCUMENTS_PROP}`, payload));
    },
  },
  title_of_the_document: {
    alias: 'dictionaryDocumentFullName',
    component: 'SELECT',
    dependsOn: PredefinedAttributes.SHORT_TITLE,
    handler: (value: Store) => {
      [
        { prop: 'documentFullName', value: value?.dictionaryValue },
        { prop: 'documentName', value: value?.dictionaryValue?.value },
      ].forEach((payload) => Vuex.commit(`${Modules.Documents}/${UPDATE_ACTIVE_DOCUMENTS_PROP}`, payload));
    },
  },
  document_kind: {
    alias: 'document_kind',
    component: 'CHECKBOX',
    handler: (value: Store) => {
      const payload = { prop: 'documentKind', value: value?.dictionaryValue.value };
      Vuex.commit(`${Modules.Documents}/${UPDATE_ACTIVE_DOCUMENTS_PROP}`, payload);
    },
  },
  notifications: {
    alias: 'notifications',
    component: 'CHECKBOX',
    handler: (value: Store) => {
      const payload = { prop: 'notifications', value: value?.dictionaryValue.value };
      Vuex.commit(`${Modules.Documents}/${UPDATE_ACTIVE_DOCUMENTS_PROP}`, payload);
    },
  },
  document_format: {
    alias: 'document_format',
    component: 'SELECT',
    handler: (value: Store) => {
      const payload = { prop: 'documentFormat', value: value?.dictionaryValue };
      Vuex.commit(`${Modules.Documents}/${UPDATE_ACTIVE_DOCUMENTS_PROP}`, payload);
    },
  },
  document_ownership: {
    alias: 'document_ownership',
    component: 'CHECKBOX',
    handler: (value: Store) => {
      const payload = { prop: 'ownershipType', value: !!value?.dictionaryValue.value };
      Vuex.commit(`${Modules.Documents}/${UPDATE_ACTIVE_DOCUMENTS_PROP}`, payload);
    },
  },
  document_period_year: {
    alias: 'document_period_year',
    component: 'AUTOCOMPLETE',
  },
  dossier_kind: {
    alias: 'dossier_kind',
    component: 'CHECKBOX',
    dependsOn: 'document_format',
    path: ['dictionaryValue', 'description'],
  },

  document_period: {
    alias: 'document_period',
    component: 'SELECT',
  },
};






















































import { Vue, Component } from 'vue-property-decorator';

const baseClassName = 'elib-icon-avatar';

@Component({
  name: baseClassName,
})
export default class IconAvatar extends Vue {}

import { getUserSessionIsAlive } from '@/controllers/sessions.controllers';

export function runSessionPing() {
    const interval = setInterval(() => {
        getUserSessionIsAlive().then((isAlive) => {
            if (!isAlive) {
                clearInterval(interval);
                window.location.replace('/auth');
            }
        });
    }, 1000 * 60 * 31);
}

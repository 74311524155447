














































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import {  Role, getEmptyRole, roleChapters, roleAuthorities,
  RolesAuthority, RolesDescription, RolesDocumentAttribute} from '@/models/roles.model';
import { SelectOption } from '@/models/view.model';
import RolesAttributes from '@/components/Administration/Roles/RolesAttributes.vue';
import * as types from '@/store/types';
import { Store } from '@/models/commons';

const baseClassName = 'elib-roles-edit-form';

@Component({
  name: 'elib-roles-edit-form',
  components: {
    RolesAttributes,
  },
  computed: {
    ...mapState('roles', ['activeRole']),
  },
  methods: {
    ...mapActions('roles', ['updateRole', 'createNewRole', 'removeRole']),
    ...mapMutations('roles', ['updateActiveRoleProperty', 'setActiveRole']),
  },
})
export default class RolesForm extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private self: any;
  private authoritiesCheckboxs = [];
  private showModal = false;

  private docAttributesChanged = false;
  private documentAttributes: RolesDocumentAttribute[] = [];

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get authorityChapters() {
    const items = roleChapters.map((value) => {
      return {
        title: value[0],
        authorities: (value[1] as number[]).map((id) => this.authorityDescirption(id)),
      };
    }).filter((value) => value.title !== '');
    return items;
  }

  private authorityDescirption(id: number) {
    return {
      description: roleAuthorities.find((v) => v.id === id),
      selected: (this as any).activeRole.roleAuthorities.find((v: RolesAuthority) => v.id === id) ? true : false,
    };
  }

  private leadingCheckbox(chapter: any) {
    const arrayOfSelected = chapter.authorities.map((val: RolesDescription) => val.selected);
    return arrayOfSelected.every((elem: boolean) => elem === true);
  }

  private onAuthorityChange(mark: boolean, value: { description: RolesAuthority, selected: boolean }) {
    const authorities = [...(this as any).activeRole.roleAuthorities] as RolesAuthority[];
    if (mark) {
      authorities.push(value.description);
      (this as any).updateActiveRoleProperty({ prop: 'roleAuthorities', value: [...authorities] });
    } else {
      (this as any).updateActiveRoleProperty({        prop: 'roleAuthorities',
        value: authorities.filter((v) => v.id !== value.description.id)      });
    }
  }

  private onAllAuthorityChange(mark: boolean, value: { title: string, authorities: any }) {
    const authorities = [...(this as any).activeRole.roleAuthorities] as RolesAuthority[];
    const authority = value.authorities.map((val: RolesDescription) => val.description);
    if (mark) {
      authorities.push(...authority);
      (this as any).updateActiveRoleProperty({ prop: 'roleAuthorities', value: [...authorities] });
    } else {
      (this as any).updateActiveRoleProperty({        prop: 'roleAuthorities',
        value: authorities.filter((v) => !authority.find((ra: RolesAuthority) => v.id === ra.id)),
      });
    }
  }

  private async saveRole() {
    if (!this.formIsValid()) {
      return;
    }
    let transactionCommited = false;
    const role = {...this.self.activeRole};
    let authorities = [...(this as any).activeRole.roleAuthorities] as RolesAuthority[];
    const roleAuthorityCodes = [...new Set(authorities.map((v) => v.code))];
    authorities = roleAuthorityCodes.map((code) => authorities.find((v) => v.code === code) as any);
    this.self.updateActiveRoleProperty({ prop: 'roleAuthorities', value: [...authorities] });
    const attributes = role?.attributes.map((v: Store) => v).filter((v: Store) => v && v.value);
    if (role && role.id !== 0) {
      transactionCommited = await this.self.updateRole(role);
    } else if (role && role.id === 0) {
      transactionCommited = await this.self.createNewRole(role);
    }
    transactionCommited ? this.$emit('close') :
      this.$notifier({ title: 'Ошибка данных!', message: 'Не удалось сохранить роль', type: 'error' });
  }

  private onDocumentAttributesChange(documentAttributes: RolesDocumentAttribute[]) {
    this.docAttributesChanged = true;
    this.documentAttributes = [...documentAttributes];
  }

  private beforeCreate() {
    this.self = this;
  }

  private async removeActiveRole() {
    const role = (this as any).activeRole;
    const deleted = await (this as any).removeRole(role);
    if (deleted) {
      this.$emit('close');
    }
  }

  private formIsValid() {
    const mainForm = this.$refs.mainForm as HTMLFormElement;
    const title = 'Ошибка формы!';
    const type = 'error';
    if (!mainForm.checkValidity()) {
      this.$notifier({ title, message: 'Не заполнены обязательны поля', type });
      return false;
    }
    if (this.hasNoAuthorities()) {
      this.$notifier({ title, message: 'Укажите минимум одно право', type });
      return false;
    }
    return true;
  }

  private systemRolesValidity() {
    const role = (this as any).activeRole as Role;
    const hasSystemRoles = role.roleAuthorities.filter((v) => [34, 35].includes(v.id)).length;
    if (hasSystemRoles && !role.outerSystem) {
      return false;
    }
    if (!hasSystemRoles && role.outerSystem && !!role.outerSystem) {
      return false;
    }
    return true;
  }

  private hasNoAuthorities() {
    const role = (this as any).activeRole as Role;
    return role.roleAuthorities.length === 0;
  }
}

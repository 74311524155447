import Vue from 'vue';
import { Module } from 'vuex';
import { Role, getEmptyRole, RolesDocumentAttribute } from '@/models/roles.model';
import {
  fetchRoles,
  fetchRoleById,
  postNewRole,
  updateExistingRole,
  deleteRole,
} from '@/controllers/roles.controllers';
import { QueryParams } from '@/commons/axios.config';
import * as types from '@/store/types';

type StringFilter = string | undefined;

interface ModuleState {
  rolesList: Role[];
  offset: number;
  filterString: StringFilter;
  activeRole: Role | undefined;
}

export const module: Module<ModuleState, any> = {
  namespaced: true,
  state: {
    rolesList: [],
    offset: 0,
    filterString: undefined,
    activeRole: undefined,
  },
  mutations: {
    filterString(state: ModuleState, stringFilter: StringFilter) {
      state.filterString = stringFilter;
      state.offset = state.rolesList.length + 1;
    },
    [types.SET_ROLES_LIST](state: ModuleState, payload: Role[]) {
      state.rolesList = payload || [];
    },
    [types.ADD_ROLES_TO_LIST](state: ModuleState, payload: Role[]) {
      const raw = [...state.rolesList, ...payload];
      const ids = [...new Set(raw.map((v) => v.id))];
      state.rolesList = ids.map((id) => {
        return raw.find((perm) => perm.id === id) as Role;
      });
    },
    setActiveRole(state: ModuleState, payload: Role) {
      state.activeRole = payload;
    },
    [types.SET_EMPTY_ACTIVE_ROLE](state: ModuleState) {
      state.activeRole = getEmptyRole();
    },
    updateActiveRoleProperty(state: ModuleState, payload: { prop: string, value: any }) {
      if (state.activeRole) {
        (state.activeRole as { [key: string]: any })[payload.prop] = payload.value;
        const index = state.rolesList.findIndex((role) => role.id === (state.activeRole as Role).id);
        state.rolesList[index] = state.activeRole;
        state.rolesList = [...state.rolesList];
        state.activeRole = { ...state.activeRole };
      }
    },
    updateRoles(state: ModuleState, payload: Role) {
      const index = state.rolesList.findIndex((v) => v.id === payload.id);
      if (index > -1) {
        state.rolesList[index] = payload;
      } else {
        state.rolesList.push(payload);
      }
    },
    removeRole(state: ModuleState, payload: Role) {
      state.rolesList = state.rolesList.filter((v) => v.id !== payload.id);
    },
  },
  getters: {
    actualRolesList({ rolesList, filterString }) {
      return getFilteredRolesList(rolesList, filterString);
    },
    [types.ROLE_DOCUMENT_ATTRIBUTES]({ activeRole }) {
      const attributes = [...activeRole?.attributes || []];
      return JSON.parse(JSON.stringify(attributes));
    },
  },
  actions: {
    async getRoles({ commit, rootState, state }, params?: QueryParams) {
      const query = { ...{offset: state.rolesList.length, query: state.filterString}, ...params};
      const roles = await fetchRoles(query as QueryParams);
      if (roles) {
        commit(types.ADD_ROLES_TO_LIST, roles);
      }
    },
    async setFilterString({ commit }, stringFilter: string) {
      commit('filterString', stringFilter);
    },
    async getActiveRoleById({ commit }, payload: number) {
      const role = await fetchRoleById(payload);
      if (role) {
        commit('setActiveRole', role);
      }
    },
    async updateRole({ commit }, payload: Role) {
      const role = await updateExistingRole(payload);
      if (role) {
        commit('updateRoles', role);
        return true;
      }
      return false;
    },
    async createNewRole({ commit }, payload: Role) {
      const role = await postNewRole(payload);
      if (role) {
        commit('updateRoles', role);
        return true;
      }
      return false;
    },
    async removeRole({ commit }, payload: Role) {
      const deleted = await deleteRole(payload);
      if (deleted) {
        commit('removeRole', payload);
      }
      return deleted;
    },
  },
};

function getFilteredRolesList(rolesList: Role[], filter: StringFilter) {
  return rolesList.filter((v) => {
    const filteredResult = filter ? RegExp(filter, 'gi').test(v.title) : true;
    const filteredResultCode = filter ? RegExp(filter, 'gi').test(v.code) : true;
    return filteredResult || filteredResultCode;
  });
}

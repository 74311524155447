








import { Vue, Component, Prop } from 'vue-property-decorator';

const baseClassName = 'elib-icon-documents-catalogue';

@Component({
    name: baseClassName,
})
export default class IconDocumentsCatalogue extends Vue {
    @Prop({default: () => 12}) private width!: number;
    @Prop({default: () => 8}) private height!: number;
    private baseClassName = baseClassName;
}











































import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { ExternalSystem } from '@/models/external-systems.model';
import types from '../../store/types';

const baseClassName = 'elib-external-systems-list';

@Component({
  name: baseClassName,
  computed: {
    ...mapState('systems', ['externalSystemList', 'filterString', 'activeExternalSystem']),
    ...mapGetters('systems', ['actualExternalSystems']),
    ...mapGetters('session', ['hasAuthorityByCode']),
  },
  methods: {
    ...mapMutations('systems', [types.SET_SYSTEMS_LIST, types.SET_EMPTY_EXTERNAL_SYSTEM, 'setActiveExternalSystem']),
    ...mapActions('systems', ['getExternalSystems', 'getActiveExternalSystemById', 'setFilterString']),
  },
})
export default class ExternalSystemsList extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private self: any;
  private isNewSystemCreation = false;
  private showDeleted = false;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private get userTableColumns() {
    return [
      { key: 'name', title: 'Система', width: 35 },
      { key: 'description', title: 'Описание', width: 35 },
      { key: 'login', title: 'Логин', width: 20 },
      { key: 'outerCode', title: 'Код', width: 10 },
    ];
  }

  private get usersTableData() {
    return ((this as any).actualExternalSystems as ExternalSystem[]).
      map((system) => {
        const deletedEntityStyle = {
          backgroundColor: 'rgba(40, 36, 35, 0.07)',
        } as CSSStyleDeclaration;
        const tdStyle = !!system.deleted ? { ...deletedEntityStyle } : undefined;
        const componentStyle = !!system.deleted ? { textDecoration: 'line-through' } : undefined;
        return {
          name: system.name,
          system,
          description: system.description,
          login: system.user ? system.user.login : ' ',
          outerCode: system.outerCode,
          tdStyle,
          componentStyle,
        };
      });
  }

  private beforeCreate() {
    this.self = this;
  }

  private created() {
    this.onComponentEnter();
  }

  private mounted() {
    (this.$el as HTMLElement).addEventListener('scroll', this.onScroll);
  }

  private activated() {
    this.onComponentEnter();
  }

  private beforeDestroy() {
    (this.$el as HTMLElement).removeEventListener('scroll', this.onScroll);
  }

  private onComponentEnter() {
    this.self[types.SET_SYSTEMS_LIST]([]);
    this.self.getExternalSystems({ withDeleted: this.showDeleted });
  }

  private onRowClick(row: { system: ExternalSystem }) {
    if (!(this as any).hasAuthorityByCode(32)) {
      this.$notifier({
        title: 'Недостаточно прав!', message: 'У вас нет прав для редактирования вн. систем',
        type: 'error',
      });
      return;
    }
    if (row.system && row.system.id) {
      this.self.getActiveExternalSystemById(row.system.id);
    }
  }
  private get stringFilter() {
    return this.self.filterString;
  }

  private set stringFilter(value: string) {
    (this as any).setFilterString(value);
    (this as any).getExternalSystems({ offset: 0 });
  }

  private onScroll() {
    if ((this.$el.scrollHeight - this.$el.clientHeight) === this.$el.scrollTop) {
      this.self.getExternalSystems({ withDeleted: this.showDeleted });
    }
  }

  private createNewExternalSystem() {
    if (!(this as any).hasAuthorityByCode(32)) {
      this.$notifier({
        title: 'Недостаточно прав!', message: 'У вас нет прав для редактирования вн. систем',
        type: 'error',
      });
      return;
    }
    this.self[types.SET_EMPTY_EXTERNAL_SYSTEM]();
    this.isNewSystemCreation = true;
  }

  private onClickOutside() {
    if ((this as any).activeExternalSystem && !this.isNewSystemCreation) {
      (this as any).setActiveExternalSystem(undefined);
    }
    this.isNewSystemCreation = false;
  }

  private onShowDeleted(check: boolean) {
    this.showDeleted = check;
    this.self[types.SET_SYSTEMS_LIST]([]);
    this.self.getExternalSystems({ withDeleted: this.showDeleted });
  }
}








































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState, mapMutations, mapActions } from 'vuex';
import { DocumentsFilter } from '../../models/user-documents.model';
import DocumentTypesTree from '@/components/UserDocuments/DocumentTypesTree.vue';
import types from '@/store/types';
import { Store } from '@/models/commons';

const baseClassName = 'elib-documents-sidebar';

@Component({
  name: baseClassName,
  components: {
    DocumentTypesTree,
  },
  computed: {
    ...mapState('filters', ['filters', 'activeFilter']),

    ...mapState('documents', ['predefinedFiltersName']),
  },
  methods: {
    ...mapMutations('filters', ['setActiveFilter', 'setActiveFilterName']),
    ...mapActions('filters', ['removeFilter']),
    ...mapMutations('documents', ['setTemporaryFilter', 'replaceDocumentsList', 'clearTemporaryFilterItems', 'setActiveFilterId',
    'setPredefinedFiltersName']),
    ...mapActions('documents', ['getUserDocuments', types.FETCH_DOCUMENTS_BY_FILTERS]),
  },
})
export default class DocumentsSidebar extends Vue {
  @Prop({ default: () => [] }) private incomingClasses!: string[];
  private baseClassName = baseClassName;
  private showFilter = true;
  private self!: any;
  private showDeleteModal = 0;

  private get className() {
    return (this.incomingClasses || []).
      reduce((acc: string, value: string) => `${acc} ${value}`, baseClassName);
  }

  private openFiltersBar() {
    this.showFilter = !this.showFilter;

  }
  private get sidebarData() {
    return [];
  }

  private onFilterSelect(filter: DocumentsFilter & Store) {
    this.self.setActiveFilter(filter);
    if (filter) {
      this.self.setPredefinedFiltersName(undefined);
      this.self.setActiveFilterId(filter.id);
      this.self.fetchDocumentsByFilters(filter.specification);
    }
  }

  private setPredefinedFilter(filtersName: string | undefined) {
    this.self.setPredefinedFiltersName(filtersName);
    this.self.getUserDocuments();
  }

  private beforeCreate() {
    this.self = this;
  }
}

import { mapGetters } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Prop } from 'vue-property-decorator';
import { DocumentsFilterItem } from '@/models/user-documents.model';
import { filters } from '@/dictionaries/filters';
import { DocumentsAttribute } from '@/models/document-attributes.model';
import { DictionaryValueDTO, QueryDictionaryParams } from '@/models/dictionaries.model';
import { Store } from '@/models/commons';
import { DocumentsFilterItemDto, FilterViewType } from '@/models/filters.model';
import { fetchDictionaryValueById, fetchDictionaryValuesById, fetchRelatedDictionaryValuesById } from '@/controllers/dictionaries.controller';
import { QueryParams } from '@/commons/axios.config';
import { debounce } from 'debounce';

@Component({
    computed: {
        ...mapGetters('catalogues', ['catalogueTreeViewData', 'catalogueByNodeId']),
    },
})
export default class FilterItemMixin extends Vue {
    protected dictionaryValues: Array<DictionaryValueDTO | Store> = [];
    protected query = '';
    protected selectedDictionaryValues: Array<DictionaryValueDTO | Store> = [];
    @Prop() protected filterItem!: DocumentsFilterItem;
    @Prop({ default: () => true }) protected filterMode!: boolean;
    @InjectReactive('scopedFilterItems') protected scopedFilterItems!: DocumentsFilterItem[];
    @InjectReactive('scopedFilters') protected scopedFilters!: DocumentsFilterItemDto[];
    protected catalogueByNodeId!: (id?: number) => Store | null;

    protected get typeCode() {
        return this.filterItem.attribute.attributeType.code.toUpperCase();
    }

    protected get isString() {
        return this.typeCode === 'STRING';
    }

    protected get isNumber() {
        return this.typeCode === 'NUMBER';
    }

    protected get isDictionary() {
        return this.typeCode === 'DICTIONARY';
    }

    protected get isBoolean() {
        return this.typeCode === 'BOOLEAN';
    }

    protected get isDate() {
        return this.typeCode === 'DATE' ||
            (this.viewType === FilterViewType.DATE);
    }

    protected get value() {
        return this.filterItem.value;
    }

    protected get attributeCode() {
        return this.filterItem.attribute.code;
    }

    protected get filterParams() {
        return filters[this.attributeCode] || {};
    }

    protected get dictionaryView() {
        return (filters[this.attributeCode] && filters[this.attributeCode]?.view) || FilterViewType.AUTOCOMPLETE;
    }

    protected get documentTypeName() {
        return this.catalogueByNodeId(+this.filterItem.value)?.title;
    }

    protected get valueGetter() {
        return this.filterParams && this.filterParams.getter;
    }

    protected get valueSetter() {
        return this.filterParams && this.filterParams.setter;
    }

    protected get valueInitializer() {
        return this.filterParams && this.filterParams.initializer;
    }

    protected get attribute() {
        return this.filterItem.attribute as DocumentsAttribute;
    }

    protected get dictionary() {
        return this.attribute?.dictionary;
    }

    protected get dictionaryId() {
        return this.dictionary?.id || this.masterValueDictionaryId || this.masterDictionaryId;
    }

    protected get externalField() {
        return this.filterParams.externalField || '';
    }

    protected get externalTable() {
        return this.filterParams.externalTable || '';
    }

    protected get externalCode() {
        return this.externalTable + this.externalField;
    }

    protected get viewType() {
        return this.filterParams.view;
    }

    protected get dependsOn() {
        return this.filterParams.dependsOn;
    }

    protected get searchField() {
        return this.filterParams.searchField as 'value' | 'title' | 'description';
    }

    protected get masterFilterDictionaryValueId() {
        return this.dependsOn && this.scopedFilters ? this.scopedFilters
            .find((v) => v.attributeDefinition?.code === this.dependsOn)?.dictionaryValueId : null;
    }

    protected get masterValueDictionaryId() {
        return this.dependsOn && this.scopedFilters ? this.scopedFilters
            .find((v) => v.attributeDefinition?.code === this.dependsOn)?.attributeDefinition?.dictionary?.id : null;
    }

    protected get masterDictionaryId() {
        return this.dependsOn && this.scopedFilterItems ? this.scopedFilterItems
            .find((v) => v.attribute.code === this.dependsOn)?.attribute.dictionary?.id : null;
    }

    protected get searchFunction() {
        return this.filterParams.searchFunction;
    }

    protected get searchParamsBuilder() {
        return this.filterParams.searchParamsBuilder;
    }

    protected get canUseFilterSearchFunction() {
        return this.filterParams.searchFunctionUseCondition && this.filterParams.searchFunctionUseCondition(this);
    }

    protected get useFilterSearchFunction() {
        return this.searchFunction && this.searchParamsBuilder;
    }

    protected get fetchDictionaryMethod() {
        return this.useFilterSearchFunction ? this.fetchAdditionalDictionaryValues :
        this.valueGetter ? this.valueGetter :
        this.dependsOn && this.masterFilterDictionaryValueId ?
        this.fetchRelatedDictionaryValues : this.fetchRootDictionaryValues;
    }

    protected get fetchAdditionalDictionaryValues() {
        return async (params: QueryParams) => {
            const dictionaryValues = this.searchFunction && this.searchParamsBuilder ?
            await this.searchFunction(...this.searchParamsBuilder(this, params))
            : this.valueGetter ? this.valueGetter(params) : await Promise.resolve([]);
            this.dictionaryValues = [...dictionaryValues];
            return true;
        };
    }

    protected get debounceQueryDictionaryValues() {
        return debounce(async (query?: string) => {
            const params = { query, offset: 0, limit: this.filterParams.limit || 3 };
            const fetched = await this.fetchDictionaryMethod(params);
            if (fetched && this.valueGetter && !this.canUseFilterSearchFunction) {
                const dictionaryValues = await this.valueGetter(params);
                this.dictionaryValues = [...dictionaryValues];
            }
        }, 600);
    }

    protected changeDictionaryValues(mark: boolean, dictionaryValue: DictionaryValueDTO, id: number) {
        this.filterItem.value = this.isDictionary && !this.filterItem.value ? [] : this.filterItem.value;
        const values = this.filterItem.value as number[];
        if (mark) {
            values.length = 0;
            values.push(id);
            this.selectedDictionaryValues.push(dictionaryValue);
        } else {
            this.filterItem.value = values.filter((v) => v !== id);
            this.selectedDictionaryValues = this.selectedDictionaryValues.filter((v) => v.id !== id);
        }
        this.onFilterValueChange();
        return values;
    }

    protected onFilterValueChange(): DocumentsFilterItemDto | DocumentsFilterItemDto[] {
        const value = this.value instanceof Array ? this.mappingArrayValue() : this.mappingFlatValue();
        this.$emit(`change`, value, this.externalCode || this.attributeCode);
        this.$emit(`change-filter-item`, this.filterItem);
        return value;
    }

    protected mappingFlatValue(): DocumentsFilterItemDto {
        if (this.valueSetter) {
            return this.valueSetter(this.attribute, this.value);
        }
        return {
            attributeDefinition: this.attribute,
            attributeDefinitionId: this.attribute.id,
            booleanValue: this.isBoolean && this.value ? this.value as boolean : undefined,
            dateValue: this.isDate && this.value ? this.value as number : undefined,
            numberValue: this.isNumber && this.value ? this.value as number : undefined,
            stringValue: this.isString && this.value ? this.value as string : undefined,
        };
    }

    protected mappingArrayValue(): DocumentsFilterItemDto[] {
        if (this.valueSetter) {
            return this.valueSetter(this.attribute, this.value);
        }
        const values = this.value ? this.value as Array<number | string> : [];

        return values.map((value) => ({
            attributeDefinition: this.attribute,
            attributeDefinitionId: this.attribute.id,
            dictionaryValueId: value as number,
        }));
    }

    protected initializeDictionaryValues() {
        if (!this.dictionary && !this.masterDictionaryId) {
            return;
        }
        if (this.value instanceof Array) {
            this.value.filter((v) => ![undefined, null].includes(v as any)).forEach(async (value: string | number) => {
                const dto = await fetchDictionaryValueById(parseInt(value as string, 10)) || null;
                if (dto) {
                    this.dictionaryValues.push(dto);
                    this.query = this.searchField && dto[this.searchField] ?
                        dto[this.searchField] : (dto.title || dto.value || '');
                }
            });
        }
    }

    protected runValueInitializer() {
        if (this.valueInitializer && this.value instanceof Array) {
            this.value.filter((v) => ![undefined, null].includes(v as any)).forEach(async (value: string | number) => {
                const dto = await this.valueInitializer?.(parseInt(value as string, 10)) || null;
                if (dto) {
                    this.dictionaryValues.push(dto);
                    this.query = this.searchField && dto[this.searchField] ?
                        dto[this.searchField] : (dto.title || dto.value || '');
                }
            });
        }
    }

    protected async queryDictionaryValues(query?: string) {
        this.debounceQueryDictionaryValues(query);
    }

    protected async fetchRootDictionaryValues(params: QueryParams) {
        if (this.dictionaryId) {
            const dictionaryValues = await fetchDictionaryValuesById(this.dictionaryId, params);
            this.dictionaryValues = [...dictionaryValues];
            return true;
        }
        return false;
    }

    protected async fetchRelatedDictionaryValues(params: QueryParams) {
        if (this.dictionaryId && this.masterFilterDictionaryValueId) {
            const dictionaryValues  = await fetchRelatedDictionaryValuesById(
            this.dictionaryId, parseInt(this.masterFilterDictionaryValueId.toString(), 10), params);
            this.dictionaryValues = [...dictionaryValues];
            return true;
        }
        return false;
    }
}











import { Vue, Prop, Component } from 'vue-property-decorator';

const baseClassName = 'elib-spinner-loading';

@Component({
  name: baseClassName,
})
export default class SpinnerLoading extends Vue {
  @Prop({default: () => 'Загрузка' }) private title!: string;
  private baseClassName = baseClassName;
}
